import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";

import { TabsContext } from "components/Common/Tabs";
import CreatorsStub from "components/Entities/Stubs/CreatorsStub";
import {
  creatorItemStyles,
  creatorsListStyles,
} from "pages/PodcastView/About/AboutCreators";

import getEpisodeUrl from "utils/entity/getEpisodeUrl";
const CREATOR_IMAGE_STYLE_OVERRIDE = {
  imageFallback: {
    height: "100%",
  },
};

const AboutCreators = ({ episode, onTabChange }) => {
  const creatorsOnTabChange = useCallback(
    () => onTabChange("creators"),
    [onTabChange]
  );
  const staticFilters = useMemo(
    () => ({
      episode_id: {
        value: episode && episode.get("id"),
      },
    }),
    [episode]
  );

  const { contentRef } = useContext(TabsContext);

  return (
    <CreatorsStub
      listKey={`episodeView/${episode.get("id")}/aboutCreators`}
      title="Creators & Guests"
      onTabChange={creatorsOnTabChange}
      staticFilters={staticFilters}
      entityName="episode"
      entity_type="episode"
      entity={episode}
      scrollRef={contentRef}
      promptType="episodeCreator"
      viewAllLink={getEpisodeUrl(episode, { path: "creators" })}
      viewAllTitle={`Creators and Guests on ${episode.get("title")}`}
      creatorItemStyles={creatorItemStyles}
      styles={creatorsListStyles}
      passedImageStyles={CREATOR_IMAGE_STYLE_OVERRIDE}
    />
  );
};

AboutCreators.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  episode: PropTypes.instanceOf(Map),
};
AboutCreators.defaultProps = {
  episode: null,
};

export default AboutCreators;
