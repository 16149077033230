import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const PodcastButtons = Loadable(() => import("./PodcastButtons"));

function PodcastButtonsAsyncLoading(props) {
  return <LoadingOverlay {...props} span height="4.9rem" />;
}

const PodcastButtonsContainer = (props) => {
  return (
    <PodcastButtons {...props} fallback={<PodcastButtonsAsyncLoading />} />
  );
};

export default PodcastButtonsContainer;
