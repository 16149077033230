import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const PodcastOverview = Loadable(() => import("./PodcastOverview"));

function ProAsyncLoading(props) {
  return <AsyncLoadingEntityCard {...props} type="overview" />;
}

const PodcastOverviewContainer = (props) => {
  return <PodcastOverview {...props} fallback={<ProAsyncLoading />} />;
};

export default PodcastOverviewContainer;
