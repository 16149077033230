import Loadable from "@loadable/component";

const InsightsStatus = Loadable(() =>
  import(/* webpackChunkName: "InsightsStatus" */ "./InsightsStatus")
);

function InsightsStatusAsyncLoading() {
  return null;
}

const InsightsStatusContainer = (props) => {
  return (
    <InsightsStatus {...props} fallback={<InsightsStatusAsyncLoading />} />
  );
};

export default InsightsStatusContainer;
