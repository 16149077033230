import { Fragment } from "react";

import useEntityCard from "../useEntityCard";
import PodcastCompactCard from "./PodcastCompactCardAsync";
import PodcastDesktopCard from "./PodcastDesktopCardAsync";
import PodcastFollowCard from "./PodcastFollowCardAsync";
import PodcastOverview from "./PodcastOverviewAsync";

import { selectSpecificPodcast, selectPodcastLoading } from "selectors/podcast";

const CARD_CONFIG = {
  selector: selectSpecificPodcast,
  loadingSelector: selectPodcastLoading,
  desktop: PodcastDesktopCard,
  overview: PodcastOverview,
  compact: PodcastCompactCard,
  follow: PodcastFollowCard,
};

const PodcastCardContainer = (props) => {
  const { CardType, cardProps } = useEntityCard(CARD_CONFIG, "podcast", props);

  if (CardType === Fragment) {
    return <CardType />;
  }

  return <CardType {...props} {...cardProps} />;
};

export default PodcastCardContainer;
