import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Reviews = Loadable(() => import("./Reviews"));

function AboutAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const ReviewsContainer = (props) => {
  return <Reviews {...props} fallback={<AboutAsyncLoading />} />;
};

export default ReviewsContainer;
