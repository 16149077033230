import { memo } from "react";

import PodcastViewSidebarSkeleton from "./PodcastViewSidebarSkeleton";
import PodcastViewSidebar from "./Sidebar/PodcastViewSidebarAsync";

const PodcastViewSidebarContainer = (props) => {
  if (!props.entity) {
    return <PodcastViewSidebarSkeleton />;
  }

  return <PodcastViewSidebar {...props} />;
};

export default memo(PodcastViewSidebarContainer);
