import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const PublicPodcastInsightsSponsors = Loadable(() =>
  import(
    /* webpackChunkName: "PublicPodcastInsightsSponsors" */ "./PublicPodcastInsightsSponsors"
  )
);

function PublicPodcastInsightsSponsorsLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const PublicPodcastInsightsSponsorsContainer = (props) => {
  return (
    <PublicPodcastInsightsSponsors
      {...props}
      fallback={<PublicPodcastInsightsSponsorsLoading />}
    />
  );
};

export default PublicPodcastInsightsSponsorsContainer;
