import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "126rem",
    maxHeight: "126rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "107rem",
      maxHeight: "107rem",
    },
  },
};

const PublicInsights = Loadable(() => import("./PublicInsights"));

function InsightsAsync(props) {
  return <LoadingOverlay height={"136rem"} styles={loadingStyles} />;
}

const PublicInsightsContainer = (props) => {
  return <PublicInsights {...props} fallback={<InsightsAsync />} />;
};

export default PublicInsightsContainer;
