import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";

const LineLoader = (props) => {
  const { percent, strokeColor, trailWidth, trailColor } = props;

  const baseStyles = useMemo(
    () => ({
      trail: {
        width: "100%",
        height: trailWidth,
        overflow: "hidden",
        borderRadius: 6,
        backgroundColor: trailColor,
        margin: ".4rem 0",
      },
      stroke: {
        height: "100%",
        width: `${percent}%`,
        backgroundColor: strokeColor,
        transition: "width 500ms",
      },
    }),
    [percent, trailWidth, trailColor, strokeColor]
  );

  const { styles } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.trail)}>
      <div className={css(styles.stroke)} />
    </div>
  );
};

LineLoader.propTypes = {
  percent: PropTypes.number,
  strokeColor: PropTypes.string,
  trailWidth: PropTypes.number,
  trailColor: PropTypes.string,
};

LineLoader.defaultProps = {
  percent: 0,
  strokeColor: colours.primary,
  trailWidth: 5,
  trailColor: "#eee",
};

export default memo(LineLoader);
