import React from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {
    width: "100%",
    padding: "0.5rem",
  },
  title: {
    height: 30,
    width: 300,
    margin: "3rem 0 2rem",
    borderRadius: 4,
  },
  subtitle: {
    height: 25,
    width: "100%",
    marginBottom: 20,
    borderRadius: 4,
  },
  maxWidth: {
    width: 200,
  },
  filterContainer: {
    display: "flex",
    gap: 10,
    marginBottom: 20,
  },
  filter: {
    height: 30,
    width: 80,
    borderRadius: "4em",
    marginBottom: "1rem",
    ":last-child": {
      marginLeft: "auto",
    },
  },
  bottom: {
    height: "400rem",
    width: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    height: "9.375rem",
    width: "9.375rem",
    marginBottom: 10,
    borderRadius: 8,
  },
  text: {
    height: "1.25rem",
    width: "6.25rem",
    marginBottom: 5,
    borderRadius: 4,
  },
  rating: {
    height: "0.938rem",
    width: "3.75rem",
    borderRadius: 4,
  },
  skeletonPlaceholder: {
    ...gStyles.skeletonPlaceholder,
  },
};

const SimilarTabLoading = (props) => {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.container)}>
      <p className={css(styles.title, styles.skeletonPlaceholder)} />
      <p className={css(styles.subtitle, styles.skeletonPlaceholder)} />
      <p
        className={css(
          styles.subtitle,
          styles.skeletonPlaceholder,
          styles.maxWidth
        )}
      />
      <div className={css(styles.filterContainer)}>
        <div className={css(styles.filter, styles.skeletonPlaceholder)} />
        <div className={css(styles.filter, styles.skeletonPlaceholder)} />
        <div className={css(styles.filter, styles.skeletonPlaceholder)} />
      </div>
      <div className={css(styles.bottom, styles.skeletonPlaceholder)} />
    </div>
  );
};

export default SimilarTabLoading;
