import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import RatingText from "components/Entities/Page/RatingText";
import RatingWithHover from "components/Rating/RatingWithHover";

import HasGuestsBadge from "../../components/Podcast/HasGuestsBadgeAsync";
import HeaderAuthorityScore from "./HeaderAuthorityScoreAsync";
import HeaderVerifiedBadge from "./HeaderVerifiedBadge";

import useHasProForPodcast from "hooks/useHasProForPodcast";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import entityPageStyles from "styles/entityPageStyles";
import ScreenSizes from "styles/ScreenSizes";

const proBaseStyles = {
  ratingAuthorityContainer: {
    [ScreenSizes.lgAndAbove]: {
      justifyContent: "flex-end",
    },
  },
};

const baseStyles = {
  ...entityPageStyles,
  ratingAuthorityContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    maxWidth: 300,
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",

    [ScreenSizes.lgAndAbove]: {
      minHeight: 180,
      marginRight: 0,
      maxWidth: 250,
      justifyContent: "center",
      paddingBottom: "3.5rem",
    },
    [ScreenSizes.xlAndAbove]: {
      maxWidth: 300,
      marginLeft: "2rem",
    },
  },
  ratingAuthorityBadgesContainer: {
    display: "none",
    marginBottom: "1.5rem",
    alignItems: "center",
    justifyContent: "center",
    [ScreenSizes.lgAndAbove]: {
      display: "flex",
    },
  },
  ratingTitle: {
    ...entityPageStyles.ratingTitle,
    color: "#000",
    fontSize: ".8125rem",

    [ScreenSizes.lgAndAbove]: {
      color: colours.white,
      marginTop: "0.3rem",
    },
  },
};

const RatingAuthorityContainer = (props) => {
  const { podcast, titleClaimBadge, handleRatingClick } = props;
  const hasProPermission = useHasProForPodcast(podcast.get("id"));
  const { styles } = useStyles(
    [baseStyles, hasProPermission && proBaseStyles],
    props
  );
  const ariaLabelFunc = useCallback(
    ({ rating }) =>
      `Community Rating: ${Math.round(rating * 100) / 100} out of 5`,
    []
  );

  const userHasRated = podcast.hasIn(["user_data", "rating"]);
  const userRatingData = podcast.getIn(["user_data", "rating"]);
  const userRating = userRatingData && userRatingData.get("rating");
  const userRatingSaving =
    userRatingData && userRatingData.get("rating_saving");

  const ratingStyles = {
    starEmpty: {
      [ScreenSizes.lgAndAbove]: {
        color: "rgba(0,0,0,0.3)",
      },
    },
    starFontSize: {
      fontSize: "1.5rem",

      [ScreenSizes.mdAndAbove]: {
        fontSize: "2.3rem",
      },

      [ScreenSizes.lgAndAbove]: {
        fontSize: hasProPermission ? "1rem" : "1.6rem",
      },
    },
  };

  return (
    <div className={css(styles.ratingAuthorityContainer)}>
      <div
        className={css(styles.ratingContainer)}
        onClick={handleRatingClick}
        onKeyPress={handleRatingClick}
        role="button"
        tabIndex={0}
      >
        {titleClaimBadge}
        {hasProPermission && (
          <div className={css(styles.ratingAuthorityBadgesContainer)}>
            <HeaderVerifiedBadge podcast={podcast} />
            <HeaderAuthorityScore podcast={podcast} styles={props?.styles} />
            <HasGuestsBadge entity={podcast} styles={props?.styles} />
          </div>
        )}
        <div className={css(styles.ratingStarsContainer)}>
          <RatingWithHover
            id={podcast.get("id")}
            rating={podcast.get("rating")}
            styles={ratingStyles}
            entity={podcast}
            ariaLabelFunc={ariaLabelFunc}
            showTooltip
          />
        </div>
        <div className={css(styles.ratingTitle)}>
          <RatingText
            entityName="podcast"
            saving={userRatingSaving}
            ratedByUser={userHasRated}
            userRating={userRating}
            ratingCount={podcast.get("rating_count")}
            friendRatingCount={podcast.get("friend_rating_count")}
            noRatingMessage="Be the first to rate this podcast"
          />
        </div>
      </div>
    </div>
  );
};

RatingAuthorityContainer.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  titleClaimBadge: PropTypes.node,
  handleRatingClick: PropTypes.func.isRequired,
};

export default RatingAuthorityContainer;
