import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const DirectRecommendationsPanel = Loadable(() =>
  import(
    /* webpackChunkName: "DirectRecommendationsPanel" */ "./DirectRecommendationsPanel"
  )
);

function DirectRecommendationsPanelAsyncLoading(props) {
  return <LoadableLoading {...props} transparent height={"9rem"} />;
}

const DirectRecommendationsPanelContainer = (props) => {
  return (
    <DirectRecommendationsPanel
      {...props}
      fallback={<DirectRecommendationsPanelAsyncLoading />}
    />
  );
};

export default DirectRecommendationsPanelContainer;
