import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useMemo, useState } from "react";
import { followEntityPromise, unfollowEntityPromise } from "routines/follow";

import ButtonWithPopout from "components/Buttons/ButtonWithPopout";
import StandardButton from "components/Buttons/StandardButton";

import EntityUnfollowPopover from "./EntityUnfollowPopoverAsync";

import modalActions from "actions/modals";
import { SUBSCRIBABLE_ENTITY_TYPES } from "constants/entity";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useActivityContext from "hooks/useActivityContext";
import useRoutinePromises from "hooks/useRoutinePromises";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  container: {},
  iconContainer: {
    display: "flex",
    gap: "0.2rem",
  },
  iconButton: {
    ...gStyles.fontLight,
    color: colours.white,
    cursor: "pointer",
    top: ".6rem",
    right: "1.5rem",
    fontSize: "1.3rem",
  },
  onClickPreventer: {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10,
  },
};

const popoutStyles = {
  contentWrapper: {
    borderRadius: "1.375rem",
    minWidth: "19.25rem",
  },
  contentWrapperOffset: {
    left: "1.5rem",
  },
  offsetContentDecor: {
    marginLeft: "-3rem",
  },
};

const followedStyles = {
  iconButton: {
    color: colours.primary,
  },
};

function EntityFollowButton(props) {
  const {
    buttonRef,
    currentUserFollows: passedCurrentUserFollows,
    customStyles: passedCustomStyles,
    dataId,
    disabled,
    entity,
    entity_type,
    flat,
    getFollowButtonStyles,
    follow_saving,
    followedVariation,
    fontSize,
    onClick,
    subscribe,
    variation,
  } = props;

  const activityContext = useActivityContext();
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });
  const [isOpen, setIsOpen] = useState(false);

  const customStyles = useMemo(
    () => ({
      button: {
        ...gStyles.avalonBold,
        fontSize: "1rem",
        position: "relative",
        ...(passedCustomStyles || { button: {} }).button,
      },
    }),
    [passedCustomStyles]
  );

  const savingFollow =
    follow_saving !== undefined
      ? follow_saving
      : entity.getIn(["user_data", "follow_saving"]);

  const currentUserFollows =
    passedCurrentUserFollows !== undefined
      ? passedCurrentUserFollows
      : entity.getIn(["user_data", "follows"]);

  const followRequested = entity.getIn(["user_data", "follow_requested"]);
  const isSubscribable = SUBSCRIBABLE_ENTITY_TYPES.includes(entity_type);

  const { styles, css } = useStyles(
    [baseStyles, currentUserFollows && followedStyles],
    props
  );

  const { followEntity, unfollowEntity } = useRoutinePromises({
    followEntity: followEntityPromise,
    unfollowEntity: unfollowEntityPromise,
  });

  const hoverLabel = useMemo(() => {
    if (followRequested) {
      return "Cancel";
    }
    if (currentUserFollows && !isSubscribable) {
      return "Unfollow";
    }

    return null;
  }, [followRequested, currentUserFollows, isSubscribable]);

  const handleFollow = useCallback(
    (popoverProps) => (e) => {
      e.preventDefault();

      if (onClick) {
        onClick(e);
      }

      if (currentUserFollows && isSubscribable) {
        const trackFollowSettingsPanelOpened = () =>
          sendGAEvent({
            action: "followSettingsOpen",
            entity_type,
            entity_id: entity.get("id"),
            entity_name: entity.get("title") || entity.get("name"),
          });

        if (popoverProps) {
          popoverProps.toggleMenu();
          trackFollowSettingsPanelOpened();
        } else {
          showModal("unfollow", {
            entity_type,
            entity,
            analyticsVariables: activityContext,
          });
          trackFollowSettingsPanelOpened();
        }
      } else {
        if (currentUserFollows) {
          unfollowEntity({
            entity_type,
            entity_id: entity.get("id"),
            entity,
            analyticsVariables: activityContext,
            subscribe,
          });
        } else {
          followEntity({
            entity_type,
            entity_id: entity.get("id"),
            entity,
            analyticsVariables: activityContext,
            subscribe,
          });
        }
      }
    },
    [
      activityContext,
      currentUserFollows,
      entity,
      entity_type,
      followEntity,
      unfollowEntity,
      onClick,
      subscribe,
      isSubscribable,
      showModal,
    ]
  );

  const renderFollowingContent = useCallback(
    (popoverProps) => (
      <EntityUnfollowPopover
        onClose={popoverProps.closeMenu}
        entity_type={entity_type}
        entity={entity}
        analyticsVariables={activityContext}
        scheduleUpdate={popoverProps.scheduleUpdate}
      />
    ),
    [entity_type, entity, activityContext]
  );

  const renderLabel = useCallback(
    (popoverProps) => {
      if (currentUserFollows || followRequested) {
        if (isSubscribable) {
          return (
            <Fragment>
              <div className={css(styles.iconContainer)}>
                <span>{followRequested ? "Requested" : "Following"}</span>

                <span
                  className={css(styles.iconButton)}
                  ref={popoverProps && popoverProps.ref}
                >
                  <FontAwesomeIcon
                    icon={
                      popoverProps && popoverProps.isOpen
                        ? faAngleUp
                        : faAngleDown
                    }
                  />
                </span>
              </div>
            </Fragment>
          );
        }

        return "Following";
      }
      if (followRequested) {
        return "Requested";
      }

      return "Follow";
    },
    [
      currentUserFollows,
      css,
      isSubscribable,
      followRequested,
      styles.iconButton,
      styles.iconContainer,
    ]
  );

  const overwriteStyles = useMemo(() => {
    if (getFollowButtonStyles) {
      return getFollowButtonStyles(currentUserFollows);
    }

    return;
  }, [currentUserFollows, getFollowButtonStyles]);

  const renderButton = useCallback(
    (popoverProps) => (
      <StandardButton
        label={renderLabel(popoverProps)}
        hoverLabel={hoverLabel}
        variation={
          currentUserFollows && followedVariation
            ? followedVariation
            : variation
        }
        onClick={handleFollow(popoverProps)}
        fontSize={fontSize}
        active={currentUserFollows || followRequested}
        flat={flat}
        customStyles={customStyles}
        disabled={disabled}
        dataId={dataId}
        dataValue={currentUserFollows ? "following" : "follow"}
        submitting={savingFollow}
        ref={buttonRef}
        overwriteStyles={overwriteStyles}
        rounded
      />
    ),
    [
      buttonRef,
      currentUserFollows,
      customStyles,
      dataId,
      disabled,
      flat,
      followRequested,
      followedVariation,
      fontSize,
      handleFollow,
      hoverLabel,
      overwriteStyles,
      renderLabel,
      savingFollow,
      variation,
    ]
  );

  if (mobile || !isSubscribable) {
    return renderButton();
  }

  return (
    <Fragment>
      <ButtonWithPopout
        renderButton={renderButton}
        renderContent={renderFollowingContent}
        styles={popoutStyles}
        onButtonToggle={setIsOpen}
        isOpen={isOpen}
        offsetArrow
        noPadding
        disablePortal
      />
      {/* the div below stops the follow button reopening the popover,
        when trying to use it to close it. The popover onblur closes it and then
        the onclick reopens it straight away, the div prevents the button onclick*/}
      {isOpen && <div className={css(styles.onClickPreventer)} />}
    </Fragment>
  );
}

EntityFollowButton.propTypes = {
  buttonRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  currentUserFollows: PropTypes.bool,
  customStyles: PropTypes.object,
  dataId: PropTypes.string,
  disabled: PropTypes.bool,
  entity: PropTypes.object.isRequired,
  entity_type: PropTypes.string.isRequired,
  flat: PropTypes.bool,
  follow_saving: PropTypes.bool,
  followedVariation: PropTypes.string,
  fontSize: PropTypes.string,
  onClick: PropTypes.func,
  subscribe: PropTypes.bool,
  getFollowButtonStyles: PropTypes.func,
  variation: PropTypes.string,
};

EntityFollowButton.defaultProps = {
  currentUserFollows: undefined,
  customStyles: undefined,
  dataId: "entity-follow-button",
  disabled: false,
  flat: false,
  follow_saving: undefined,
  followedVariation: null,
  fontSize: "1rem",
  onClick: null,
  subscribe: false,
  variation: "pink",
};

export default memo(EntityFollowButton);
