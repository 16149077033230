import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const EpisodeOverview = Loadable(() => import("./EpisodeOverview"));

function ProAsyncLoading(props) {
  return <AsyncLoadingEntityCard {...props} type="overview" />;
}

const EpisodeOverviewContainer = (props) => {
  return <EpisodeOverview {...props} fallback={<ProAsyncLoading />} />;
};

export default EpisodeOverviewContainer;
