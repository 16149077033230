import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useMemo } from "react";
import Helmet from "react-helmet-async";
import { Link } from "react-router-dom";

import ContentContainer from "components/Common/Layout/ContentContainer";
import AuthorityBadge from "components/Podcast/AuthorityBadgeAsync";
import ClaimedBadge from "components/Podcast/ClaimedBadgeAsync";

import { MISSING_IMAGE_URL } from "constants/base";
import cachedImage from "utils/entity/cachedImage";
import getEntityImageTitle, {
  getEntityImageAlt,
} from "utils/entity/getEntityImageTitle";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import getPodcastAuthorityLevel from "utils/entity/getPodcastAuthorityLevel";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import generateTransition from "utils/generateTransition";

import useEpisodeData from "hooks/useEpisodeData";
import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  navBar: {
    ...gStyles.textEllipsis,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    color: colours.bodyText,
    overflow: "hidden",
    padding: ".8rem 0",
    borderBottom: "1px solid #EEF0F2",

    [ScreenSizes.lgAndAbove]: {
      padding: "1rem 0",
      borderBottom: "none",
    },
  },
  navBarColumn: {
    ...gStyles.textEllipsis,
    color: "#000",
    maxWidth: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  navBarColumnLeft: {
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
  navBarArrow: {
    fontSize: "1.25rem",
  },
  navBarImage: {
    minWidth: 48,
    width: 48,
    height: 48,
    marginLeft: "1rem",
  },
  navBarInfo: {
    ...gStyles.textEllipsis,
    display: "flex",
    flexDirection: "column",
    marginLeft: "1.188rem",
  },
  navBarSubtitle: {
    ...gStyles.textEllipsis,
    ...gStyles.fontRegular,
    fontSize: ".75rem",
  },
  navBarTitle: {
    ...gStyles.textEllipsis,
    ...gStyles.fontBold,
    fontSize: "0.9375rem",
    marginTop: ".25rem",
  },
  navBarMobileButtons: {
    display: "none",
  },
  navBarButtons: {
    borderRadius: 6,
    boxShadow: "0 5px 16px 0 rgba(0, 0, 0, 0.1)",
    height: "2.875rem",
    overflow: "hidden",
    margin: "1rem auto 0",
    color: "#000",
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      margin: 0,
      flex: 0,
      width: "auto",
      flexDirection: "row",
    },
  },
  navBarOneButton: {
    flex: 1,
    maxWidth: "100%",

    [ScreenSizes.lgAndAbove]: {
      maxWidth: 150,
    },
  },
  navBarBothButtons: {
    flex: 1,
    maxWidth: "100%",

    [ScreenSizes.lgAndAbove]: {
      maxWidth: 300,
    },
  },
  navBarButton: {
    ...gStyles.avalonMedium,
    display: "flex",
    flex: 1,
    maxWidth: 150,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: ".813rem",
    whiteSpace: "nowrap",
    height: "100%",
    cursor: "pointer",
    backgroundColor: "#fff",
    transition: generateTransition({ target: "visual", speed: "200ms" }),

    [ScreenSizes.lgAndAbove]: {
      maxWidth: 300,
    },
    ...getHoverQuery({
      backgroundColor: "#F6F6F6",
    }),
  },
  navBarPrevButton: {
    borderRight: "1px solid var(--color-neutral-d9)",
    paddingRight: "1.25rem",
  },
  navBarPrevButtonWithNext: {
    borderRight: "1px solid var(--color-neutral-d9)",
  },
  navBarNextButton: {
    paddingLeft: "1.25rem",
  },
  navBarButtonArrow: {
    margin: "0 1rem",
    fontSize: "1.06rem",
  },
};

const navBarStyles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "auto",
    paddingBottom: 0,
    backgroundColor: "#fff",

    [ScreenSizes.lgAndAbove]: {
      height: "4.375rem",
    },
  },
};

const MAIN_SIZE_IMAGE = 48;

function getImageUrl(imageUrl, mainImageSize) {
  return cachedImage(imageUrl, mainImageSize);
}
const EpisodeNavBar = (props) => {
  const { episodeId, podcastId } = props;

  const podcast = usePodcastData(podcastId);
  const episode = useEpisodeData(episodeId);

  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess, isWindowSizeOrMore } = useWindowSize();

  const next_episode = episode.get("next_episode");
  const previous_episode = episode.get("previous_episode");
  const hasAtleastOneButton = next_episode || previous_episode;
  const hasBothButtons = next_episode && previous_episode;
  const mobile = isWindowSizeOrLess("medium");

  const score = getPodcastAuthorityLevel(podcast, 0);

  const src = podcast.get("image_url");
  const isMissingImage = src === MISSING_IMAGE_URL;

  const podcastHeaderImageUrl = isMissingImage
    ? MISSING_IMAGE_URL
    : getImageUrl(src, MAIN_SIZE_IMAGE);

  const podcastHeaderImageUrlRetina = isMissingImage
    ? MISSING_IMAGE_URL
    : getImageUrl(src, MAIN_SIZE_IMAGE * 2);

  const { alt, title } = useMemo(() => {
    return {
      alt: getEntityImageAlt(podcast, "podcast"),
      title: getEntityImageTitle(podcast, "podcast"),
    };
  }, [podcast]);

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="image"
          href={podcastHeaderImageUrl}
          imagesrcset={`${podcastHeaderImageUrl} 1x, ${podcastHeaderImageUrlRetina} 2x`}
          media="(min-width: 640px)"
        />
        <link
          rel="preload"
          as="image"
          href={podcastHeaderImageUrl}
          media="(max-width: 639px)"
        />
      </Helmet>
      <ContentContainer styles={navBarStyles} noVerticalPadding fullWidth>
        <div className={css(styles.navBar)}>
          <Link
            to={getPodcastUrl(podcast)}
            className={css(styles.navBarColumn, styles.navBarColumnLeft)}
            title={`${podcast.get("title")} Podcast`}
          >
            <span className={css(styles.navBarArrow)}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </span>
            <span className={css(styles.navBarImage)}>
              <picture>
                <source
                  media="(min-width: 640px)"
                  srcSet={`${podcastHeaderImageUrl} 1x, ${podcastHeaderImageUrlRetina} 2x`}
                  className={css(styles.image)}
                  alt={alt}
                  title={title}
                  width={48}
                  height={48}
                />
                <img
                  src={podcastHeaderImageUrl}
                  className={css(styles.image)}
                  alt={alt}
                  title={title}
                  width={48}
                  height={48}
                />
              </picture>
            </span>
            <span className={css(styles.navBarInfo)}>
              <span className={css(styles.navBarSubtitle)}>
                Episode from the podcast
              </span>
              <span className={css(styles.navBarTitle)}>
                {podcast.get("title")}
                {podcast.get("is_claimed") && (
                  <ClaimedBadge
                    showLabel={false}
                    padded={false}
                    showTooltip={isWindowSizeOrMore("medium")}
                  />
                )}
                {score && (
                  <AuthorityBadge
                    entity_type="podcast"
                    entity={podcast}
                    score={getPodcastAuthorityLevel(podcast, 0)}
                    tooltipText={`Power Score: ${getPodcastAuthorityLevel(
                      podcast,
                      2
                    )}`}
                    showTooltip
                    disableLink
                  />
                )}
              </span>
            </span>
          </Link>
          <div
            className={css(
              styles.navBarColumn,
              hasAtleastOneButton && styles.navBarButtons,
              hasAtleastOneButton && styles.navBarOneButton,
              hasBothButtons && styles.navBarBothButtons,
              mobile && styles.navBarMobileButtons
            )}
          >
            {previous_episode && !mobile && (
              <Link
                to={getEpisodeUrl(previous_episode)}
                className={css(
                  styles.navBarButton,
                  styles.navBarPrevButton,
                  next_episode && styles.navBarPrevButtonWithNext
                )}
              >
                <span className={css(styles.navBarButtonArrow)}>
                  <FontAwesomeIcon icon={faCaretLeft} />
                </span>
                Previous {!mobile && "Episode"}
              </Link>
            )}
            {next_episode && !mobile && (
              <Link
                to={getEpisodeUrl(next_episode.set("podcast", podcast))}
                className={css(styles.navBarButton, styles.navBarNextButton)}
              >
                Next {!mobile && "Episode"}
                <span className={css(styles.navBarButtonArrow)}>
                  <FontAwesomeIcon icon={faCaretRight} />
                </span>
              </Link>
            )}
          </div>
        </div>
      </ContentContainer>
    </>
  );
};

EpisodeNavBar.propTypes = {
  podcastId: PropTypes.number.isRequired,
  episodeId: PropTypes.number.isRequired,
};

export default EpisodeNavBar;
