import Loadable from "@loadable/component";
import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "29.5rem",
    marginBottom: "3.25rem",

    [ScreenSizes.mdAndAbove]: {
      height: "32.5rem",
    },
  },
};

const PodcastInsightsSponsorsAsync = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <>
      <div className={css(styles.container)} />
    </>
  );
};

const PodcastInsightsSponsorsAsyncMemo = memo(PodcastInsightsSponsorsAsync);

const PodcastInsightsSponsors = Loadable(() =>
  import(
    /* webpackChunkName: "PodcastInsightsSponsors" */ "./PodcastInsightsSponsors"
  )
);

function PodcastInsightsSponsorsLoading(props) {
  return <PodcastInsightsSponsorsAsyncMemo />;
}

const PodcastInsightsSponsorsContainer = (props) => {
  return (
    <PodcastInsightsSponsors
      {...props}
      fallback={<PodcastInsightsSponsorsLoading />}
    />
  );
};

export default PodcastInsightsSponsorsContainer;
