import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import getListEpisodesHeight from "./getListEpisodesHeight";

import ScreenSizes from "styles/ScreenSizes";

const Episodes = Loadable(() => import("./Episodes"));

const HEIGHT_REST_PAGE = 184;

function EpisodesAsyncLoading(props) {
  const { podcast } = props;

  const listEpisodesHeight = getListEpisodesHeight(podcast);

  const loadingStyles = {
    height: {
      height: listEpisodesHeight + HEIGHT_REST_PAGE,
      maxHeight: listEpisodesHeight + HEIGHT_REST_PAGE,
      minHeight: 0,

      [ScreenSizes.lgAndAbove]: {
        height: "159rem",
        maxHeight: "159rem",
      },
    },
  };

  return <LoadingOverlay height={"225rem"} styles={loadingStyles} />;
}

const EpisodesContainer = (props) => {
  return (
    <Episodes
      {...props}
      fallback={<EpisodesAsyncLoading podcast={props.podcast} />}
    />
  );
};

export default EpisodesContainer;
