import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";

import Title from "components/Entities/Page/Title";

import NoStubItemsMessage from "./NoStubItemsMessage";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  noItemsLink: {
    ...gStyles.fontSemiBold,
    color: colours.primary,
    cursor: "pointer",
    border: "none",
    padding: 0,
  },
  container: {
    height: "14.2rem",
  },
};

const NoFollowersMessage = (props) => {
  const { mobilePodcastFollowButton } = props;

  const { styles } = useStyles(baseStyles, props);

  const handleNoItemsClick = useCallback(
    (event) => {
      event.preventDefault();

      if (mobilePodcastFollowButton && mobilePodcastFollowButton.current) {
        mobilePodcastFollowButton.current?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    [mobilePodcastFollowButton]
  );

  return (
    <div className={css(styles.container)}>
      <Title title="Followers" TitleComponent="h2" />
      <NoStubItemsMessage
        message={
          <span>
            This podcast has no followers yet.{" "}
            <button
              className={css(styles.noItemsLink)}
              onClick={handleNoItemsClick}
            >
              Follow them
            </button>{" "}
            to stay up to date and be notified of new episodes.
          </span>
        }
      />
    </div>
  );
};

NoFollowersMessage.propTypes = {
  mobilePodcastFollowButton: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default memo(NoFollowersMessage);
