import { Map } from "immutable";
import PropTypes from "prop-types";

import NoFollowersMessage from "components/Entities/Stubs/NoFollowersMessage";

import Followers from "../Sidebar/Followers";

import useList from "hooks/useList";

const AboutFollowersMobile = (props) => {
  const { podcast, mobilePodcastFollowButton } = props;

  const followersListKey =
    podcast && `podcastView/${podcast.get("id")}/followersPanel`;

  const { listExists, total, loading } = useList(followersListKey);

  if (!podcast) {
    return null;
  }

  if (listExists && !loading && total === 0) {
    return (
      <NoFollowersMessage
        mobilePodcastFollowButton={mobilePodcastFollowButton}
      />
    );
  }

  return (
    <Followers
      key="followers"
      podcast={podcast}
      mobilePodcastFollowButton={mobilePodcastFollowButton}
      noPanel
      mobile
      renderOnMount
    />
  );
};

AboutFollowersMobile.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  mobilePodcastFollowButton: PropTypes.func,
};

AboutFollowersMobile.defaultProps = {
  podcast: null,
};

export default AboutFollowersMobile;
