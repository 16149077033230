import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import NoCreatorsMessage from "components/Entities/Stubs/NoCreatorsMessage";

import AboutCreators from "./AboutCreators";

import useWindowSize from "hooks/useWindowSize";

const AboutCreatorsContainer = (props) => {
  const { podcast, onTabChange } = props;

  const { isWindowSizeOrLess } = useWindowSize();

  const mobile = isWindowSizeOrLess("medium");

  const showNoCreatorsMessage = useMemo(
    () => podcast?.get("creator_count") === 0,
    [podcast]
  );

  if (!podcast) {
    return null;
  }

  if (showNoCreatorsMessage) {
    return (
      <NoCreatorsMessage
        title="Creators & Guests"
        entity={podcast}
        entityName="podcast"
        entity_type="podcast"
        inSidebar={mobile}
      />
    );
  }

  return (
    <AboutCreators key="creators" onTabChange={onTabChange} podcast={podcast} />
  );
};

AboutCreatorsContainer.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  onTabChange: PropTypes.func.isRequired,
};

AboutCreatorsContainer.defaultProps = {
  podcast: null,
};

export default AboutCreatorsContainer;
