import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo, useContext } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import ShowMoreContainer from "components/Common/ShowMoreContainer";
import PageAbout from "components/Entities/Page/About";
import PageCTABox from "components/Entities/Page/PageCTABox";
import Title from "components/Entities/Page/Title";
import PodcastModeratorTools from "components/Podcast/ModeratorTools/LazyLoader";
import AboutMerch from "pages/PodcastView/About/AboutMerchAsync";
import RefreshPodcastFeed from "pages/PodcastView/Sidebar/RefreshPodcastFeed";
import SideButtonStyles from "pages/PodcastView/Sidebar/SideButtonStyles";
import RequestContext from "pages/RequestContext";

import ApiCTA from "../ApiCTA";
import InsightsStatus from "../Insights/Sections/InsightsStatusAsync";
import ClaimPodcast from "../Sidebar/ClaimPodcast";
import DirectPodcastRecommendations from "../Sidebar/DirectPodcastRecommendations";
import PodcastAffiliates from "../Sidebar/PodcastAffiliatesAsync";
import ProNotes from "../Sidebar/ProNotesAsync";
import SocialLinks from "../Sidebar/SocialLinks";
import ColdCaseAdContainer, {
  canShowColdCaseAd,
} from "../Sidebar/SponsoredContent/ColdCaseAdContainer";
import GoldingsAdContainer, {
  canShowGoldingsAd,
} from "../Sidebar/SponsoredContent/GoldingsAdContainer";
import Stats from "../Sidebar/Stats";
import Tags from "../Sidebar/Tags";
import AboutBestEpisodes from "./AboutBestEpisodes";
import AboutCreatorsContainer from "./AboutCreatorsContainer";
import AboutDisclaimer from "./AboutDisclaimer";
import AboutFollowersMobile from "./AboutFollowersMobile";
import AboutListsContainer from "./AboutListsContainer";
import AboutProcessing from "./AboutProcessing";
import AboutRecentEpisodes from "./AboutRecentEpisodes";
import AboutReviewsContainer from "./AboutReviewsContainer";
import AboutSimilar from "./AboutSimilar";

import affiliates from "constants/affiliates";
import getPodcastStatusDetails from "utils/entity/getPodcastStatusDetails";
import podcastIsInCategory from "utils/entity/podcastIsInCategory";

import useHasProForPodcast from "hooks/useHasProForPodcast";
import useIsModerator from "hooks/useIsModerator";
import { useLoggedIn } from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";
import useTransformRawDescription, {
  TransformRawDescriptionAnalytics,
} from "hooks/useTransformRawDescription";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const showMoreStyles = {
  outer: {
    paddingBottom: 0,

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "1.5rem",
    },
  },
  children: {
    ...gStyles.fontLight,
    color: "#414141",
    fontSize: "0.813rem",
    lineHeight: "18px",
    textAlign: "center",
    padding: "0 1.5rem",

    [ScreenSizes.lgAndAbove]: {
      padding: 0,
      fontSize: "1rem",
      lineHeight: "28px",
      color: "#000",
      textAlign: "left",
    },
  },
  toggleContainer: {
    marginTop: "1.5rem",
    justifyContent: "center",
    minHeight: "1.8rem",

    [ScreenSizes.mdAndAbove]: {
      minHeight: "2.1rem",
    },

    [ScreenSizes.lgAndAbove]: {
      marginTop: "0.6rem",
      justifyContent: "flex-start",
    },
  },
  toggle: {
    ...gStyles.fontSemiBold,
    fontSize: "0.7rem",
    color: colours.bodyText,
  },
};

const mobileShowMoreStyles = {
  ...showMoreStyles,
  children: {
    ...showMoreStyles.children,
    ...gStyles.fontRegular,
    fontSize: ".813rem",
    lineHeight: "18px",
    justifyContent: "center",
    color: "#414141",
    padding: 0,
    textAlign: "left",

    [ScreenSizes.smAndAbove]: {
      lineHeight: "25px",
      fontSize: "1rem",
      padding: "0 1.5rem",
    },
    [ScreenSizes.mdAndAbove]: {
      padding: 0,
    },
  },
  toggle: {
    ...gStyles.fontSemiBold,
    color: colours.primary,
    fontSize: ".813rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: ".938rem",
    },
  },
};

const insightStatusStyles = {
  insightsContent: {
    borderLeft: "1px solid #E3E3E3",
    borderRight: "1px solid #E3E3E3",
  },
};

const statusStyles = {
  insightsContent: {
    borderLeft: "1px solid #E3E3E3",
    borderRight: "1px solid #E3E3E3",
  },
};

const aboutPageStyles = {
  about: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    minWidth: 0,
    flex: "none",
  },
};

const baseStyles = {
  mobileContainer: {
    display: "block",

    [ScreenSizes.mdAndAbove]: {
      display: "none",
    },
  },
  aboutFollowersMobile: {
    height: "14.2rem",

    [ScreenSizes.mdAndAbove]: {
      height: "16.2rem",
    },
  },
  directRecommendation: {
    height: "8.75rem",

    [ScreenSizes.mdAndAbove]: {
      height: "9.3rem",
    },
  },
  pageCTAContainer: {
    height: "14.3rem",
  },
};

const refreshFeedStyles = {
  description: { ...SideButtonStyles.descriptionLast },
};

const About = ({ onTabChange, mobile, podcast }) => {
  const { isWindowSizeOrMore } = useWindowSize();

  const { styles, css } = useStyles(baseStyles);

  const requestContext = useContext(RequestContext);

  const isLoggedIn = useLoggedIn();

  const { isModerator } = useIsModerator();
  const hasProPodcastPermission = useHasProForPodcast(podcast.get("id"));

  const provider = podcast && podcast.get("hosting_provider");
  const hasHostingProvider =
    provider && affiliates && affiliates?.find((aff) => aff.id === provider);

  let toggleHeight = 72;

  if (isWindowSizeOrMore("medium")) {
    toggleHeight = 100;
  }

  if (isWindowSizeOrMore("large")) {
    toggleHeight = 140;
  }

  const getToggleHeightStyles = useCallback((height) => {
    const LARGE_DEVICES_HEIGHT = 140;
    const MEDIUM_DEVICES_HEIGHT = 100;
    const MOBILE_DEVICES_HEIGHT = 72;

    return {
      openStyle: {
        transition: "0.5s",
        height: height > MOBILE_DEVICES_HEIGHT ? height : MOBILE_DEVICES_HEIGHT,

        [ScreenSizes.mdAndAbove]: {
          height:
            height > MEDIUM_DEVICES_HEIGHT ? height : MEDIUM_DEVICES_HEIGHT,
        },
        [ScreenSizes.lgAndAbove]: {
          height: height > LARGE_DEVICES_HEIGHT ? height : LARGE_DEVICES_HEIGHT,
        },
      },
    };
  }, []);

  const showBestEpisodes = useMemo(
    () => podcast.get("episodes_with_ratings_count") > 1,
    [podcast]
  );

  const userHasPro = useHasProForPodcast(podcast && podcast.get("id"));

  const statusDetails = useMemo(
    () =>
      podcast &&
      podcast?.get("full_record") &&
      getPodcastStatusDetails(podcast, userHasPro),
    [podcast, userHasPro]
  );

  const canShowStatus = useMemo(
    () =>
      userHasPro ||
      (!podcastIsInCategory(podcast, "audio-drama") &&
        !podcastIsInCategory(podcast, "fiction") &&
        podcast.get("itunes_type") !== "serial"),
    [podcast, userHasPro]
  );

  const podcastDescription = useTransformRawDescription(
    podcast.get("description") && podcast.get("description_sanitized")
  );

  const showMoreAnalytics = useMemo(
    () => ({
      action: "descriptionLinkOnClick",
      page: "PODCAST_PAGE",
      entity: "podcast",
      entity_id: podcast.get("id"),
      entity_name: podcast.get("name"),
    }),
    [podcast]
  );

  // these all need keys to avoid a client side warning
  const sections = useMemo(
    () => [
      podcast.get("processing_status") === "initialParse" && (
        <AboutProcessing podcast={podcast} key="processing" />
      ),
      !mobile &&
        userHasPro &&
        canShowStatus &&
        statusDetails &&
        statusDetails.proImportant && (
          <InsightsStatus
            podcast={podcast}
            key="proStatus"
            styles={insightStatusStyles}
          />
        ),
      podcast.get("description") && (
        <ShowMoreContainer
          key="description"
          styles={mobile ? mobileShowMoreStyles : showMoreStyles}
          hideOverlay
          checkForContentChanges
          toggleHeight={toggleHeight}
          getToggleHeightStyles={getToggleHeightStyles}
          disableToggleOnContentClick
          shrink
        >
          <TransformRawDescriptionAnalytics analytics={showMoreAnalytics}>
            {podcastDescription}
          </TransformRawDescriptionAnalytics>
        </ShowMoreContainer>
      ),
      mobile && userHasPro && statusDetails && statusDetails.proImportant && (
        <InsightsStatus
          podcast={podcast}
          key="PodcastInsightsStatus"
          styles={statusStyles}
        />
      ),
      canShowGoldingsAd(podcast) && (
        <div key="GoldingsAdContainer" className={css(styles.mobileContainer)}>
          <LazyLoadComponent
            key="GoldingsAdContainer"
            height={"4.1rem"}
            renderOnMount={requestContext.isBotCrawler}
          >
            <GoldingsAdContainer podcastId={podcast?.get("id")} noPanel />
          </LazyLoadComponent>
        </div>
      ),

      <AboutCreatorsContainer
        key="creators"
        onTabChange={onTabChange}
        podcast={podcast}
      />,
      <div className={css(styles.mobileContainer)}>
        <LazyLoadComponent
          key="AboutFollowersMobile"
          renderOnMount={requestContext.isBotCrawler}
          className={css(styles.aboutFollowersMobile)}
        >
          <AboutFollowersMobile podcast={podcast} />
        </LazyLoadComponent>
      </div>,
      mobile && isModerator && (
        <PodcastModeratorTools
          MISSING_PAGES_PODCAST_ID={podcast.get("id")}
          key="modtoolsAbout"
          noPanel
        />
      ),
      <AboutRecentEpisodes
        key="episodes_recent"
        onTabChange={onTabChange}
        podcast={podcast}
      />,
      showBestEpisodes && (
        <AboutBestEpisodes
          key="episodes_ranking"
          onTabChange={onTabChange}
          podcast={podcast}
        />
      ),
      <AboutReviewsContainer
        key="reviews"
        onTabChange={onTabChange}
        podcast={podcast}
      />,
      <AboutListsContainer
        key="lists"
        onTabChange={onTabChange}
        podcast={podcast}
      />,
      <AboutSimilar
        key="similar"
        onTabChange={onTabChange}
        podcast={podcast}
      />,
      podcast.get("merch_enabled") && (
        <AboutMerch key="merch" onTabChange={onTabChange} podcast={podcast} />
      ),
      mobile && hasProPodcastPermission && (
        <LazyLoadComponent
          key="ProNotes"
          height={"9.5rem"}
          renderOnMount={requestContext.isBotCrawler}
        >
          <ProNotes key="proNotes" podcast={podcast} noPanel />
        </LazyLoadComponent>
      ),
      <div className={css(styles.mobileContainer)}>
        <LazyLoadComponent
          key="DirectPodcastRecommendations"
          className={css(styles.directRecommendation)}
          renderOnMount={requestContext.isBotCrawler}
        >
          <DirectPodcastRecommendations
            key="directRecommendations"
            podcast={podcast}
            mobile
            noPanel
          />
        </LazyLoadComponent>
      </div>,
      hasHostingProvider && (
        <div className={css(styles.mobileContainer)}>
          <LazyLoadComponent
            key="PodcastAffiliates"
            renderOnMount={requestContext.isBotCrawler}
          >
            <PodcastAffiliates
              key="podcastAffiliates"
              podcast={podcast}
              mobile
            />
          </LazyLoadComponent>
        </div>
      ),
      <div className={css(styles.mobileContainer)}>
        <div key="claim">
          <Title
            title="Host or manage this podcast?"
            TitleComponent="h3"
            inSidebar
          />
          <ClaimPodcast podcast={podcast} />
          <RefreshPodcastFeed podcast={podcast} styles={refreshFeedStyles} />
        </div>
      </div>,
      <div className={css(styles.mobileContainer)}>
        <Stats key="stats" noPanel podcast={podcast} />
      </div>,
      canShowColdCaseAd(podcast) && (
        <div key="ColdCaseAdContainer" className={css(styles.mobileContainer)}>
          <LazyLoadComponent
            key="ColdCaseAdContainer"
            height={"4.1rem"}
            renderOnMount={requestContext.isBotCrawler}
          >
            <ColdCaseAdContainer podcastId={podcast?.get("id")} noPanel />
          </LazyLoadComponent>
        </div>
      ),
      <div className={css(styles.mobileContainer)}>
        <Tags key="tags" noPanel podcast={podcast} />
      </div>,
      mobile && {
        panel: true,
        component: (
          <div className={css(styles.mobileContainer)}>
            <SocialLinks
              key="socialLinks"
              noPanel
              podcastId={podcast.get("id")}
            />
          </div>
        ),
      },
      mobile && {
        panel: false,
        component: (
          <div className={css(styles.mobileContainer)}>
            <ApiCTA />
          </div>
        ),
      },
      <AboutDisclaimer key="disclaimer" />,
      !isLoggedIn &&
        mobile && {
          panel: false,
          component: (
            <div className={css(styles.mobileContainer)}>
              <LazyLoadComponent
                key="PageCTABox"
                renderOnMount={requestContext.isBotCrawler}
                className={css(styles.pageCTAContainer)}
              >
                <PageCTABox
                  key="podcastPageCTABox"
                  sidebarPanelProps={{ renderOnMount: true }}
                />
              </LazyLoadComponent>
            </div>
          ),
        },
    ],
    [
      canShowStatus,
      css,
      getToggleHeightStyles,
      hasHostingProvider,
      hasProPodcastPermission,
      isLoggedIn,
      isModerator,
      mobile,
      onTabChange,
      podcast,
      podcastDescription,
      requestContext.isBotCrawler,
      showBestEpisodes,
      showMoreAnalytics,
      statusDetails,
      styles.aboutFollowersMobile,
      styles.directRecommendation,
      styles.mobileContainer,
      styles.pageCTAContainer,
      toggleHeight,
      userHasPro,
    ]
  );

  return (
    <PageAbout
      styles={aboutPageStyles}
      mobile={mobile}
      sections={sections}
      key={`podcast_about_${podcast.get("id")}`}
      renderOnMount
    />
  );
};

About.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  podcast: PropTypes.instanceOf(Map).isRequired,
  mobile: PropTypes.bool,
};

About.defaultProps = {
  mobile: false,
};

export default About;
