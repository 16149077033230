import { formatDistance } from "date-fns/formatDistance";
import Immutable from "immutable";
import PropTypes from "prop-types";

import StatsPanel from "components/Entities/Page/StatsPanel";

import getPeriodFromDays from "utils/date/getPeriodFromDays";
import { podcastExclusiveTo } from "utils/entity/exclusiveTo";
import formatFieldDate from "utils/entity/formatFieldDate";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import getNetworkUrl, {
  getNetworkUrlByEntity,
} from "utils/entity/getNetworkUrl";
import getPodcastStatusDetails, {
  getPodcastStatus,
} from "utils/entity/getPodcastStatusDetails";
import { capitalize } from "utils/misc";

import useWindowSize from "hooks/useWindowSize";

const CONFIG = [
  // Similar code in InsightsDetails
  {
    title: "Created by",
    value: "itunes_owner_name",
  },
  {
    title: "Podcast Status",
    format: (podcast, userHasPro) => getPodcastStatus(podcast, userHasPro),
    infoMessage: (podcast, userHasPro) => {
      const details = getPodcastStatusDetails(podcast, userHasPro);
      return details ? details.status : null;
    },
    condition: () => true,
  },
  {
    title: "Started",
    format: (podcast) => formatFieldDate(podcast, "date_of_first_episode"),
  },
  {
    title: "Latest Episode",
    metaTitle: (podcast) =>
      `Episode: ${podcast.getIn(["latest_episode", "title"])}`,
    format: (podcast) => formatFieldDate(podcast, "date_of_latest_episode"),
    to: (podcast) =>
      podcast.get("latest_episode") &&
      getEpisodeUrl(podcast.get("latest_episode"), { podcast }),
  },
  {
    title: "Release Period",
    format: (podcast) => {
      if (
        podcast.get("days_between_episodes") &&
        podcast.get("days_between_episodes") > 0
      ) {
        return getPeriodFromDays(podcast.get("days_between_episodes"), {
          exactOnly: false,
        });
      }

      return null;
    },
  },
  {
    title: "Seasons",
    format: (podcast) => {
      const numberOfSeasons = podcast.get("number_of_seasons");

      if (numberOfSeasons > 0) {
        return numberOfSeasons;
      }

      return null;
    },
  },
  {
    title: "Episodes",
    format: (podcast) => podcast.get("number_of_episodes"),
  },
  {
    title: "Avg. Episode Length",
    format: (podcast) => {
      if (podcast.get("average_episode_length_seconds")) {
        const avgLength = formatDistance(
          podcast.get("average_episode_length_seconds") * 1000,
          0
        );

        return capitalize(avgLength);
      }

      return null;
    },
  },
  {
    title: "Explicit",
    format: (episode) => {
      if (!episode.has("is_explicit")) {
        return null;
      }
      return episode.get("is_explicit") ? "Yes" : "No";
    },
  },
  {
    title: "Order",
    format: (pod) => {
      if (pod.get("itunes_type")) {
        return capitalize(pod.get("itunes_type"));
      }

      return null;
    },
  },
  {
    title: "Language",
    format: (pod) => {
      const language = pod.getIn(["language", "name"]);

      if (language && language !== "Unknown") {
        return language;
      }

      return null;
    },
  },
  {
    title: "Exclusive To",
    format: () => "Spotify",
    condition: (pod) => podcastExclusiveTo(pod, "spotify"),
  },
  {
    title: "Network",
    to: (pod, userIsInternal) => {
      const network = pod?.get("networks")?.first();

      let networkUrl = getNetworkUrl(network);
      if (userIsInternal) {
        networkUrl = getNetworkUrlByEntity(network);
      }

      return networkUrl;
    },
    format: (pod) => {
      const networkName = pod?.get("networks")?.first()?.get("title");

      return networkName;
    },
  },
];

const Stats = (props) => {
  const { podcast, noPanel } = props;
  const { isWindowSizeOrLess } = useWindowSize();
  let config = CONFIG;

  if (isWindowSizeOrLess("medium")) {
    config = [...CONFIG];
  }
  return (
    <StatsPanel
      title="Podcast Details"
      entity={podcast}
      statsConfig={config}
      noPanel={noPanel}
      renderOnMount
    />
  );
};

Stats.propTypes = {
  podcast: PropTypes.instanceOf(Immutable.Map).isRequired,
  noPanel: PropTypes.bool,
};
Stats.defaultProps = {
  noPanel: false,
};

export default Stats;
