import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const ClaimPodcast = Loadable(() =>
  import(/* webpackChunkName: "ClaimPodcast" */ "./ClaimPodcast")
);

function ClaimPodcastLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const ClaimPodcastContainer = (props) => {
  return <ClaimPodcast {...props} fallback={<ClaimPodcastLoading />} />;
};

export default ClaimPodcastContainer;
