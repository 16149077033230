import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const PodcastCompactCard = Loadable(() => import("./PodcastCompactCard"));

function ProAsyncLoading(props) {
  return <AsyncLoadingEntityCard {...props} type="compact" />;
}

const PodcastCompactCardContainer = (props) => {
  return <PodcastCompactCard {...props} fallback={<ProAsyncLoading />} />;
};

export default PodcastCompactCardContainer;
