import Loadable from "@loadable/component";

const PodcastViewSidebar = Loadable(() =>
  import(/* webpackChunkName: "PodcastViewSidebar" */ "./PodcastViewSidebar")
);

function PodcastViewSidebarAsyncLoading(props) {
  return null;
}

const PodcastViewSidebarContainer = (props) => {
  return (
    <PodcastViewSidebar
      {...props}
      fallback={<PodcastViewSidebarAsyncLoading />}
    />
  );
};

export default PodcastViewSidebarContainer;
