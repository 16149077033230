import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";

import { TabsContext } from "components/Common/Tabs";
import CreatorsStub from "components/Entities/Stubs/CreatorsStub";

import getPodcastUrl from "utils/entity/getPodcastUrl";

import ScreenSizes from "styles/ScreenSizes";

export const creatorItemStyles = {
  creatorItemImgContainer: {
    minWidth: "5rem",
    width: "5rem",
    height: "5rem",

    [ScreenSizes.smAndAbove]: {
      minWidth: "5.313rem",
      width: "5.313rem",
      height: "5.313rem",
    },

    [ScreenSizes.lgAndAbove]: {
      minWidth: "initial",
      width: "100%",
      height: "initial",
    },
  },
  creatorItemDescriptionRoleOverThumbnail: {
    transform: "none",
  },
};

export const creatorsListStyles = {
  creatorPanelsOuter: {
    minHeight: "16.8rem",

    [ScreenSizes.lgAndAbove]: {
      maxHeight: "12rem",
      overflow: "hidden",
    },

    [ScreenSizes.xxlAndAbove]: {
      minHeight: "13.25rem",
    },
  },
};
const CREATOR_IMAGE_STYLE_OVERRIDE = {
  imageFallback: {
    height: "100%",
  },
};

const AboutCreators = ({ podcast, onTabChange }) => {
  const creatorsOnTabChange = useCallback(
    () => onTabChange("creators"),
    [onTabChange]
  );

  const staticFilters = useMemo(
    () => ({
      podcast_id: {
        value: podcast && podcast.get("id"),
      },
    }),
    [podcast]
  );

  const listConfig = useMemo(
    () => ({
      loadingStyles: {
        noOverlay: {
          padding: "4rem 0",
          fontSize: "2rem",
          height: "14rem",

          [ScreenSizes.xxlAndAbove]: {
            height: "13.25rem",
          },
        },
      },
    }),
    []
  );

  const { contentRef } = useContext(TabsContext);

  return (
    <CreatorsStub
      listKey={`podcastView/${podcast.get("id")}/aboutCreators`}
      title="Creators & Guests"
      onTabChange={creatorsOnTabChange}
      staticFilters={staticFilters}
      entityName="podcast"
      entity_type="podcast"
      entity={podcast}
      scrollRef={contentRef}
      creatorItemStyles={creatorItemStyles}
      viewAllLink={getPodcastUrl(podcast, "creators")}
      viewAllTitle={`Creators and Guests of ${podcast.get("title")}`}
      listConfig={listConfig}
      renderOnMount
      styles={creatorsListStyles}
      passedImageStyles={CREATOR_IMAGE_STYLE_OVERRIDE}
    />
  );
};

AboutCreators.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  podcast: PropTypes.instanceOf(Map),
};
AboutCreators.defaultProps = {
  podcast: null,
};

export default AboutCreators;
