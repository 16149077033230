import PropTypes from "prop-types";

import ColdCaseAd from "./ColdCaseAdAsync";

import { PODCAST_CATEGORIES } from "constants/podcast";

import usePodcastData from "hooks/usePodcastData";

export const canShowColdCaseAd = (podcast) => {
  const categories = podcast?.get("categories");

  return categories?.contains(PODCAST_CATEGORIES.TRUE_CRIME);
};

const ColdCaseAdContainer = (props) => {
  const { noPanel, podcastId } = props;

  const podcast = usePodcastData(podcastId);

  if (canShowColdCaseAd(podcast)) {
    return <ColdCaseAd noPanel={noPanel} podcastId={podcastId} />;
  }

  return null;
};

ColdCaseAdContainer.propTypes = {
  noPanel: PropTypes.bool,
  podcastId: PropTypes.number,
};

ColdCaseAdContainer.defaultProps = {
  noPanel: false,
};

export default ColdCaseAdContainer;
