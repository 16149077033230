import Loadable from "@loadable/component";
const PodcastTourPrompt = Loadable(() => import("./PodcastTourPrompt"));

function AboutAsyncLoading() {
  return null;
}

const PodcastTourPromptContainer = (props) => {
  return <PodcastTourPrompt {...props} fallback={<AboutAsyncLoading />} />;
};

export default PodcastTourPromptContainer;
