import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo, useContext } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import NoReviewsMessage from "components/Entities/Stubs/NoReviewsMessage";
import RequestContext from "pages/RequestContext";

import AboutReviews from "./AboutReviews";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    height: "23.9rem",

    [ScreenSizes.mdAndAbove]: {
      height: "18rem",
    },
  },
};

const AboutReviewsContainer = (props) => {
  const { podcast, onTabChange } = props;

  const requestContext = useContext(RequestContext);

  const { isWindowSizeOrLess } = useWindowSize();

  const { styles, css } = useStyles(baseStyles);

  const mobile = isWindowSizeOrLess("medium");

  const showNoReviewsMessage = useMemo(
    () => podcast.get("review_count") === 0,
    [podcast]
  );

  if (!podcast) {
    return null;
  }

  if (showNoReviewsMessage) {
    return (
      <NoReviewsMessage
        title="Podcast Reviews"
        entity={podcast}
        entityName="podcast"
        entity_type="podcast"
        inSidebar={mobile}
      />
    );
  }
  return (
    <LazyLoadComponent
      className={css(styles.container)}
      renderOnMount={requestContext.isBotCrawler}
    >
      <AboutReviews key="reviews" onTabChange={onTabChange} podcast={podcast} />
    </LazyLoadComponent>
  );
};

AboutReviewsContainer.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  onTabChange: PropTypes.func.isRequired,
};

AboutReviewsContainer.defaultProps = {
  podcast: null,
};

export default AboutReviewsContainer;
