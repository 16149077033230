import Immutable from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";
import PageSidebarPanel from "components/Entities/Page/PageSidebarPanel";
import Title from "components/Entities/Page/Title";
import CategoryTags from "components/Podcast/CategoryTags";

import ratingActionCreators from "actions/rating";

import useActionCreators from "hooks/useActionCreators";

import colours from "styles/colours";

const tagStyles = {
  categoriesContainer: {},
  mediumCategoriesContainer: {
    justifyContent: "flex-start",
  },
  category: {
    backgroundColor: colours.lightGreyishBlue,
    fontSize: "0.625rem",
    padding: "0.6em 0.9em 0.5em",
    marginRight: "0.5em",
  },
};

function TagsPanel(props) {
  const { noPanel, title, categories, entity_type, entity, index } = props;

  const { openEpisodeRatingModal, openPodcastRatingModal } =
    useActionCreators(ratingActionCreators);

  const handleAddTagsClick = useCallback(() => {
    if (entity_type === "episode") {
      openEpisodeRatingModal(entity.get("id"), {
        initialTab: "tags",
        entity,
        entityAction: "add_tags",
      });
    } else {
      openPodcastRatingModal(entity.get("id"), {
        initialTab: "tags",
        entity,
        entityAction: "add_tags",
      });
    }
  }, [entity, entity_type, openEpisodeRatingModal, openPodcastRatingModal]);

  const content = (
    <>
      <Title title={title} TitleComponent="h3" inSidebar />
      <CategoryTags
        styles={tagStyles}
        categories={categories}
        maxCharacters={500}
        medium
        type="hashtag"
        urlPath={entity_type === "podcast" ? "/podcasts" : "/episodes"}
      />
      <StandardButton
        label="Add Tags"
        variation="white"
        onClick={handleAddTagsClick}
        flat
        rounded
      />
    </>
  );

  if (noPanel) {
    return <Fragment>{content}</Fragment>;
  }

  return (
    <PageSidebarPanel index={index} renderOnMount>
      {content}
    </PageSidebarPanel>
  );
}

TagsPanel.propTypes = {
  entity: PropTypes.instanceOf(Immutable.Map),
  entity_type: PropTypes.string,
  noPanel: PropTypes.bool,
  title: PropTypes.string,
  categories: PropTypes.instanceOf(Immutable.List),
  index: PropTypes.number,
};

TagsPanel.defaultProps = {
  entity: Immutable.Map({}),
  entity_type: "podcast",
  noPanel: false,
  title: "Tags",
  categories: null,
  index: 0,
};

export default memo(TagsPanel);
