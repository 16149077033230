import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import merge from "ramda/src/mergeDeepRight";
import { memo, Fragment, useCallback, useMemo, useRef } from "react";

import EpisodeCardContainer from "components/Entities/Cards/EpisodeCards/EpisodeCardContainer";
import entityList from "components/Entities/EntityList";
import Title from "components/Entities/Page/Title";
import { useStubLinkClick } from "components/Entities/Stubs/useStubLinkClick";

import NoStubItemsMessage from "./NoStubItemsMessage";
import StubLink from "./StubLink";

import * as sortConstants from "constants/sort";
import loadEpisodesList from "sagas/pagination/lists/loadEpisodesList";
import { selectList } from "selectors/pagination";
import { selectSpecificEpisode } from "selectors/podcast";
import getSortTitle from "utils/getSortTitle";

import useList from "hooks/useList";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  link: {
    marginTop: ".8rem",
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.mdAndAbove]: {
      display: "none",
    },
  },
};

const entityCardStyles = {
  entityCard: {
    marginBottom: "1rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "1.5rem",
    },
  },
};

const EpisodesStub = (props) => {
  const {
    onTabChange,
    listKey,
    staticFilters,
    linkLabel,
    hidePodcastName,
    listConfig,
    noItemsMessage,
    showMore,
    scrollRef,
    inlineContent,
    entityCardProps,
    podcast,
    viewAllLink,
    viewAllTitle,
    inSidebar,
    styles: passedEpisodeStyles,
    renderItem: passedRenderItem,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess, isWindowSize } = useWindowSize();
  const isMedium = isWindowSizeOrLess("medium");
  const isSmallOrLess = isWindowSizeOrLess("small");
  const mobile = isWindowSize("large") || isSmallOrLess;

  const list = useReduxState(
    (state) => selectList(state, { key: listKey }),
    [listKey]
  );

  const handleLinkClick = useStubLinkClick(onTabChange, scrollRef);

  const episodeStyles = useMemo(() => {
    if (passedEpisodeStyles) {
      return merge(entityCardStyles, passedEpisodeStyles);
    }

    return entityCardStyles;
  }, [passedEpisodeStyles]);

  const EpisodesList = useRef(
    entityList({
      key: listKey,
      sort: sortConstants.SORT_ORDER_BEST_OPTION,
      list_type: "episodes_filtered",
      loadListAction: loadEpisodesList,
      entity_type: "episode",
      pageSize: 3,
      staticFilters,
      getEntity: (state, id) => selectSpecificEpisode(state, id),
      loadingStyles: {
        noOverlay: {
          padding: "4rem 0",
          fontSize: "2rem",
        },
      },
      ...listConfig,
    })
  ).current;

  const { list: listDetails } = useList(listKey);

  const overrideSortTitles = {
    [sortConstants.SORT_ORDER_RANKING]: {
      asc: "Lowest Rated",
      desc: "Best",
    },
  };
  const sortTitle = getSortTitle({
    sort: listDetails.get("sort"),
    sortDirection: listDetails.get("sort_direction"),
    overrideSortTitles,
  });

  const title = `${sortTitle} Episodes`;

  const renderItem = useCallback(
    (episode, index) => {
      if (passedRenderItem) {
        return passedRenderItem({
          key: episode ? episode.get("id") : index,
          styles: episodeStyles,
          entity: episode,
          mobile,
          hidePodcast: hidePodcastName,
          noPanel: isSmallOrLess,
          hideEmptyRating: false,
          showEllipsisMenu: true,
          renderOnMount: true,
          ...entityCardProps,
        });
      }

      return (
        <EpisodeCardContainer
          key={episode ? episode.get("id") : index}
          styles={episodeStyles}
          entity={episode}
          mobile={mobile}
          hidePodcast={hidePodcastName}
          noPanel={isSmallOrLess}
          hideEmptyRating={false}
          showEllipsisMenu
          renderOnMount
          {...entityCardProps}
        />
      );
    },
    [
      passedRenderItem,
      episodeStyles,
      mobile,
      hidePodcastName,
      isSmallOrLess,
      entityCardProps,
    ]
  );

  if (list && list.get("total") === 0 && list.get("loaded")) {
    return (
      <Fragment>
        <Title title={title} TitleComponent="h2" inSidebar={inSidebar} />
        <NoStubItemsMessage
          message={
            noItemsMessage || (
              <span>
                There are no episodes of {`"${podcast.get("title")}"`} to
                display at the moment.{" "}
                {podcast.get("processing_status") === "initialParse" && (
                  <span>
                    This podcast is still being processed, so episodes will be
                    available soon.
                  </span>
                )}
              </span>
            )
          }
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Title
        title={title}
        onTabChange={handleLinkClick}
        count={isMedium ? list && list.get("total") : null}
        TitleComponent="h2"
        inlineContent={inlineContent}
        link={viewAllLink}
        viewAllTitle={
          viewAllTitle ||
          (podcast.get("title") &&
            `Podcast Episodes of ${podcast.get("title")}`) ||
          "Podcast Episodes"
        }
        inSidebar={inSidebar}
      />
      <EpisodesList
        renderItem={renderItem}
        showMore={showMore}
        onMoreClick={handleLinkClick}
      />
      <div className={css(styles.mobileContainer)}>
        <StubLink
          className={css(styles.link)}
          onClick={handleLinkClick}
          label={linkLabel}
        />
      </div>
    </Fragment>
  );
};

EpisodesStub.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  listKey: PropTypes.string.isRequired,
  staticFilters: PropTypes.object,
  linkLabel: PropTypes.string,
  list: PropTypes.object,
  hidePodcastName: PropTypes.bool,
  listConfig: PropTypes.object,
  noItemsMessage: PropTypes.node,
  name: PropTypes.node,
  showMore: PropTypes.bool,
  scrollRef: PropTypes.object,
  inlineContent: PropTypes.node,
  entityCardProps: PropTypes.object,
  viewAllLink: PropTypes.string,
  viewAllTitle: PropTypes.string,
  inSidebar: PropTypes.bool,
  podcast: PropTypes.instanceOf(Map),
  styles: PropTypes.object,
  renderItem: PropTypes.func,
};

EpisodesStub.defaultProps = {
  staticFilters: null,
  linkLabel: "View All",
  hidePodcastName: false,
  listConfig: {},
  noItemsMessage: null,
  name: "this podcast",
  showMore: false,
  scrollRef: null,
  inlineContent: null,
  entityCardProps: null,
  viewAllLink: null,
  viewAllTitle: null,
  inSidebar: false,
  podcast: null,
  styles: {},
};

export default memo(EpisodesStub);
