import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import AddEntityButton from "components/Entities/Page/AddEntityButton";

import ratingActionCreators from "actions/rating";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";

const AddReviewButton = (props) => {
  const { entity, entity_type, onRatingClick } = props;

  const { openPodcastRatingModal, openEpisodeRatingModal } =
    useActionCreators(ratingActionCreators);

  const handleClick = useCallback(
    (e) => {
      if (onRatingClick) {
        onRatingClick(e);
      } else if (entity_type === "podcast") {
        openPodcastRatingModal(entity.get("id"), { entity });
      } else if (entity_type === "episode") {
        openEpisodeRatingModal(entity.get("id"), { entity });
      }

      sendGAEvent({
        action: `${entity_type}AboutPage-AddReviewClicked`,
        [`${entity_type}_id`]: entity.get("id"),
        [`${entity_type}_name`]:
          entity && (entity.get("title") || entity.get("name")),
      });
    },
    [
      openPodcastRatingModal,
      openEpisodeRatingModal,
      onRatingClick,
      entity,
      entity_type,
    ]
  );

  return <AddEntityButton onClick={handleClick} label="add a review" />;
};

AddReviewButton.propTypes = {
  entity: PropTypes.object.isRequired,
  entity_type: PropTypes.string.isRequired,
  onRatingClick: PropTypes.func,
};
AddReviewButton.defaultProps = {
  onRatingClick: null,
};

export default memo(AddReviewButton);
