import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";
import Rating from "react-rating";

import BasicTooltip from "../Common/Tooltip/BasicTooltip";
import RatingStars from "./RatingStarsAsync";

import ratingActions from "actions/rating";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";
import withHoverDecorator from "utils/withHover";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  outer: {
    cursor: "pointer",
  },
  outerNoHover: {
    cursor: "inherit",
  },
  outerNoOnClick: {
    cursor: "inherit",
  },
  star: {
    ...gStyles.star,
    cursor: "pointer",
  },
  starNoClick: {
    cursor: "inherit",
  },
  starEmpty: {
    color: colours.stars.empty,
  },
  starFilled: {
    color: colours.stars.filled,
  },
  starFilledEstimated: {
    color: colours.stars.estimated,
  },
  additionalInfo: {
    position: "absolute", // Needed to fix positioning issue in feed card intro for some reason
  },
};

const hoverableStarsStyles = {
  ratingStars: {
    display: "initial",
  },
};

const HoverRating = withHoverDecorator(Rating, { ComponentType: "span" });

const RatingWithHover = (props) => {
  const {
    // organization,
    rating,
    numRatings,
    id,
    isEstimated,
    onClick,
    onChange,
    withHover,
    fontSize,
    ComponentType,
    hoverableStars,
    clickableStars,
    variation,
    entity,
    entity_type,
    entity_id,
    ariaLabel,
    ariaLabelFunc,
    showTooltip,
    tooltipProps,
    tooltipLabel,
  } = props;

  const isClickable = clickableStars || onClick;

  const { styles } = useStyles(
    [
      baseStyles,
      {
        baseFontSize: {
          fontSize: fontSize || "1em",
        },
        star: hoverableStars
          ? {
              display: "block",
              marginLeft: ".03rem",
              marginRight: ".03rem",
            }
          : {},
        starFull: {
          fontSize: fontSize || "1em",
          transform: "scale(1)",
        },
        starEmpty: {
          fontSize: fontSize || "1em",
          transform: "scale(1)",
          color: colours.stars.empty,
          opacity: 1,
        },
        starFontSize: {
          fontSize: fontSize || "1em",
        },
      },
    ],
    props
  );

  const ratingLabel = useMemo(() => {
    if (tooltipLabel || numRatings) {
      return `Rated ${Math.round(rating * 100) / 100} from ${numRatings} votes`;
    }

    return "";
  }, [tooltipLabel, rating, numRatings]);

  const { openRatingModal } = useActionCreators({
    openRatingModal: ratingActions.openRatingModal,
  });

  const handleRatingsChange = useCallback(
    (newRating = null) =>
      openRatingModal({
        entity,
        entity_type,
        entity_id: entity ? entity.get("id") : entity_id,
        initialRating: newRating,
      }),
    [openRatingModal, entity, entity_type, entity_id]
  );

  const handleClick = useCallback(() => {
    if (clickableStars) {
      handleRatingsChange();
    } else if (onClick) {
      onClick();
    }
  }, [onClick, clickableStars, handleRatingsChange]);

  const handleKeyDown = useCallback(
    (e) => {
      if (eventIsFieldTrigger(e)) {
        handleClick(e);
      }
    },
    [handleClick]
  );

  const renderHoverRating = () => (
    <HoverRating
      id={`rating-${id}`}
      className={css(styles.ratingsWrapper)}
      onChange={onChange || (() => null)}
      readonly={!onChange}
      emptySymbol={
        <span
          className={css(
            styles.star,
            !isClickable && styles.starNoClick,
            styles.starEmpty,
            styles.starFontSize
          )}
        >
          <FontAwesomeIcon icon={faStar} />
        </span>
      }
      fullSymbol={
        <span
          className={css(
            styles.star,
            !isClickable && styles.starNoClick,
            isEstimated ? styles.starFilledEstimated : styles.starFilled,
            styles.starFontSize
          )}
        >
          <FontAwesomeIcon icon={faStar} />
        </span>
      }
      initialRating={parseFloat(rating)}
      passDisplayValue={false}
      ComponentType={ComponentType}
    />
  );

  const renderHoverableRatingStars = () => (
    <RatingStars
      styles={hoverableStarsStyles}
      rating={parseFloat(rating)}
      onChange={handleRatingsChange}
      variation={variation}
      fullAdditionalStyles={styles}
      emptyAdditionalStyles={styles}
    />
  );

  const finalRating = (
    <ComponentType
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      className={css(
        styles.outer,
        styles.baseFontSize,
        !withHover && styles.outerNoHover,
        !isClickable && styles.outerNoOnClick
      )}
      role="img"
      aria-label={
        (ariaLabelFunc && ariaLabelFunc({ rating })) ||
        (ariaLabel &&
          ariaLabel.replace(
            "[rating]",
            (Math.round(rating * 100) / 100).toString()
          )) ||
        `Rating: ${Math.round(rating * 100) / 100} out of 5`
      }
    >
      {hoverableStars ? renderHoverableRatingStars() : renderHoverRating()}
    </ComponentType>
  );

  if (showTooltip && ratingLabel) {
    return (
      <BasicTooltip
        renderTooltip={() => ratingLabel}
        enterTimeout={600}
        {...tooltipProps}
      >
        {(tooltipProps) => <div {...tooltipProps}>{finalRating}</div>}
      </BasicTooltip>
    );
  }
  return finalRating;
};

RatingWithHover.propTypes = {
  rating: PropTypes.number,
  numRatings: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEstimated: PropTypes.bool,
  onClick: PropTypes.func,
  // organization: PropTypes.string,
  withHover: PropTypes.bool,
  onChange: PropTypes.func,
  ComponentType: PropTypes.node,
  hoverableStars: PropTypes.bool,
  clickableStars: PropTypes.bool,
  variation: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  entity_id: PropTypes.string,
  ariaLabel: PropTypes.string,
  ariaLabelFunc: PropTypes.func,
  showTooltip: PropTypes.bool,
  tooltipProps: PropTypes.object,
  tooltipLabel: PropTypes.string,
};

RatingWithHover.defaultProps = {
  numRatings: null,
  rating: null,
  fontSize: null,
  // organization: 'Podchaser',
  isAggregate: true,
  isEstimated: false,
  onClick: null,
  withHover: true,
  onChange: null,
  dataId: null,
  ComponentType: "div",
  entity: null,
  hideMeta: false,
  author: null,
  ariaLabel: null,
  ariaLabelFunc: null,
  showTooltip: null,
  tooltipProps: null,
  tooltipLabel: null,
  hoverableStars: false,
  clickableStars: false,
  variation: "primary",
};

export default memo(RatingWithHover);
