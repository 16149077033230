import Loadable from "@loadable/component";
const AboutMerch = Loadable(() =>
  import(/* webpackChunkName: "AboutMerch" */ "./AboutMerch")
);

function AboutMerchAsyncLoading(props) {
  return null;
}

const AboutMerchContainer = (props) => {
  return <AboutMerch {...props} fallback={<AboutMerchAsyncLoading />} />;
};

export default AboutMerchContainer;
