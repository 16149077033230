import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

export default {
  button: {
    marginBottom: ".5rem",
  },
  description: {
    ...gStyles.fontMediumItalic,
    color: "#555",
    fontSize: ".75rem",
    letterSpacing: 0,
    lineHeight: "1rem",
    textAlign: "center",
    marginBottom: "2rem",
  },
  descriptionError: {
    color: colours.negative,
  },
  descriptionLast: {
    marginBottom: 0,
  },
  descriptionWithBottomMargin: {
    [ScreenSizes.lgAndAbove]: {
      marginBottom: "3rem",
    },
  },
};
