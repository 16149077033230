import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "312rem",
    maxHeight: "312rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "136rem",
      maxHeight: "136rem",
    },
  },
};

const Reviews = Loadable(() => import("./Reviews"));

function ReviewsAsyncLoading(props) {
  return <LoadingOverlay height={"312rem"} styles={loadingStyles} />;
}

const ReviewsContainer = (props) => {
  return <Reviews {...props} fallback={<ReviewsAsyncLoading />} />;
};

export default ReviewsContainer;
