import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo, useContext } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import ShowMoreContainer from "components/Common/ShowMoreContainer";
import About from "components/Entities/Page/About";
import PageCTABox from "components/Entities/Page/PageCTABox";
import Title from "components/Entities/Page/TitleAsync";
import ClaimPodcast from "pages/PodcastView/Sidebar/ClaimPodcastAsync";
import RequestContext from "pages/RequestContext";

import ApiCTA from "../ApiCTA";
import DirectEpisodeRecommendations from "../Sidebar/DirectEpisodeRecommendations";
import EpisodeAffiliates from "../Sidebar/EpisodeAffiliates";
import PodcastInfo from "../Sidebar/PodcastInfo";
import SocialLinks from "../Sidebar/SocialLinks";
import Stats from "../Sidebar/Stats";
import Tags from "../Sidebar/Tags";
import AboutCreatorsContainer from "./AboutCreatorsContainer";
import AboutDisclaimer from "./AboutDisclaimer";
import AboutHate from "./AboutHateAsync";
import AboutListsContainer from "./AboutListsContainer";
import AboutReviewsContainer from "./AboutReviewsContainer";

import affiliates from "constants/affiliates";
import { selectSpecificPodcast } from "selectors/podcast";

import { useLoggedIn } from "hooks/useLoggedInUser";
import useReduxState from "hooks/useReduxState";
import useTransformRawDescription, {
  TransformRawDescriptionAnalytics,
} from "hooks/useTransformRawDescription";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const showMoreStyles = {
  outer: {
    paddingBottom: 0,

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "1.5rem",
    },
  },
  children: {
    ...gStyles.fontLight,
    color: "#414141",
    fontSize: "0.813rem",
    lineHeight: "18px",
    textAlign: "center",
    padding: "0 1.5rem",

    [ScreenSizes.lgAndAbove]: {
      padding: 0,
      fontSize: "1rem",
      lineHeight: "28px",
      color: "#000",
      textAlign: "left",
    },
  },
  toggleContainer: {
    marginTop: "1.5rem",
    justifyContent: "center",

    [ScreenSizes.lgAndAbove]: {
      marginTop: "0.6rem",
      justifyContent: "flex-start",
    },
  },
  toggle: {
    ...gStyles.fontSemiBold,
    fontSize: "0.7rem",
    color: colours.bodyText,
  },
};

const mobileShowMoreStyles = {
  ...showMoreStyles,
  children: {
    ...showMoreStyles.children,
    ...gStyles.fontRegular,
    fontSize: ".813rem",
    lineHeight: "18px",
    justifyContent: "center",
    color: "#414141",

    [ScreenSizes.smAndAbove]: {
      lineHeight: "25px",
      fontSize: "1rem",
    },
  },
  toggle: {
    ...gStyles.fontSemiBold,
    color: colours.primary,
    fontSize: ".813rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: ".938rem",
    },
  },
};

const claimStyles = {
  description: {
    marginBottom: 0,
  },
};

const EpisodeViewAbout = ({ onTabChange, mobile, episode }) => {
  const { isWindowSizeOrLess } = useWindowSize();
  const podcast_id = episode.get("podcast_id");

  const podcast = useReduxState(
    (state) => selectSpecificPodcast(state, podcast_id),
    [podcast_id]
  );

  const requestContext = useContext(RequestContext);

  const isLoggedIn = useLoggedIn();
  const provider = episode && episode.get("hosting_provider");
  const hasHostingProvider =
    provider && affiliates.find((aff) => aff.id === provider);

  let toggleHeight = 188;

  if (isWindowSizeOrLess("tiny")) {
    toggleHeight = 72;
  } else if (mobile) {
    toggleHeight = 95;
  }

  const episodeDescription = useTransformRawDescription(
    episode.get("description_sanitized")
  );

  const showMoreAnalytics = useMemo(
    () => ({
      action: "descriptionLinkOnClick",
      page: "EPISODE_PAGE",
      entity: "episode",
      entity_id: episode.get("id"),
      entity_name: episode.get("name"),
    }),
    [episode]
  );

  const sections = useMemo(
    () => [
      episode.get("disclaimers") && (
        <AboutHate key="about-hate" episode={episode} />
      ),
      episodeDescription && episode.get("description") && (
        <ShowMoreContainer
          checkForContentChanges
          disableToggleOnContentClick
          hideOverlay
          shrink
          styles={mobile ? mobileShowMoreStyles : showMoreStyles}
          toggleHeight={toggleHeight}
        >
          <TransformRawDescriptionAnalytics analytics={showMoreAnalytics}>
            {episodeDescription}
          </TransformRawDescriptionAnalytics>
        </ShowMoreContainer>
      ),

      <AboutCreatorsContainer
        key="about-creators"
        onTabChange={onTabChange}
        episode={episode}
      />,
      mobile && (
        <LazyLoadComponent
          key="PodcastInfoLazy"
          height={"17.1rem"}
          renderOnMount={requestContext.isBotCrawler}
        >
          <PodcastInfo
            key="about-podcastinfo"
            podcast={podcast}
            episode={episode}
            noPanel
          />
        </LazyLoadComponent>
      ),
      mobile && (
        <LazyLoadComponent
          key="DirectEpisodeRecommendations"
          height={"9.3rem"}
          renderOnMount={requestContext.isBotCrawler}
        >
          <DirectEpisodeRecommendations
            key="about-direct-recommendations"
            episode={episode}
            mobile
            noPanel
          />
        </LazyLoadComponent>
      ),
      mobile && hasHostingProvider && (
        <LazyLoadComponent
          key="EpisodeAffiliates"
          renderOnMount={requestContext.isBotCrawler}
        >
          <EpisodeAffiliates key="about-affiliates" episode={episode} mobile />
        </LazyLoadComponent>
      ),
      mobile && <Stats key="about-stats" noPanel />,
      mobile && (
        <LazyLoadComponent
          key="Tags"
          height={"14.3rem"}
          renderOnMount={requestContext.isBotCrawler}
        >
          <Tags key="about-tags" noPanel />
        </LazyLoadComponent>
      ),
      mobile && (
        <LazyLoadComponent
          key="SocialLinks"
          height={"15.6rem"}
          renderOnMount={requestContext.isBotCrawler}
        >
          <SocialLinks
            key="about-social-links"
            noPanel
            episodeId={episode.get("id")}
          />
        </LazyLoadComponent>
      ),
      <AboutReviewsContainer
        key="about-reviews"
        onTabChange={onTabChange}
        episode={episode}
      />,
      mobile && {
        panel: true,
        component: (
          <AboutListsContainer
            key="about-lists"
            onTabChange={onTabChange}
            episode={episode}
          />
        ),
      },
      mobile && (
        <div key="about-claim">
          <Title
            title="Host or manage this podcast?"
            TitleComponent="h3"
            inSidebar
          />
          <ClaimPodcast styles={claimStyles} podcast={podcast} />
        </div>
      ),
      mobile && {
        panel: false,
        component: <ApiCTA />,
      },
      <AboutDisclaimer key="about-disclaimer" />,
      !isLoggedIn &&
        mobile && {
          panel: false,
          component: (
            <LazyLoadComponent
              key="PageCTABox"
              renderOnMount={requestContext.isBotCrawler}
            >
              <PageCTABox
                key="about-cta"
                sidebarPanelProps={{ renderOnMount: true }}
              />
            </LazyLoadComponent>
          ),
        },
    ],
    [
      episode,
      episodeDescription,
      hasHostingProvider,
      isLoggedIn,
      mobile,
      onTabChange,
      podcast,
      requestContext.isBotCrawler,
      showMoreAnalytics,
      toggleHeight,
    ]
  );

  return (
    <About
      mobile={mobile}
      sections={sections}
      key={`episode_about_${episode.get("id")}`}
    />
  );
};

EpisodeViewAbout.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  episode: PropTypes.instanceOf(Map).isRequired,
  mobile: PropTypes.bool,
};

EpisodeViewAbout.defaultProps = {
  mobile: false,
};

export default EpisodeViewAbout;
