import Loadable from "@loadable/component";
import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "23.6rem",
    marginBottom: "3rem",

    [ScreenSizes.mdAndAbove]: {
      height: "24.8rem",
    },
  },
};

const InsightsRateCardAsync = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <>
      <div className={css(styles.container)} />
    </>
  );
};

const InsightsRateCardAsyncMemo = memo(InsightsRateCardAsync);

const InsightsRateCard = Loadable(() => import("./InsightsRateCard.js"));

function InsightsRateCardAsyncLoading(props) {
  return <InsightsRateCardAsyncMemo />;
}

const InsightsRateCardContainer = (props) => {
  return (
    <InsightsRateCard {...props} fallback={<InsightsRateCardAsyncLoading />} />
  );
};

export default InsightsRateCardContainer;
