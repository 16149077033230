import Loadable from "@loadable/component";
const RatingText = Loadable(() => import("./RatingText"));

function RatingTextAsyncLoading(props) {
  return null;
}

const RatingTextContainer = (props) => {
  return <RatingText {...props} fallback={<RatingTextAsyncLoading />} />;
};

export default RatingTextContainer;
