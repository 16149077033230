import { faApple } from "@fortawesome/free-brands-svg-icons/faApple";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { faPatreon } from "@fortawesome/free-brands-svg-icons/faPatreon";
import { faTiktok } from "@fortawesome/free-brands-svg-icons/faTiktok";
import { faTwitch } from "@fortawesome/free-brands-svg-icons/faTwitch";
import { faWikipediaW } from "@fortawesome/free-brands-svg-icons/faWikipediaW";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faGlobeAmericas } from "@fortawesome/free-solid-svg-icons/faGlobeAmericas";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faRss } from "@fortawesome/free-solid-svg-icons/faRss";
import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";

import PageSidebarPanel from "components/Entities/Page/PageSidebarPanel";
import Title from "components/Entities/Page/Title";
import LinkList from "components/Lists/LinkList";

import generateItunesUrl from "utils/entity/generateItunesUrl";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import faXTwitterSquare from "styles/icons/faXTwitterSquare";

const panelStyles = {
  pageSidebarPanel: {
    color: colours.bodyText,
    overflow: "hidden",
    padding: 0,
  },
};

const linkListStyles = {
  list: {
    borderRadius: 6,
    overflow: "hidden",
  },
  listItem: {
    padding: "0.5rem",
    borderBottom: "1px #edf0f0 solid",
    ":last-child": {
      borderBottom: "none",
    },
    ...getHoverQuery({
      boxShadow: "none",
    }),
  },
  thumbnail: {
    width: "2.625rem",
    height: "2.625rem",
    flex: "0 0 2.625rem",
    position: "relative",
    display: "flex",
  },
  title: {
    ...gStyles.avalonBold,
    fontSize: ".81rem",
    marginBottom: ".1rem",
    color: colours.bodyText,
  },
  subtitle: {
    ...gStyles.fontMediumItalic,
    fontSize: ".688rem",
    color: "#444",
  },
  icon: {
    fontSize: "1.1rem",
  },
  arrow: {
    display: "none",
  },
};

const noPanelLinkListStyles = {
  ...linkListStyles,
  listItem: {
    ...linkListStyles.listItem,
    borderBottom: "none",
    padding: ".5rem 0",
  },
};

const SocialLinksPanel = (props) => {
  const { noPanel, title, links: passedLinks, index } = props;

  const links = useMemo(
    () =>
      [
        passedLinks.web && {
          title: passedLinks.web.title || "Official Website",
          url: passedLinks.web.url,
          icon: faGlobeAmericas,
          trusted: true,
          ugc: true,
        },
        passedLinks.feed && {
          title: "RSS Feed",
          url: passedLinks.feed.url,
          icon: faRss,
          color: "#ed8219",
        },
        passedLinks.itunes && {
          title: "Apple Podcasts",
          url:
            passedLinks.itunes.url || generateItunesUrl(passedLinks.itunes.id),
          color:
            "linear-gradient(135deg, #09ddf0 1%,#1b82f9 43%,#e64375 84%,#f97169 100%)",
          filter:
            "progid:DXImageTransform.Microsoft.gradient( startColorstr='#09ddf0', endColorstr='#f97169',GradientType=1 )",
          icon: faApple,
          trusted: true,
        },
        passedLinks.facebook && {
          title: "Facebook",
          subtitle:
            passedLinks.facebook.subtitle ||
            `https://www.facebook.com/${passedLinks.facebook.handle}`,
          url:
            passedLinks.facebook.url ||
            `https://www.facebook.com/${passedLinks.facebook.handle}`,
          icon: faFacebookF,
          color: colours.facebook,
          trusted: true,
        },
        passedLinks.twitter && {
          title: "X/Twitter",
          subtitle:
            passedLinks.twitter.subtitle ||
            `twitter.com/${passedLinks.twitter.handle}`,
          url:
            passedLinks.twitter.url ||
            `https://www.twitter.com/${passedLinks.twitter.handle}`,
          icon: faXTwitterSquare,
          color: colours.twitter,
          trusted: true,
        },
        passedLinks.tiktok && {
          title: "TikTok",
          subtitle:
            passedLinks.tiktok.subtitle ||
            `tiktok.com/@${passedLinks.tiktok.handle}`,
          url:
            passedLinks.tiktok.url ||
            `https://www.tiktok.com/@${passedLinks.tiktok.handle}`,
          icon: faTiktok,
          color: "#010101",
          trusted: true,
        },
        passedLinks.instagram && {
          title: "Instagram",
          subtitle:
            passedLinks.instagram.subtitle ||
            `instagram.com/${passedLinks.instagram.handle}`,
          url:
            passedLinks.instagram.url ||
            `https://www.instagram.com/${passedLinks.instagram.handle}`,
          icon: faInstagram,
          color:
            "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
          trusted: true,
        },
        passedLinks.youtube && {
          title: "YouTube",
          subtitle:
            passedLinks.youtube.url ||
            `https://www.youtube.com/${passedLinks.youtube.handle}`,
          url:
            passedLinks.youtube.url ||
            `https://www.youtube.com/${passedLinks.youtube.handle}`,
          icon: faYoutube,
          color: "#F00",
        },
        passedLinks.wiki && {
          title: "Wikipedia",
          subtitle:
            passedLinks.wiki.subtitle ||
            `wikipedia.org/wiki/${passedLinks.wiki.handle}`,
          url:
            passedLinks.wiki.url ||
            `https://www.wikipedia.org/wiki/${passedLinks.wiki.handle}`,
          icon: faWikipediaW,
          color: "#000",
          trusted: true,
        },
        passedLinks.patreon && {
          title: "Patreon",
          subtitle:
            passedLinks.patreon.url ||
            `https://www.patreon.com/${passedLinks.patreon.handle}`,
          url:
            passedLinks.patreon.url ||
            `https://www.patreon.com/${passedLinks.patreon.handle}`,
          icon: faPatreon,
          color: "#f96854",
        },
        passedLinks.linkedin && {
          title: "Linkedin",
          subtitle:
            passedLinks.linkedin.url ||
            `https://www.linkedin.com/${passedLinks.linkedin.handle}`,
          url:
            passedLinks.linkedin.url ||
            `https://www.linkedin.com/${passedLinks.linkedin.handle}`,
          icon: faLinkedin,
          color: "#0a66c2",
        },
        passedLinks.twitch && {
          title: "Twitch",
          subtitle:
            passedLinks.twitch.url ||
            `https://www.twitch.com/${passedLinks.twitch.handle}`,
          url:
            passedLinks.twitch.url ||
            `https://www.twitch.com/${passedLinks.twitch.handle}`,
          icon: faTwitch,
          color: "#6441a5",
        },
        passedLinks.download && {
          title: passedLinks.download.title || "Download",
          subtitle: passedLinks.download.url,
          url: passedLinks.download.url,
          icon: faDownload,
        },
        ...(passedLinks.otherLinks || []).map((link) => {
          return {
            title: link.title,
            url: link.url,
            icon: faLink,
            color: "#999",
            ugc: true,
          };
        }),
      ].filter((v) => !!v),
    [passedLinks]
  );

  if (links.length === 0) {
    return null;
  }

  const socialLinks = (
    <LinkList
      links={links}
      styles={noPanel ? noPanelLinkListStyles : linkListStyles}
    />
  );

  if (noPanel) {
    return (
      <Fragment>
        <Title title={title} TitleComponent="h3" inSidebar />
        {socialLinks}
      </Fragment>
    );
  }

  return (
    <PageSidebarPanel styles={panelStyles} index={index} renderOnMount>
      {socialLinks}
    </PageSidebarPanel>
  );
};

SocialLinksPanel.propTypes = {
  links: PropTypes.object,
  noPanel: PropTypes.bool,
  title: PropTypes.string,
  index: PropTypes.number,
};

SocialLinksPanel.defaultProps = {
  links: {},
  noPanel: false,
  title: null,
  index: 0,
};

export default SocialLinksPanel;
