import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";

import { TabsContext } from "components/Common/Tabs";
import UserlistsStub from "components/Entities/Stubs/UserlistsStubAsync";

import getPodcastUrl from "utils/entity/getPodcastUrl";

import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const AboutLists = ({ podcast, onTabChange }) => {
  const listsOnTabChange = useCallback(
    () => onTabChange("lists"),
    [onTabChange]
  );
  const staticFilters = useMemo(
    () => ({
      podcast_id: {
        value: podcast && podcast.get("id"),
      },
    }),
    [podcast]
  );

  const listConfig = useMemo(
    () => ({
      loadingStyles: {
        noOverlay: {
          padding: "4rem 0",
          fontSize: "2rem",
          height: "16rem",

          [ScreenSizes.xlAndAbove]: {
            height: "18rem",
          },
        },
      },
    }),
    []
  );

  const { contentRef } = useContext(TabsContext);
  const { isWindowSizeOrLess } = useWindowSize();

  const mobile = isWindowSizeOrLess("medium");

  return (
    <UserlistsStub
      listKey={`podcastView/${podcast.get("id")}/aboutUserlists`}
      listConfig={listConfig}
      title="Mentioned In These Lists"
      onTabChange={listsOnTabChange}
      staticFilters={staticFilters}
      name={podcast.get("title")}
      entity_type="podcast"
      entity={podcast}
      scrollRef={contentRef}
      viewAllLink={getPodcastUrl(podcast, "lists")}
      viewAllTitle={`Episode lists featuring ${podcast.get("title")}`}
      inSidebar={mobile}
      key="AboutPodcastList"
    />
  );
};

AboutLists.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  podcast: PropTypes.instanceOf(Map),
};
AboutLists.defaultProps = {
  podcast: null,
};

export default AboutLists;
