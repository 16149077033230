import { useCallback } from "react";

import { useSmoothScroll } from "hooks/useSmoothScroll";

export function useStubLinkClick(onTabChange, scrollRef) {
  const { scrollTo } = useSmoothScroll({ allowedDirections: ["up"] });

  return useCallback(
    (e) => {
      if (onTabChange) {
        e.stopPropagation();
        onTabChange(e);
      }
      if (scrollRef) {
        setTimeout(() => scrollTo(scrollRef.current), 0);
      }
      if (onTabChange) {
        return false;
      }
    },
    [onTabChange, scrollRef, scrollTo]
  );
}
