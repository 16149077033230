import { formatDistance } from "date-fns/formatDistance";
import PropTypes from "prop-types";

import StatsPanel from "components/Entities/Page/StatsPanel";

import { episodeExclusiveTo } from "utils/entity/exclusiveTo";
import { secondsToDaysHoursMinSecsChars } from "utils/formatTime";
import { capitalize } from "utils/misc";

import useEpisodeData from "hooks/useEpisodeData";

const CONFIG = [
  {
    title: "Length",
    format: (episode) =>
      episode.get("length") &&
      secondsToDaysHoursMinSecsChars(episode.get("length")),
  },
  {
    title: "Episode Length",
    format: (episode) => {
      if (episode.get("average_episode_length_seconds")) {
        return formatDistance(
          episode.get("average_episode_length_seconds") * 1000,
          0
        );
      }

      return null;
    },
  },
  {
    title: "Explicit",
    format: (episode) => {
      if (!episode.has("is_explicit")) {
        return null;
      }
      return episode.get("is_explicit") ? "Yes" : "No";
    },
  },
  {
    title: "Exclusive To",
    format: () => "Spotify",
    condition: (episode) => episodeExclusiveTo(episode, "spotify"),
  },
  {
    title: "Season",
    format: (episode) => {
      const season = episode.getIn(["meta_data", "season"]);

      if (season > 0) {
        return season;
      }

      return null;
    },
  },
  {
    title: "Episode",
    format: (episode) => {
      const episodeNum = episode.get("episode_num");

      if (episodeNum > 0) {
        return episodeNum;
      }

      return null;
    },
  },
  {
    title: "Episode Type",
    format: (episode) => {
      const episodeType = episode.getIn(["meta_data", "episode_type"]);

      if (episodeType) {
        return capitalize(episodeType);
      }

      return null;
    },
  },
];

const Stats = ({ episodeId, noPanel }) => {
  const episode = useEpisodeData(episodeId);

  return (
    <StatsPanel
      title="Episode Details"
      entity={episode}
      statsConfig={CONFIG}
      noPanel={noPanel}
    />
  );
};

Stats.propTypes = {
  episodeId: PropTypes.number.isRequired,
  noPanel: PropTypes.bool,
};

Stats.defaultProps = {
  noPanel: false,
};

export default Stats;
