import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";

import { ensureScheme, extractSimpleDomain } from "utils/url";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";

const BORDER_WIDTH = 1;
const THUMB_SIZE = 40;

const baseStyles = {
  listItem: {
    background: "#fff",
    display: "block",
    padding: `${BORDER_WIDTH}px ${BORDER_WIDTH * 2}px`,
    fontSize: "13px",
    zIndex: 1,
    ...getHoverQuery({
      boxShadow: "0 0 0.5em rgba(0,0,0,0.1)",
      position: "relative",
      zIndex: 2,
    }),
  },
  link: {
    display: "flex",
  },
  thumbnail: {
    background: colours.oldSecondary,
    color: "#fff",
    width: THUMB_SIZE,
    height: THUMB_SIZE,
    flex: `0 0 ${THUMB_SIZE}px`,
    position: "relative",
    display: "flex",
    borderRadius: 10,
    overflow: "hidden",
  },
  image: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    borderStyle: "none",
  },
  icon: {
    flex: "1 1 100%",
    alignSelf: "center",
    textAlign: "center",
    fontSize: 20,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    height: THUMB_SIZE,
    borderLeft: "8px solid transparent",
    flex: "1 1 auto",
    justifyContent: "center",
    textAlign: "left",
    lineHeight: 1.4,
    overflow: "hidden",
  },
  title: {
    ...gStyles.fontSemiBold,
    color: colours.bodyText,
    display: "block",
    width: "100%",
  },
  subtitle: {
    ...gStyles.fontLight,
    fontStyle: "italic",
    display: "block",
    color: "#b5b9c2",
    fontSize: "12px",
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  arrow: {
    padding: 7,
    color: "#ddd",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
};

const LinkListItem = (props) => {
  const {
    url,
    image_url,
    title,
    subtitle,
    icon,
    trusted,
    ugc,
    sponsored,
    metaTitle,
    filter,
    color,
  } = props;
  const { styles } = useStyles(
    [
      baseStyles,
      color && {
        thumbnail: {
          background: color,
          filter,
        },
      },
    ],
    props
  );

  const simpleDomain = extractSimpleDomain(url);
  const finalSubtitle = subtitle || simpleDomain;

  return (
    <li className={css(styles.listItem)}>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a
        href={ensureScheme(url)}
        className={css(styles.link)}
        target="_blank"
        rel={`${trusted ? "follow" : "nofollow noopener"} ${
          ugc ? " ugc" : ""
        } ${sponsored ? " sponsored" : ""}`}
        title={metaTitle}
      >
        <span className={css(styles.thumbnail)}>
          {image_url && (
            <img
              src={image_url}
              alt={title}
              className={css(styles.image)}
              width={2.625 * 16}
              height={2.625 * 16}
            />
          )}
          {icon && (
            <span className={css(styles.icon)}>
              <FontAwesomeIcon icon={icon} fixedWidth />
            </span>
          )}
        </span>
        <span className={css(styles.content)}>
          <span className={css(styles.title)}>{title}</span>
          <span className={css(styles.subtitle)} title={finalSubtitle}>
            {finalSubtitle}
          </span>
        </span>
        <span className={css(styles.arrow)}>
          <FontAwesomeIcon icon={faChevronRight} />
        </span>
      </a>
    </li>
  );
};

LinkListItem.propTypes = {
  metaTitle: PropTypes.string,
  url: PropTypes.string,
  image_url: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.object,
  color: PropTypes.string,
  trusted: PropTypes.bool,
  sponsored: PropTypes.bool,
  ugc: PropTypes.bool,
  filter: PropTypes.string,
};
LinkListItem.defaultProps = {
  metaTitle: null,
  url: null,
  image_url: null,
  title: null,
  subtitle: null,
  icon: null,
  color: null,
  trusted: false,
  sponsored: false,
  ugc: false,
  filter: null,
};

export default memo(LinkListItem);
