import Loadable from "@loadable/component";
const AboutHate = Loadable(() =>
  import(/* webpackChunkName: "AboutHate" */ "./AboutHate")
);

function AboutHateLoading() {
  return null;
}

const AboutHateContainer = (props) => {
  return <AboutHate {...props} fallback={<AboutHateLoading />} />;
};

export default AboutHateContainer;
