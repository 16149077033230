import { Map } from "immutable";
import PropTypes from "prop-types";
import { useContext } from "react";

import RequestContext from "pages/RequestContext";

import Insights from "./Insights";
import InsightsLoading from "./InsightsLoading";
import PublicInsights from "./PublicInsights/async";

import useHasProForPodcast from "hooks/useHasProForPodcast";
import { useLoggedIn } from "hooks/useLoggedInUser";

function InsightsContainer(props) {
  const { podcast } = props;

  const podcastId = podcast?.get("id");

  const hasPro = useHasProForPodcast(podcastId);

  const { server, hasAuthCookieDefined } = useContext(RequestContext);
  const isLoggedIn = useLoggedIn();

  if (!isLoggedIn && hasAuthCookieDefined && server) {
    return <InsightsLoading />;
  }

  if (!podcast?.get("id")) {
    return <InsightsLoading />;
  }

  if (hasPro) {
    return <Insights {...props} />;
  }

  return <PublicInsights {...props} />;
}

InsightsContainer.propTypes = {
  podcast: PropTypes.instanceOf(Map),
};

InsightsContainer.defaultProps = {
  podcast: null,
};
export default InsightsContainer;
