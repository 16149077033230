import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import AffiliateBadgesSidebarPanel from "components/Entities/Page/AffiliateBadgesSidebarPanelAsync";

const EpisodeAffiliates = ({ episode, mobile }) => {
  const affiliates = useMemo(() => {
    if (episode && episode.get("hosting_provider")) {
      return [
        {
          title: "Hosted",
          affiliates: [episode.get("hosting_provider")],
        },
      ];
    }

    return null;
  }, [episode]);

  return <AffiliateBadgesSidebarPanel panels={affiliates} mobile={mobile} />;
};

EpisodeAffiliates.propTypes = {
  episode: PropTypes.instanceOf(Map).isRequired,
  mobile: PropTypes.bool,
};

EpisodeAffiliates.defaultProps = {
  mobile: false,
};

export default EpisodeAffiliates;
