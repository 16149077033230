import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";

import ContentContainer from "components/Common/Layout/ContentContainer";
import EntityImage from "components/Entities/EntityImage";

import mergeDeep from "utils/objects/mergeDeep";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const MAIN_IMAGE_MOBILE_SIZE = 8.5;
const FLEX_IMAGE_MOBILE_SIZE = Math.round(MAIN_IMAGE_MOBILE_SIZE * 0.8);

const baseStyles = {
  largeContainer: {
    // ...gStyles.textEllipsis,
    display: "flex",
    position: "relative",
    top: "1.5rem",
    flexDirection: "row",
    alignItems: "center",
  },
  mediumContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  smallContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "1rem 0",
    [ScreenSizes.mdAndAbove]: {
      padding: "1rem",
    },
  },
  smallHeader: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    justifyContent: "space-between",
    padding: "0.4rem 0 0 1rem",
    color: colours.bodyText,
    overflow: "hidden",
  },
  smallHeaderWithSmallContent: {
    paddingRight: "1rem",
  },
  image: {
    height: "100%",
  },
  backgroundImageOverlay: {
    ...gStyles.gradientBackground,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    pointerEvents: "none",
    overflow: "hidden",
  },
  backgroundImage: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundPosition: "center",
    opacity: ".07",
    filter: "blur(6px) saturate(0.3)",
    top: "-200px",
    bottom: "-200px",
    backgroundSize: "cover",
    transform: "rotate(-10deg)",
  },
  mainHeaderImage: {
    position: "relative",
    top: `${MAIN_IMAGE_MOBILE_SIZE / 2}rem`,
    height: `${MAIN_IMAGE_MOBILE_SIZE}rem`,
    width: `${MAIN_IMAGE_MOBILE_SIZE}rem`,
    borderRadius: "50%",
    overflow: "hidden",
    border: "5px solid #FFF",
    backgroundColor: "#F3F3F3",
    backgroundSize: "cover",
    backgroundPosition: "center",
    zIndex: 10,
    marginTop: "-1rem",

    [ScreenSizes.lgAndAbove]: {
      height: "11.875rem",
      width: "11.875rem",
      position: "relative", // 'initial',
      marginTop: 0,
    },
  },
  smallMainHeaderImage: {
    position: "relative",
    height: "3.5rem",
    width: "3.5rem",
    borderRadius: "50%",
    overflow: "hidden",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  thumbnailImage: {
    fontSize: "4.75rem",
    color: "#fff",
    marginBottom: "1.5rem",
    position: "relative",
    zIndex: 10,
    alignSelf: "flex-start",
    ":last-child": {
      marginBottom: 0,
    },
  },
  flexibleMainHeaderImage: {
    background: "#f9f9f9",
    position: "relative",
    height: `${FLEX_IMAGE_MOBILE_SIZE}rem`,
    minWidth: `${FLEX_IMAGE_MOBILE_SIZE}rem`,
    marginLeft: "auto",
    marginRight: "auto",
    zIndex: 10,
    overflow: "hidden",
    display: "inline",

    [ScreenSizes.lgAndAbove]: {
      marginLeft: 0,
    },
  },
  smallBackArrow: {
    paddingRight: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    cursor: "pointer",
    fontSize: "1.7rem",
    color: colours.bodyText,
  },
  infoContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "2.5rem",
    color: "#FFF",
  },
  desktopContainer: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.smAndAbove]: {
      display: "none",
    },
  },
  tabletContainer: {
    display: "none",

    [ScreenSizes.smAndAbove]: {
      display: "block",
    },
    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
};

const largeContainerStyles = {
  container: {
    paddingBottom: 0,
    position: "relative",
    backgroundColor: "var(--color-secondary-d3)",
  },
  contentContainer: {
    paddingTop: ".5rem",
    paddingBottom: "1.2rem",
    position: "initial",
  },
};

const mediumHeaderContainerStyles = {
  container: {
    paddingBottom: 0,
    position: "relative",
    backgroundColor: "var(--color-secondary-d3)",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 0,
    paddingBottom: 0,
    position: "initial",
  },
  row: {
    width: "100%",
  },
};

const mediumContentContainerStyles = {
  container: {
    paddingBottom: 0,
    position: "relative",
    backgroundColor: "#FFF",
    color: "#000",
  },
  row: {
    maxWidth: "100%",

    [ScreenSizes.mdAndAbove]: {
      maxWidth: "none",
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: `${MAIN_IMAGE_MOBILE_SIZE / 2 + 1.5}rem`,
    paddingBottom: "1.5rem",

    [ScreenSizes.smAndAbove]: {
      paddingTop: `${MAIN_IMAGE_MOBILE_SIZE / 2 + 1}rem`,
    },
    [ScreenSizes.mdAndAbove]: {
      paddingTop: "3rem",
    },
  },
};

const smallContainerStyles = {
  container: {
    paddingTop: 0,
    paddingBottom: 0,
    position: "relative",
    backgroundColor: "#FFF",
    boxShadow: "0 5px 16px 0 rgba(0,0,0,0.07)",
    marginBottom: "1rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: 0,
    },
  },
  row: {
    maxWidth: "100%",

    [ScreenSizes.mdAndAbove]: {
      maxWidth: "none",
    },
  },
  contentContainer: {
    position: "initial",
  },
};

const PageHeader = (props) => {
  const {
    size,
    renderAboveSmallHeader,
    renderSmallHeaderContent,
    renderSmallContent,
    containerStyles,
    onSmallLayoutBackClick,
    renderMediumHeaderContent,
    renderMediumContent,
    renderUnderMediumContent,
    renderBackgroundImage: passedRenderBackgroundImage,
    mediumContentStyles,
    renderContent,
    siteHeaderPadding,
    overrideStyles,
    entity,
    entity_type,
    renderProfileImage: passedRenderProfileImage,
    thumbnailImage,
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const imageSize = useMemo(() => {
    return mobile ? 160 : 200;
  }, [mobile]);

  const renderProfileImage = () =>
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    passedRenderProfileImage ? (
      passedRenderProfileImage()
    ) : (
      <div
        className={css(
          styles[size === "small" ? "smallMainHeaderImage" : "mainHeaderImage"]
        )}
      >
        <EntityImage
          entity={entity}
          entity_type={entity_type}
          size={imageSize}
          showTitle
          fullWidth
          disablePopup
        />
      </div>
    );

  const renderBackgroundImage = () => {
    if (passedRenderBackgroundImage) {
      return (
        <div className={css(styles.backgroundImageOverlay)}>
          {passedRenderBackgroundImage()}
        </div>
      );
    }

    return null;
  };

  const contentContainerStyles = useMemo(() => {
    if (size === "small") {
      return containerStyles
        ? mergeDeep(smallContainerStyles, containerStyles)
        : smallContainerStyles;
    }
    if (size === "medium") {
      return mediumContentStyles
        ? mergeDeep(mediumContentContainerStyles, mediumContentStyles)
        : mediumContentContainerStyles;
    }

    return containerStyles
      ? mergeDeep(largeContainerStyles, containerStyles)
      : largeContainerStyles;
  }, [size, containerStyles, mediumContentStyles]);

  const mediumHeaderStyles = useMemo(
    () => mergeDeep(mediumHeaderContainerStyles, containerStyles),
    [containerStyles]
  );

  const renderSmall = () => (
    <ContentContainer
      styles={contentContainerStyles}
      overrideStyles={overrideStyles}
      dataId="page-header"
    >
      {renderAboveSmallHeader && renderAboveSmallHeader()}
      <div className={css(styles.smallContainer)}>
        {onSmallLayoutBackClick && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
          <div
            onClick={onSmallLayoutBackClick}
            className={css(styles.smallBackArrow)}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
        )}
        {thumbnailImage ? thumbnailImage : renderProfileImage()}
        <div
          className={css(
            styles.smallHeader,
            renderSmallContent && styles.smallHeaderWithSmallContent
          )}
        >
          {renderSmallHeaderContent && renderSmallHeaderContent()}
        </div>
        {renderSmallContent && renderSmallContent()}
      </div>
    </ContentContainer>
  );

  const renderMedium = () => (
    <div className={css(styles.mediumContainer)} data-testid="page-header">
      <ContentContainer
        styles={mediumHeaderStyles}
        overrideStyles={overrideStyles}
      >
        {thumbnailImage ? thumbnailImage : renderProfileImage()}
        {renderBackgroundImage()}
        {renderMediumHeaderContent && renderMediumHeaderContent()}
      </ContentContainer>
      <ContentContainer styles={contentContainerStyles}>
        {renderMediumContent && renderMediumContent()}
      </ContentContainer>
      {renderUnderMediumContent && renderUnderMediumContent()}
    </div>
  );

  // TODO cache the image_url
  const renderLarge = () => (
    <ContentContainer
      styles={contentContainerStyles}
      noVerticalPadding
      overrideStyles={overrideStyles}
      siteHeaderPadding={siteHeaderPadding}
      fullWidth
      dataId="page-header"
    >
      {renderBackgroundImage()}
      <div className={css(styles.largeContainer)}>
        {thumbnailImage ? thumbnailImage : renderProfileImage()}
        <div className={css(styles.infoContainer)}>
          {renderContent && renderContent()}
        </div>
      </div>
    </ContentContainer>
  );

  return (
    <>
      <div className={css(styles.desktopContainer)}>{renderLarge()}</div>
      <div className={css(styles.mobileContainer)}>{renderSmall()}</div>
      <div className={css(styles.tabletContainer)}>{renderMedium()}</div>
    </>
  );
};

PageHeader.propTypes = {
  size: PropTypes.string,
  renderContent: PropTypes.func,
  renderMediumContent: PropTypes.func,
  renderUnderMediumContent: PropTypes.func,
  renderMediumHeaderContent: PropTypes.func,
  renderSmallContent: PropTypes.func,
  renderSmallHeaderContent: PropTypes.func,
  renderBackgroundImage: PropTypes.func,
  mainImageUrl: PropTypes.string,
  mainImageUrlFallback: PropTypes.string,
  mainImageTitle: PropTypes.string,
  updateProps: PropTypes.object,
  containerStyles: PropTypes.object,
  mediumContentStyles: PropTypes.object,
  onSmallLayoutBackClick: PropTypes.func,
  imageInline: PropTypes.bool,
  mainImageSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  entity: PropTypes.instanceOf(Map).isRequired,
  entity_type: PropTypes.string.isRequired,
  thumbnailImage: PropTypes.node,
};

PageHeader.defaultProps = {
  size: "large",
  renderContent: null,
  renderMediumContent: null,
  renderUnderMediumContent: null,
  renderMediumHeaderContent: null,
  renderSmallContent: null,
  renderSmallHeaderContent: null,
  renderBackgroundImage: null,
  updateProps: null,
  containerStyles: null,
  mediumContentStyles: null,
  onSmallLayoutBackClick: null,
  imageInline: false,
  siteHeaderPadding: true,
  overrideStyles: false,
  mainImageSize: null,
  thumbnailImage: null,
};

export default PageHeader;
