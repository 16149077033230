import { addMonths } from "date-fns/addMonths";
import { format } from "date-fns/format";

const affiliates = [
  {
    id: "Buzzsprout",
    title: "Buzzsprout",
    url: "https://www.buzzsprout.com/?referrer_id=225122",
    image_url: import("./affiliatesImages/buzzsprout.png").then(
      (src) => src.default
    ),
    description: "Get 90 days FREE & a $20 Amazon Gift Card!",
  },
  {
    id: "Captivate",
    title: "Captivate",
    url: "https://www.captivate.fm/signup?ref=podchaser",
    image_url: import("./affiliatesImages/captivate.png").then(
      (src) => src.default
    ),
    description: "Redeem your 7 day free trial!",
  },
  {
    id: "Libsyn",
    title: "Libsyn",
    url: "https://signup.libsyn.com/?promo_code=podchaser",
    image_url: import("./affiliatesImages/libsyn.png").then(
      (src) => src.default
    ),
    description: `Use code: 'podchaser' for rest of ${format(
      new Date(),
      "MMM"
    )} + ${format(addMonths(new Date(), 1), "MMM")} FREE!`,
    additional_matches: ["Libsyn Pro"],
  },
  {
    id: "Podbean",
    title: "Podbean",
    url: "https://www.podbean.com/podchaser ",
    image_url: import("./affiliatesImages/podbean.png").then(
      (src) => src.default
    ),
    description: "Get 1 FREE month of hosting!",
  },
  {
    id: "Podiant",
    title: "Podiant",
    url: "https://podiant.co/podchaser",
    image_url: import("./affiliatesImages/podiant.png").then(
      (src) => src.default
    ),
    description: "Get 50% OFF a year of hosting!",
  },
  {
    id: "Spreaker",
    title: "Spreaker",
    url: "https://www.spreaker.com/create?coupon_code=podchaser",
    image_url: import("./affiliatesImages/spreaker.png").then(
      (src) => src.default
    ),
    description: "Get 1 FREE month of hosting!",
  },
  {
    id: "Simplecast",
    title: "SimpleCast",
    url: "https://simplecast.com/#_r_podchaser",
    image_url: import("./affiliatesImages/simplecast.png").then(
      (src) => src.default
    ),
    description: "Use Code: PODCHASER for 50% OFF your first 2 months!",
  },
  {
    id: "Transistor",
    title: "Transistor",
    url: "https://transistor.fm/?via=podchaser",
    image_url: import("./affiliatesImages/transistor.png").then(
      (src) => src.default
    ),
  },
  {
    id: "bCast",
    title: "bCast",
    url: "https://bcast.fm/?via=podchaser",
    image_url: import("./affiliatesImages/bcast.png").then(
      (src) => src.default
    ),
  },
  {
    id: "Acast",
    title: "Acast",
    url: "https://acast.com/?utm_source=podchaser&utm_medium=web&utm_campaign=podchaser-podcast-page",
    description: "Get 3 months free with code PODCHASER",
    image_url: import("./affiliatesImages/acast.svg").then(
      (src) => src.default
    ),
    styles: {
      affiliateImage: {
        height: "60px",
        backgroundColor: "#ffd140",
      },
    },
  },
];

export default affiliates;
