import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Lists = Loadable(() => import("./Lists"));

function AboutAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const ListsContainer = (props) => {
  return <Lists {...props} fallback={<AboutAsyncLoading />} />;
};

export default ListsContainer;
