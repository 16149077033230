import useEntityCard from "../useEntityCard";
import ReviewCompactCard from "./ReviewCompactCard";
import ReviewDesktopCard from "./ReviewDesktopCard";
import ReviewMobileCard from "./ReviewMobileCard";
import ReviewOverview from "./ReviewOverview";

import { selectSpecificReview, selectReviewLoading } from "selectors/reviews";

const CARD_CONFIG = {
  selector: selectSpecificReview,
  loadingSelector: selectReviewLoading,
  desktop: ReviewDesktopCard,
  mobile: ReviewMobileCard,
  overview: ReviewOverview,
  compact: ReviewCompactCard,
};

const ReviewCardContainer = (props) => {
  const { CardType, cardProps } = useEntityCard(CARD_CONFIG, "review", props);

  return <CardType {...props} {...cardProps} />;
};

export default ReviewCardContainer;
