import { useEffect, useRef } from "react";

import useLayoutEffect from "hooks/useLayoutEffect";

/*
 * Executes a function when the component mounts if the testFunc returns true
 * */
const useExecOnMount = (
  execFunc,
  mountTestFunc = false,
  props = null,
  compareProps = () => false
) => {
  const lastProps = useRef(props !== null ? { ...props } : null);

  useLayoutEffect(() => {
    const mountTestIsFunc = typeof mountTestFunc === "function";
    const loadOnMount = mountTestIsFunc ? mountTestFunc(props) : mountTestFunc;

    if (loadOnMount) {
      execFunc(props);
    }
    // only on mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (compareProps(props, lastProps.current)) {
      execFunc(props);
    }

    lastProps.current = props !== null ? { ...props } : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
};

export default useExecOnMount;
