/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-x-twitter-square",
  icon: [
    512,
    512,
    [],
    null,
    "M464,0 C490.509668,-4.86974701e-15 512,21.490332 512,48 L512,464 C512,490.509668 490.509668,512 464,512 L48,512 C21.490332,512 3.24649801e-15,490.509668 0,464 L0,48 C-3.24649801e-15,21.490332 21.490332,4.86974701e-15 48,0 L464,0 Z M194.254237,103 L87,103 L215.101043,270.34375 L93.6297262,409 L145.709909,409 L239.410039,302.047115 L321.397653,409 L426,409 L292.374185,232.608654 L405.963494,103 L353.956975,103 L268.28618,200.758173 L194.254237,103 Z M178.563885,132.423077 L364.490874,377.958654 L335.688396,377.958654 L147.625163,132.423077 L178.563885,132.423077 Z",
  ],
};
