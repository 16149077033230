import { useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import getPodcastWrapperId from "components/EntityWrappers/getPodcastWrapperId";
import Error404 from "pages/Error404/Async";

import PodcastView from "./PodcastView";

import { loadSpecificPodcast as loadSpecificPodcastAction } from "actions/podcast";
import { getBaseUrl } from "constants/base";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useExecOnMount from "hooks/useExecOnMount";
import usePodcastData, {
  usePodcastDataIsLoading,
  usePodcastDataFailed,
} from "hooks/usePodcastData";

const compareFunc = (compProps, oldCompProps) =>
  compProps.podcastID !== oldCompProps.podcastID;

const PodcastViewContainer = (props) => {
  const { isInsightPage } = props;

  const params = useParams();
  const location = useLocation();

  const podcastID = getPodcastWrapperId({ params });

  const podcast = usePodcastData(podcastID);
  const podcastTitle = podcast?.get("title");

  const loading = usePodcastDataIsLoading(podcastID);

  const { loadSpecificPodcast } = useActionCreators({
    loadSpecificPodcast: loadSpecificPodcastAction,
  });

  const hasFullyPodcastRecord = podcast && podcast.get("full_record");

  const isFailed = usePodcastDataFailed(podcastID);

  const loadPodcastFlag = podcastID && !loading && !hasFullyPodcastRecord;

  const loadPodcast = useCallback(() => {
    if (loadPodcastFlag) {
      loadSpecificPodcast(podcastID);
    }
  }, [loadPodcastFlag, loadSpecificPodcast, podcastID]);

  useExecOnMount(loadPodcast, loadPodcastFlag, { podcastID }, compareFunc);

  useEffect(() => {
    if (podcastID && podcastTitle) {
      sendGAEvent({
        action: "podcastPageImpression",
        podcast_id: podcastID,
        page: "podcast",
        pageType: "PodcastView",
        page_id: podcastID,
        page_name: podcastTitle,
        podcast_category: podcast?.get("categories").toJS(),
        hosting_provider: podcast?.get("hosting_provider"),
        path: location?.pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastID, podcastTitle, location?.pathname]);

  const insightCanonicalUrl = `${getBaseUrl()}${getPodcastUrl(
    podcast
  )}/insights`;

  if (!podcast && isFailed) {
    return <Error404 />;
  }

  if (isInsightPage) {
    return <PodcastView podcast={podcast} canonicalUrl={insightCanonicalUrl} />;
  }

  return <PodcastView podcast={podcast} />;
};

export default PodcastViewContainer;
