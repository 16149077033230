import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";
import { Link } from "react-router-dom";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";
import ScreenSizes from "styles/ScreenSizes";

const buttonWhite = {
  background: colours.white,
  border: `1px solid ${colours.greyishBlueHighlight}`,
  color: colours.bodyText,

  ...getHoverQuery({
    borderColor: colours.oldSecondary,
    color: colours.bodyText,
  }),
};

const baseStyles = {
  addEntityButton: {
    ...gStyles.button,
    ...gStyles.avalonBold,
    ...gStyles.buttonRounded,
    ...buttonWhite,
    marginLeft: "auto",
    display: "block",
    width: "100%",
    textAlign: "center",

    [ScreenSizes.lgAndAbove]: {
      ...gStyles.button,
      ...gStyles.avalonBold,
      ...buttonWhite,

      width: "auto",
      display: "inline-block",
      fontSize: ".65rem",
      padding: ".5rem 1rem .3rem",
    },

    [ScreenSizes.xlAndAbove]: {
      fontSize: ".75rem",
      padding: ".7rem 1.4rem .5rem",
    },
  },
  addEntityText: {
    ...gStyles.avalonBold,
    fontSize: ".825rem",
    color: colours.primary,
    marginTop: ".75rem",
    marginBottom: "2rem",
    cursor: "pointer",
  },
  addEntityButtonIcon: {
    marginRight: "0.4em",
    Width: "0.547rem",
    maxHeight: "0.547rem",
    minHeight: "0.547rem",
  },
};

const AddEntityButton = (props) => {
  const { to, label, icon, onClick, showAsText, dataId } = props;
  const { styles } = useStyles(baseStyles, props);
  const Component = onClick ? "div" : Link;

  return (
    <Component
      data-id={dataId}
      onClick={onClick}
      to={to}
      className={css(
        showAsText ? styles.addEntityText : styles.addEntityButton
      )}
      aria-label={label}
    >
      <FontAwesomeIcon
        icon={icon}
        className={css(styles.addEntityButtonIcon)}
      />
      {label}
    </Component>
  );
};

AddEntityButton.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  label: PropTypes.node.isRequired,
  icon: PropTypes.object,
  dataId: PropTypes.string,
  showAsText: PropTypes.bool,
};

AddEntityButton.defaultProps = {
  to: null,
  onClick: null,
  icon: faPlus,
  showAsText: false,
  dataId: "add-entity-button",
};

export default memo(AddEntityButton);
