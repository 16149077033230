import { css } from "aphrodite";
import PropTypes from "prop-types";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  about: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    minHeight: 0,
    minWidth: 0,
    flex: "none",
  },
  mobileSection: {
    backgroundColor: "#FFF",
    marginBottom: "1rem",
    padding: "1rem",
    boxShadow: "0 8px 16px 0px rgba(0, 0, 0, 0.1)",
    minHeight: 100,
    flex: "none",
    position: "relative",

    [ScreenSizes.mdAndAbove]: {
      backgroundColor: "transparent",
      padding: "0",
      boxShadow: "none",
      minHeight: 0,
    },
  },
  noMarginBottom: {
    marginBottom: 0,
  },
};

const About = (props) => {
  const {
    mobile,
    sections,
    dataId,
    renderOnMount: passedRenderOnMount,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const renderSection = (section, index) => {
    if (section === true) {
      return null;
    }

    const renderOnMount =
      passedRenderOnMount !== undefined ? passedRenderOnMount : index < 2;

    if (mobile && section && typeof section === "object" && section.component) {
      return (
        <LazyLoadComponent
          key={index}
          renderOnMount={renderOnMount}
          height={770}
        >
          <div
            className={css(
              section.panel ? styles.mobileSection : null,
              section.noMarginBottom && styles.noMarginBottom,
              section?.bgColor && styles[section.bgColor]
            )}
            key={index}
          >
            {section.component}
          </div>
        </LazyLoadComponent>
      );
    } else if (mobile && section) {
      return (
        <LazyLoadComponent
          key={index}
          renderOnMount={renderOnMount}
          height={770}
        >
          <div className={css(styles.mobileSection)} key={index}>
            {section}
          </div>
        </LazyLoadComponent>
      );
    }

    return (
      <LazyLoadComponent key={index} renderOnMount={renderOnMount} height={325}>
        {section}
      </LazyLoadComponent>
    );
  };

  return (
    <div data-id={dataId} className={css(styles.about)}>
      {sections.map(renderSection)}
    </div>
  );
};

About.propTypes = {
  sections: PropTypes.array,
  mobile: PropTypes.bool,
  dataId: PropTypes.string,
  renderOnMount: PropTypes.bool,
};

About.defaultProps = {
  mobile: false,
  sections: [],
  dataId: "page-about-section",
};

export default About;
