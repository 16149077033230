import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";

import Title from "components/Entities/Page/Title";

import NoStubItemsMessage from "./NoStubItemsMessage";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  noItemsLink: {
    ...gStyles.fontSemiBold,
    color: colours.primary,
    cursor: "pointer",
    border: "none",
    padding: 0,
  },
};

const NoReviewsMessage = (props) => {
  const {
    entity,
    entityName,
    entity_type,
    inSidebar,
    noItemsMessage,
    onRatingClick,
    title,
    titleStyles,
  } = props;

  const { styles } = useStyles(baseStyles, props);

  const handleNoItemsClick = useCallback(
    (e) => {
      if (onRatingClick) {
        onRatingClick(e, entity, entity_type);
      }

      sendGAEvent({
        action: `${entity_type}AboutPage-AddReviewClicked`,
        [entity_type ? `${entity_type}_id` : "review_item_id"]:
          entity.get("id"),
        [`${entity_type || "review_item"}_name`]:
          entity && (entity.get("title") || entity.get("name")),
      });
    },
    [onRatingClick, entity_type, entity]
  );

  const noItemsText = `This ${entityName} hasn't been reviewed yet`;

  return (
    <Fragment>
      <Title
        title={title}
        styles={titleStyles}
        TitleComponent="h3"
        inSidebar={inSidebar}
      />
      <NoStubItemsMessage
        message={
          noItemsMessage || (
            <span>
              {`${noItemsText}. You can `}
              <button
                className={css(styles.noItemsLink)}
                onClick={handleNoItemsClick}
              >
                add a review
              </button>{" "}
              to show others what you thought.
            </span>
          )
        }
      />
    </Fragment>
  );
};

NoReviewsMessage.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entityName: PropTypes.string,
  entity_type: PropTypes.string,
  inSidebar: PropTypes.bool,
  noItemsMessage: PropTypes.node,
  onRatingClick: PropTypes.func,
  title: PropTypes.string,
  titleStyles: PropTypes.object,
};

NoReviewsMessage.defaultProps = {
  entity: null,
  entityName: "podcast",
  entity_type: "podcast",
  inSidebar: false,
  noItemsMessage: null,
  onRatingClick: null,
  title: null,
  titleStyles: null,
};

export default memo(NoReviewsMessage);
