import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Creators = Loadable(() => import("./Creators"));

function AboutAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const CreatorsContainer = (props) => {
  return <Creators {...props} fallback={<AboutAsyncLoading />} />;
};

export default CreatorsContainer;
