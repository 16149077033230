import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const PublicInsights = Loadable(() => import("./PublicInsights"));

function InsightsAsync(props) {
  return <LoadableLoading {...props} />;
}

const PublicInsightsContainer = (props) => {
  return <PublicInsights {...props} fallback={<InsightsAsync />} />;
};

export default PublicInsightsContainer;
