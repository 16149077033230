import { memo } from "react";

import PodcastHeader from "./PodcastHeader";
import PodcastHeaderSkeleton from "./PodcastHeaderSkeleton";

const PodcastHeaderContainer = (props) => {
  if (!props.entity) {
    return <PodcastHeaderSkeleton />;
  }

  return <PodcastHeader {...props} />;
};

export default memo(PodcastHeaderContainer);
