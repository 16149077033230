import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback } from "react";

import StandardButton from "components/Buttons/StandardButton";
import SideButtonStyles from "pages/PodcastView/Sidebar/SideButtonStyles";

import claimActions from "actions/claiming";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";
import useUserIsPodcastOwner from "hooks/useUserIsPodcastOwner";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...SideButtonStyles,
  desktopContainer: {
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
    },
  },
};

const ClaimPodcast = (props) => {
  const { podcast } = props;
  const { styles } = useStyles(baseStyles, props);

  const { openClaimModal } = useActionCreators(claimActions);

  const claimedByLoggedInUser = useUserIsPodcastOwner(
    podcast && podcast.get("id")
  );

  const handleClaimClick = useCallback(
    () =>
      openClaimModal(podcast.get("id"), {
        entity: podcast,
        analyticsVariables: {
          triggered_by_source: "podcastViewSidebarClaimButton",
        },
      }),
    [openClaimModal, podcast]
  );

  if (claimedByLoggedInUser) {
    return (
      <Fragment>
        <div className={css(styles.button)}>
          <StandardButton
            label="Manage Podcast"
            variation="white"
            to={`/profile/claimed/${podcast.get("id")}`}
            link
            flat
          />
        </div>
        <div className={css(styles.description)}>
          You are an owner of this podcast.
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className={css(styles.button)}>
        <StandardButton
          label="Claim This Podcast"
          onClick={handleClaimClick}
          variation="white"
          flat
        />
      </div>
      <div className={css(styles.description)}>
        <span className={css(styles.desktopContainer)}>
          Do you host or manage this podcast?
          <br />
        </span>
        Claim and edit this page to your liking.
      </div>
    </Fragment>
  );
};

ClaimPodcast.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
};

export default ClaimPodcast;
