import { Map } from "immutable";
import PropTypes from "prop-types";

import EntityOverviewTemplate from "../EntityOverviewTemplate";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const overviewStyles = {
  entitySubtitle: {
    ...gStyles.fontLight,
    color: colours.lightishGrey,
  },
};

const ReviewOverview = (props) => {
  const { review } = props;

  return (
    <EntityOverviewTemplate
      dataId="review-overview"
      {...props}
      entity_type="review"
      entity={review}
      subtitle={review.get("content")}
      styles={overviewStyles}
    />
  );
};

ReviewOverview.propTypes = {
  review: PropTypes.instanceOf(Map).isRequired,
};

export default ReviewOverview;
