import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import merge from "ramda/src/mergeDeepRight";
import { memo, Fragment, useCallback, useMemo, useRef } from "react";

import AddCreatorsButton from "components/Creator/AddCreatorsButton";
import entityList from "components/Entities/EntityList";
import CreatorItem from "components/Entities/Items/CreatorItemAsync";
import Title from "components/Entities/Page/Title";
import { useStubLinkClick } from "components/Entities/Stubs/useStubLinkClick";

import NoCreatorsMessage from "./NoCreatorsMessage";

import * as sortConstants from "constants/sort";
import loadCreatorsList from "sagas/pagination/lists/loadCreatorsList";
import { selectSpecificCreator } from "selectors/creator";
import { selectList } from "selectors/pagination";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  creatorPanelsOuter: {
    [ScreenSizes.lgAndAbove]: {
      maxHeight: "20rem",
      overflow: "hidden",
    },
  },
  creatorPanels: {
    display: "flex",
    flexDirection: "row",

    [ScreenSizes.lgAndAbove]: {
      flexWrap: "wrap",
    },
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.mdAndAbove]: {
      display: "none",
    },
  },
  desktopContainer: {
    display: "none",

    [ScreenSizes.mdAndAbove]: {
      display: "block",
      marginLeft: "auto",
    },
  },
};

const itemStyles = {
  entityItem: {
    [ScreenSizes.mdAndAbove]: {
      width: "auto",
      padding: "1rem 1rem",
      margin: 0,
    },
  },
};

const regularItemStyles = {
  entityItem: {
    ...itemStyles.entityItem,
    margin: "1rem 0.5rem",
  },
};

const titleStyles = {
  titleContainer: {
    paddingTop: 0,
    paddingBottom: "1rem",
    fontSize: "1rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: "1rem",
    },
  },
  title: {
    [ScreenSizes.smAndAbove]: {
      fontSize: "1em",
    },
  },
};

const CreatorsStub = (props) => {
  const {
    listKey,
    onTabChange,
    staticFilters,
    title,
    listConfig,
    noItemsMessage,
    entityName,
    entity,
    entity_type,
    hideAdd,
    showMore,
    scrollRef,
    promptType,
    creatorItemStyles,
    viewAllLink,
    viewAllTitle,
    inSidebar,
    renderOnMount,
    passedImageStyles,
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess, isWindowSizeOrMore } = useWindowSize();
  const handleLinkClick = useStubLinkClick(onTabChange, scrollRef);

  const list = useReduxState(
    (state) => selectList(state, { key: listKey }),
    [listKey]
  );

  const CreatorsList = useRef(
    entityList({
      key: listKey,
      sort: sortConstants.SORT_ORDER_RELEVANCE,
      sort_direction: "desc",
      list_type: "creators_filtered",
      loadListAction: loadCreatorsList,
      entity_type: "creator",
      pageSize: 4,
      staticFilters,
      getEntity: (state, id) => selectSpecificCreator(state, id),
      loadingStyles: {
        noOverlay: {
          padding: "4rem 0",
          fontSize: "2rem",
          height: "15rem",
        },
      },
      cellSpacing: 0,
      ...listConfig,
    })
  ).current;

  const mobile = isWindowSizeOrLess("medium");
  const isTabletOrLess = isWindowSizeOrLess("xlarge");

  const showCarousel = isTabletOrLess && list && list.get("total") > 1;
  let itemPercentage = isWindowSizeOrMore("medium") ? 15 : 35;

  const creatorStyles = useMemo(() => {
    const containerStyles =
      !showCarousel && !mobile ? regularItemStyles : itemStyles;

    if (creatorItemStyles) {
      return merge(containerStyles, creatorItemStyles);
    }

    return containerStyles;
  }, [showCarousel, mobile, creatorItemStyles]);

  const renderItem = useCallback(
    (creator) => {
      if (creator) {
        return (
          <CreatorItem
            styles={creatorStyles}
            creatorId={creator.get("id")}
            subtitleContextType={entity_type}
            subtitleContextId={entity.get("id")}
            inCarousel={showCarousel}
            renderOnMount={renderOnMount}
            showRole
            passedImageStyles={passedImageStyles}
          />
        );
      }

      return null;
    },
    [
      creatorStyles,
      entity,
      entity_type,
      renderOnMount,
      showCarousel,
      passedImageStyles,
    ]
  );

  if (list && list.get("total") === 0 && list.get("loaded")) {
    return (
      <NoCreatorsMessage
        entity={entity}
        entityName={entityName}
        entity_type={entity_type}
        inSidebar={inSidebar}
        noItemsMessage={noItemsMessage}
        title={title}
      />
    );
  }

  return (
    <Fragment>
      {title && (
        <Title
          title={title}
          onTabChange={handleLinkClick}
          count={showCarousel ? list && list.get("total") : null}
          TitleComponent="h2"
          button={
            <div className={css(styles.desktopContainer)}>
              <AddCreatorsButton
                entity={entity}
                entity_type={entity_type}
                promptType={promptType}
              />
            </div>
          }
          link={viewAllLink}
          viewAllTitle={
            viewAllTitle ||
            (entity.get("title") &&
              `Creators and Guests of ${entity.get("title")}`) ||
            "Creators and Guests"
          }
          styles={titleStyles}
        />
      )}
      <div className={css(styles.creatorPanelsOuter, styles.creatorPanels)}>
        <CreatorsList
          renderItem={renderItem}
          minCarouselItemPercentage={itemPercentage}
          carousel={showCarousel}
          showMore={showMore}
          onMoreClick={handleLinkClick}
        />
      </div>
      {!hideAdd && (
        <div className={css(styles.mobileContainer)}>
          <AddCreatorsButton
            entity={entity}
            entity_type={entity_type}
            promptType={promptType}
          />
        </div>
      )}
    </Fragment>
  );
};

CreatorsStub.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  listKey: PropTypes.string.isRequired,
  staticFilters: PropTypes.object,
  listConfig: PropTypes.object,
  noItemsMessage: PropTypes.node,
  entityName: PropTypes.node,
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  hideAdd: PropTypes.bool,
  showMore: PropTypes.bool,
  promptType: PropTypes.string,
  creatorItemStyles: PropTypes.object,
  scrollRef: PropTypes.object,
  viewAllLink: PropTypes.string,
  viewAllTitle: PropTypes.string,
  renderOnMount: PropTypes.bool,
};

CreatorsStub.defaultProps = {
  title: null,
  staticFilters: null,
  listConfig: {},
  noItemsMessage: null,
  entityName: null,
  entity: Map({}),
  entity_type: "",
  hideAdd: false,
  showMore: false,
  promptType: "creator",
  creatorItemStyles: null,
  scrollRef: null,
  viewAllLink: null,
  viewAllTitle: null,
};

export default memo(CreatorsStub);
