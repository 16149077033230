import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import EpisodeCard from "components/Entities/Cards/EpisodeCards/EpisodeCardContainerAsync";
import PodcastCard from "components/Entities/Cards/PodcastCards/PodcastCardContainerAsync";

import CardActions from "../CardActions";
import CategoryTag from "../CategoryTag";
import Review from "./Review";

import modalActions from "actions/modals";
import { DESKTOP_BOTTOM_ROW_HEIGHT } from "constants/podcast";
import { selectSpecificUser } from "selectors/user";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";
import useReviewWithEntity from "hooks/useReviewWithEntity";
import { useStyles } from "hooks/useStyles";

import cardStyles, { cardSizes } from "styles/CardStyles";
import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  reviewDesktopCard: {
    ...cardStyles.desktopCard,
  },
  reviewDesktopCardNoPanel: {
    ...cardStyles.cardNoPanel,
  },
  topRow: {
    ...cardStyles.topRow,
    flexDirection: "column",
    paddingBottom: 0,
    marginBottom: 0,
    borderBottom: "none",
    [ScreenSizes.lgAndAbove]: {
      paddingBottom: 0,
      marginBottom: 0,
      borderBottom: "none",
    },
  },
  reviewInfoIconRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: ".4rem 0",
  },
  ratingsContainer: {
    marginRight: ".5rem",
  },
  bottomRow: {
    ...cardStyles.bottomRow,
  },
  bottomRowCategories: {
    ...cardStyles.bottomRowCategories,
  },
  actions: {
    ...cardStyles.cardActions,
  },
  tagTitle: {
    ...cardStyles.bottomRowGroupTitle,
  },
  reviewBubble: {
    display: "flex",
    flexDirection: "column",
    marginTop: cardSizes.paddingBetweenReviewAndEntity,
    fontSize: "0.8125rem",
    color: colours.bodyText,
  },
  reviewBubbleNoSidePadding: {
    ...gStyles.noSidePadding,
  },
  reviewBubbleNoVerticalPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
  },
  reviewBubbleRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: ".3rem",
  },
  reviewEntityOverview: {
    padding: cardSizes.standardPadding,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: cardSizes.paddingBetweenReviewAndEntity,
    [ScreenSizes.lgAndAbove]: {
      paddingLeft: 0,
    },
  },
  actionsInReviewBubble: {
    marginTop: ".5rem",
    marginLeft: "auto",
  },
};

const inPanelStyles = {
  reviewDesktopCard: {
    ...cardStyles.noRaisedCard,
    ...cardStyles.flatCard,
  },
  bottomRow: {
    ...cardStyles.bottomRowInsidePanel,
  },
  reviewEntityOverview: {
    paddingBottom: cardSizes.mobileVerticalPaddingOnCardsInPanel,

    [ScreenSizes.mdAndAbove]: {
      paddingBottom: cardSizes.verticalPaddingOnCardsInPanel,
    },
  },
};

const PAGE_SIZE = 3;

const ACTIONS = [{ type: "heart" }];

const getEntityOverview = (entity_type) => {
  if (entity_type === "episode") {
    return EpisodeCard;
  }

  return PodcastCard;
};

const ReviewDesktopCard = (props) => {
  const {
    review: passedReview,
    buttons,
    actions: passedActions,
    hideBottomRow,
    hideUserImage,
    hideEntityOverview,
    noPanel,
    showInfoIcons,
    userRating,
    showActionsInReviewBubble,
    noSidePadding,
    noVerticalPadding,
    insidePanel,
    showReplies,
    renderOnMount,
  } = props;
  const { styles } = useStyles(
    [baseStyles, insidePanel && inPanelStyles],
    props
  );

  const pulledReview = useReviewWithEntity(
    passedReview && passedReview.get("id")
  );
  const review = pulledReview || Map();
  const entity = review.has("entity") ? review.get("entity") : Map();

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const loadedUser = useReduxState(
    (state) => selectSpecificUser(state, review.get("user")),
    [review]
  );
  const user = loadedUser || Map();

  const handleOpenReviewModal = useCallback(
    () => showModal("review", { review_id: review.get("id") }),
    [showModal, review]
  );

  const renderCategory = (category, index) => {
    if (index === PAGE_SIZE - 1 && PAGE_SIZE < review.get("tags").size) {
      return (
        <CategoryTag
          key="categoryMore"
          onClick={handleOpenReviewModal}
          isMore
        />
      );
    }

    return <CategoryTag key={category} slug={category} />;
  };

  const renderTags = () => {
    if (review.get("tags") && review.get("tags").size > 0) {
      return (
        <div className={css(styles.bottomRowCategories)}>
          <div className={css(styles.tagTitle)}>Tags Added</div>
          {review.get("tags") &&
            review.get("tags").slice(0, PAGE_SIZE).map(renderCategory)}
        </div>
      );
    }

    return null;
  };

  const renderActions = () => (
    <CardActions
      actions={passedActions || ACTIONS}
      entity={review}
      entity_type="review"
    />
  );

  const renderReviewBubble = () => (
    <div
      className={css(
        styles.reviewBubble,
        noSidePadding && styles.reviewBubbleNoSidePadding,
        noVerticalPadding && styles.reviewBubbleNoVerticalPadding
      )}
    >
      <Review
        {...props}
        review={review}
        user={user}
        showTitle={!hideUserImage}
        showImage={!hideUserImage}
        userRating={userRating}
        content={review.get("content")}
        showInfoIcons={showInfoIcons}
        showReplies={showReplies}
        renderOnMount={renderOnMount}
      />
    </div>
  );

  const renderEntityOverview = () => {
    const EntityOverview = getEntityOverview(passedReview.get("entity_type"));

    return (
      <div className={css(styles.reviewEntityOverview)}>
        <EntityOverview
          entity_type={passedReview.get("entity_type")}
          entity={entity}
          renderOnMount={renderOnMount}
          layout="overview"
        />
      </div>
    );
  };

  const renderBottomRow = () => (
    <LazyLoadComponent
      renderOnMount={renderOnMount}
      height={DESKTOP_BOTTOM_ROW_HEIGHT}
    >
      <div className={css(styles.bottomRow)}>
        {renderTags()}
        {!showActionsInReviewBubble && (
          <div className={css(styles.actions)}>
            {buttons || renderActions()}
          </div>
        )}
      </div>
    </LazyLoadComponent>
  );

  return (
    <div
      data-id="review-desktop-card"
      className={css(
        styles.reviewDesktopCard,
        noPanel && styles.reviewDesktopCardNoPanel,
        styles.entityCard
      )}
    >
      <div className={css(styles.topRow)}>
        {!hideEntityOverview && renderEntityOverview()}
        {renderReviewBubble()}
      </div>
      {!hideBottomRow && !showActionsInReviewBubble && renderBottomRow()}
    </div>
  );
};

ReviewDesktopCard.propTypes = {
  review: PropTypes.instanceOf(Map),
  buttons: PropTypes.node,
  actions: PropTypes.array,
  hideBottomRow: PropTypes.bool,
  noPanel: PropTypes.bool,
  showInfoIcons: PropTypes.bool,
  userRating: PropTypes.number,
  hideUserImage: PropTypes.bool,
  showActionsInReviewBubble: PropTypes.bool,
  hideEntityOverview: PropTypes.bool,
  noSidePadding: PropTypes.bool,
  insidePanel: PropTypes.bool,
  noVerticalPadding: PropTypes.bool,
  showReplies: PropTypes.bool,
  renderOnMount: PropTypes.bool,
};

ReviewDesktopCard.defaultProps = {
  review: null,
  buttons: null,
  actions: null,
  hideBottomRow: false,
  hideUserImage: false,
  hideEntityOverview: false,
  noPanel: false,
  insidePanel: false,
  showInfoIcons: false,
  showActionsInReviewBubble: true,
  userRating: undefined,
  noSidePadding: false,
  noVerticalPadding: false,
  showReplies: false,
  renderOnMount: false,
};

export default memo(ReviewDesktopCard);
