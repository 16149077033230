import { episodeSchema, podcastSchema } from "api/schema/creator_podcast";
import { schema } from "normalizr";

import { userSchema } from "./user";

const listEntity = new schema.Union(
  { podcast: podcastSchema, episode: episodeSchema },
  (entity, parent) => parent.entity_type
);

const reviewSchema = new schema.Entity("reviews");

reviewSchema.define({
  user: userSchema,
  entity: listEntity,
});

reviewSchema.define({
  user: userSchema,
  entity: reviewSchema,
});

export { reviewSchema };
