import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const HeaderVerifiedBadge = Loadable(() => import("./HeaderVerifiedBadge"));

function HeaderVerifiedBadgeAsyncLoading(props) {
  return <LoadingOverlay height="1.875rem" />;
}

const HeaderVerifiedBadgeContainer = (props) => {
  return (
    <HeaderVerifiedBadge
      {...props}
      fallback={<HeaderVerifiedBadgeAsyncLoading />}
    />
  );
};

export default HeaderVerifiedBadgeContainer;
