import { faCode } from "@fortawesome/free-solid-svg-icons/faCode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useStyles } from "hooks/useStyles";
import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "../../styles/ScreenSizes";

const baseStyles = {
  outerWrapper: {
    ...gStyles.avalonBold,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    color: colours.semiDarkBlueGrey,
    fontSize: ".875rem",
    marginBottom: "1rem",
    [ScreenSizes.lgAndAbove]: {
      marginTop: "-2rem",
    },
  },
  innerWrapper: {
    display: "flex",
    gap: ".2rem",
    padding: "1rem",
  },
};

function ApiCTA() {
  const { styles, css } = useStyles(baseStyles);

  return (
    <div className={css(styles.outerWrapper)}>
      <Link to="/profile/settings/api" className={css(styles.innerWrapper)}>
        <FontAwesomeIcon icon={faCode} />
        <span>Get this podcast via API</span>
      </Link>
    </div>
  );
}

export default ApiCTA;
