import { useEffect, useState } from "react";

import sendGAEvent from "utils/sendGAEvent";
import transformRawDescription, {
  linkifyHTMLContent,
} from "utils/transformRawDescription";

function useTransformRawDescription(initialText) {
  const [text, setText] = useState(initialText);

  useEffect(() => {
    async function transformDescription() {
      if (initialText) {
        if (
          initialText.includes("<a") ||
          initialText.includes("www") ||
          initialText.includes("@") ||
          initialText.includes("http")
        ) {
          const textFormattedWithLinks = await linkifyHTMLContent(initialText);
          const textFormatted = transformRawDescription(textFormattedWithLinks);

          setText(textFormatted);
        } else {
          const textFormatted = transformRawDescription(initialText);

          setText(textFormatted);
        }
      }
    }
    transformDescription();
  }, [initialText]);

  return text;
}

export function TransformRawDescriptionAnalytics({ children, analytics = {} }) {
  return (
    <div
      onClick={(e) => {
        if (!e.target) {
          return;
        }

        const isLinkElement = e.target?.tagName === "A";

        if (isLinkElement) {
          const text = e.target?.text;
          const link = e.target?.href;

          sendGAEvent({
            ...analytics,
            text,
            link,
          });
        }
      }}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
}

export default useTransformRawDescription;
