import Loadable from "@loadable/component";
import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    height: "14.5rem",

    [ScreenSizes.lgAndAbove]: {
      height: "13.5rem",
      width: "25%",
      margin: "0px 1rem",
    },

    [ScreenSizes.xlAndAbove]: {
      width: "14%",
    },
  },
};

const CreatorItemAsync = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.container)} />;
};

const CreatorItemAsyncMemo = memo(CreatorItemAsync);

const CreatorItem = Loadable(() => import("./CreatorItem"));

function CreatorItemAsyncLoading() {
  return <CreatorItemAsyncMemo />;
}

const CreatorItemContainer = (props) => {
  return <CreatorItem {...props} fallback={<CreatorItemAsyncLoading />} />;
};

export default CreatorItemContainer;
