import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useMemo, useContext, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { pure } from "recompose";
import { compose } from "redux";

import EntityPageActionHandler from "components/ActionHandler/EntityPageActionHandlerAsync";
import BetaLogo from "components/Common/Branding/BetaLogoAsync";
import Page from "components/Entities/Page/Page";
import SingleReviewModal from "components/Review/SingleReviewModalAsync";
import SingleReviewStructuredData from "components/Review/SingleReviewStructuredDataAsync";
import RequestContext from "pages/RequestContext";

import PodcastTourPrompt from "../../components/SiteTour/PodcastTourPromptAsync";
import AboutContainer from "./About/AboutContainer";
import AdvertiseContainer from "./AdvertiseOnPodcast/AdvertiseContainerAsync";
import Creators from "./Creators/async";
import Episodes from "./Episodes/async";
import InsightsContainer from "./Insights/InsightsContainer";
import Lists from "./Lists/async";
import Merch from "./Merch/async";
import PodcastHeaderContainer from "./PodcastHeaderContainer";
import PodcastViewSidebarContainer from "./PodcastViewSidebarContainer";
import PodcastViewStructuredData from "./PodcastViewStructuredData";
import Reviews from "./Reviews/async";
import SimilarContainer from "./Similar/SimilarContainer";
import Sponsors from "./Sponsors/async";

import modalActions from "actions/modals";
import canonicalRedirect from "utils/canonicalRedirect";
import getPodcastName from "utils/entity/getPodcastName";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import { VIEW_SPONSOR_HISTORY_PERMISSION } from "utils/entity/user";
import sendGAEvent from "utils/sendGAEvent";
import { getParams } from "utils/url";

import useActionCreators from "hooks/useActionCreators";
import useReviewWithEntity from "hooks/useReviewWithEntity";
import useSiteTourDismissed from "hooks/useSiteTourDismissed";
import useUserHasPermission from "hooks/useUserHasPermission";

const BASE_ROUTE = "/podcasts/:id";

const getTabs = ({
  isBotCrawler,
  podcast,
  redirect,
  showModal,
  userHasViewSponsorPermissions,
}) => {
  const hasEpisodes = podcast && podcast?.get("number_of_episodes") > 0;
  const hasReviews = podcast && podcast?.get("review_count") > 0;
  const hasCreators = podcast && podcast?.get("creator_count") > 0;
  const hasLists = podcast && podcast?.get("list_count") > 0;
  const hasMerch = podcast && podcast?.get("merch_enabled");

  const showPromptModal = (modalProps) => () => {
    const { promptType } = modalProps;
    showModal("prompt", {
      ...modalProps,
      entity: podcast,
      entity_type: "podcast",
      page: `Podcast View - ${promptType} tab`,
    });
    sendGAEvent({
      action: `podcastView-emptyTabClick-${modalProps.promptType}`,
      podcast_id: podcast && podcast?.get("id"),
      podcast_name: podcast && getPodcastName(podcast),
    });
  };

  const podcastUrl = `${getPodcastUrl(podcast)}`;

  return [
    {
      key: "about",
      title: "About",
      contentComponent: AboutContainer,
      anchor: `${podcastUrl}`,
      hiddenTitle: `${podcast?.get("title")}`,
    },
    {
      key: "insights",
      title: "Insights",
      contentComponent: InsightsContainer,
      anchor: `${podcastUrl}/insights`,
      hiddenTitle: `Insights into ${podcast?.get("title")}`,
      condition: true,
      pro: true,
    },
    {
      key: "episodes",
      title: "Episodes",
      contentComponent: Episodes,
      badge: hasEpisodes ? podcast?.get("number_of_episodes") : null,
      anchor: isBotCrawler
        ? `${podcastUrl}/episodes`
        : `${podcastUrl}/episodes/recent`,
      condition: hasEpisodes,
      redirect,
      hiddenTitle: `Episodes of ${podcast?.get("title")}`,
    },
    {
      key: "reviews",
      title: "Reviews",
      contentComponent: Reviews,
      badge: hasReviews ? podcast?.get("review_count") : null,
      anchor: hasReviews ? `${podcastUrl}/reviews` : null,
      preventTabChange: !hasReviews,
      onClick: hasReviews ? null : showPromptModal({ promptType: "review" }),
      contentCondition: hasReviews,
      redirect,
      hiddenTitle: `Reviews of ${podcast?.get("title")}`,
    },
    {
      key: "creators",
      title: "Credits",
      contentComponent: Creators,
      badge: hasCreators ? podcast?.get("creator_count") : null,
      anchor: hasCreators ? `${podcastUrl}/creators` : null,
      preventTabChange: !hasCreators,
      onClick: hasCreators ? null : showPromptModal({ promptType: "creator" }),
      contentCondition: hasCreators,
      redirect,
      hiddenTitle: `Creators of ${podcast?.get("title")}`,
    },
    {
      key: "lists",
      title: "Lists",
      contentComponent: Lists,
      badge: hasLists ? podcast?.get("list_count") : null,
      anchor: hasLists && `${podcastUrl}/lists`,
      preventTabChange: !hasLists,
      onClick: hasLists ? null : showPromptModal({ promptType: "list" }),
      contentCondition: hasLists,
      redirect,
      hiddenTitle: `Lists featuring ${podcast?.get("title")}`,
    },
    {
      key: "merch",
      title: "Merch",
      contentComponent: Merch,
      condition: !!hasMerch,
      redirect,
    },
    {
      key: "similar",
      title: "Similar",
      hiddenTitle: "Similar Podcasts",
      contentComponent: SimilarContainer,
      anchor: `${podcastUrl}/similar`,
    },
    {
      key: "sponsors",
      title: (
        <>
          Sponsors
          <BetaLogo>Beta</BetaLogo>
        </>
      ),
      contentComponent: Sponsors,
      tabCondition: false,
      condition: userHasViewSponsorPermissions,
      redirect,
    },
    {
      key: "advertise",
      title: "",
      tabCondition: false,
      contentComponent: AdvertiseContainer,
      anchor: `${podcastUrl}/advertise`,
    },
  ];
};

const REVIEWS_ROUTE = "/podcasts/:podcast_id/reviews/:review_id";

const PodcastView = ({ podcast, canonicalUrl }) => {
  const location = useLocation();

  const { showModal } = useActionCreators(modalActions);
  const userHasViewSponsorPermissions = useUserHasPermission(
    VIEW_SPONSOR_HISTORY_PERMISSION
  );

  const { hideTour } = useSiteTourDismissed("podcast");
  const { isBotCrawler } = useContext(RequestContext);

  const { id } = useParams();

  const basePathname = `/podcasts/${id}`;

  const tabs = useMemo(
    () =>
      getTabs({
        isBotCrawler,
        podcast,
        redirect: basePathname,
        showModal,
        userHasViewSponsorPermissions,
      }),
    [
      podcast,
      showModal,
      isBotCrawler,
      userHasViewSponsorPermissions,
      basePathname,
    ]
  );

  const { review_id } = getParams(location.pathname, REVIEWS_ROUTE);
  const review = useReviewWithEntity(review_id);

  const mobilePodcastFollowButton = useRef();

  const routePassProps = useMemo(
    () => ({
      podcast,
      episodesKey: `podcastView/${podcast?.get("id")}/episodes`,
      isSingleReview: !!review_id,
    }),
    [podcast, review_id]
  );

  const podcastId = podcast && podcast?.get("id");

  const analyticsVariables = useMemo(
    () => ({
      podcast_id: podcastId,
      page: "podcast",
      pageType: "PodcastView",
      page_id: podcastId,
      page_name: podcast?.get("title"),
      podcast_category: podcast?.get("categories").toJS(),
      hosting_provider: podcast?.get("hosting_provider"),
    }),
    [podcast, podcastId]
  );

  return (
    <Fragment>
      {!hideTour && <PodcastTourPrompt />}
      {podcast && (
        <PodcastViewStructuredData
          podcast={podcast}
          canonicalUrl={canonicalUrl}
        />
      )}
      <Page
        baseRoute={BASE_ROUTE}
        tabs={tabs}
        basePathname={basePathname}
        header={PodcastHeaderContainer}
        sideBar={PodcastViewSidebarContainer}
        tabProps={routePassProps}
        preserveScroll
        analyticsVariables={analyticsVariables}
        entity_type="podcast"
        entity={podcast}
        showMobileTabs
        mobilePodcastFollowButton={mobilePodcastFollowButton}
      />
      {podcast && (
        <EntityPageActionHandler
          entity={podcast}
          entity_type="podcast"
          getName={getPodcastName}
        />
      )}
      {review_id && review && (
        <Fragment>
          <SingleReviewStructuredData review_id={review_id} />
          <SingleReviewModal
            review_id={parseInt(review_id, 10)}
            navigate_on_exit={getPodcastUrl(podcast, "/reviews")}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

PodcastView.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  canonicalUrl: PropTypes.string,
};

PodcastView.defaultProps = {
  podcast: null,
};

export default compose(
  canonicalRedirect((props = {}) => {
    if (!props?.podcast) {
      return props?.match?.url;
    }

    const { review_id } =
      props.location.pathname &&
      getParams(props?.location?.pathname, REVIEWS_ROUTE);

    const episodeSort =
      props?.match?.path.includes("episodes/:sort") &&
      props?.match?.params.sort;

    const isInsights = props?.match?.path.includes("insights");
    const podcastUrl = getPodcastUrl(props?.podcast);

    if (review_id) {
      return `${podcastUrl}/reviews/${review_id}`;
    }

    if (episodeSort) {
      return `${podcastUrl}/episodes/${episodeSort}`;
    }

    if (isInsights) {
      return `${podcastUrl}/insights`;
    }

    return podcastUrl;
  }),
  pure
)(PodcastView);
