import { List, Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo } from "react";
import Helmet from "react-helmet-async";

import { getBaseUrl } from "constants/base";
import { selectCategoriesForPodcast } from "selectors/podcast";
import getCreatorDisplayName from "utils/entity/getCreatorDisplayName";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import { generateMetaArray } from "utils/meta";
import commaList from "utils/text/commaList";
import { truncateString } from "utils/truncate";
import { getCreatorUrl } from "utils/url/creatorUrls";

import useReduxState from "hooks/useReduxState";

export function getPodcastMetaDescription(podcast, creators = List()) {
  let description = "";

  if (creators.size > 0) {
    const creatorText = commaList(
      creators.slice(0, 2),
      (creator) => creator.get("name"),
      { commaFunc: (i, str) => str }
    ).join("");
    description = `With ${creatorText}, `;
  }

  description = `${description}${podcast.get("number_of_episodes")} episodes`;

  if (podcast.get("rating_count") > 0) {
    description = `${description}, ${podcast.get(
      "rating_count"
    )} ratings & reviews`;
  }

  return truncateString(`${description}. ${podcast.get("description")}`, 500);
}

const PodcastViewStructuredData = ({
  podcast,
  canonicalUrl: passedCanonicalUrl,
}) => {
  const podcastId = podcast.get("id");
  const categories = useReduxState(
    (state) => selectCategoriesForPodcast(state, podcastId),
    [podcastId]
  );

  const canonicalUrl =
    passedCanonicalUrl || `${getBaseUrl()}${getPodcastUrl(podcast)}`;

  const { title, script, links, meta } = useMemo(() => {
    const aggregateRating = {
      "@type": "AggregateRating",
      ratingValue: podcast.get("rating"),
    };

    const roleMap = {
      host: "creator",
      editor: "editor",
      producer: "producer",
    };

    const creatorsJson = podcast
      .getIn(["summary", "creators"], List())
      .reduce((agg, next) => {
        const roleCode = next.getIn(["role", "code"], "contributor");
        agg[roleMap[roleCode]] = [
          ...(agg[roleMap[roleCode]] || []),
          {
            "@type": "Person",
            "@id": getBaseUrl() + getCreatorUrl(next),
            name: getCreatorDisplayName(next),
          },
        ];

        return agg;
      }, {});

    const jsonLd = {
      "@context": "http://schema.org",
      "@type": "PodcastSeries",
      "@id": canonicalUrl,
      accessMode: "auditory",
      genre:
        categories && categories.size > 0 && categories.first()?.get("text"),
      // isFamilyFriendly: '', // TODO: use explicit value to determine this
      description: truncateString(podcast.get("description"), 300),
      identifier: podcast.get("id"), // maybe make this our canonical URL?
      image: podcast.get("image_url"),
      name: podcast.get("title"),
      url: canonicalUrl,
      webFeed: podcast.get("feed_url"),
      ...creatorsJson,
    };

    if (podcast.get("rating_count") > 0) {
      aggregateRating.ratingCount = podcast.get("rating_count");
    }
    if (podcast.get("review_count") > 0) {
      aggregateRating.reviewCount = podcast.get("review_count");
    }
    if (podcast.get("rating_count") > 0) {
      jsonLd.aggregateRating = aggregateRating;
    }

    if (podcast.get("date_of_first_episode")) {
      jsonLd.startDate = podcast.get("date_of_first_episode");
    }

    let suffix = " Podcast";

    if (categories && categories.size > 0) {
      suffix = ` - ${categories.first()?.get("text")}${suffix}`;
    }

    return {
      // add 'Podcast' to the end of the title, removing any existing 'Podcast' already there
      title: `${podcast.get("title").trim()}${suffix}`,
      script: [
        { type: "application/ld+json", innerHTML: JSON.stringify(jsonLd) },
      ],
      links: [
        {
          rel: "canonical",
          href: canonicalUrl,
        },
      ],
      meta: generateMetaArray(
        {
          title: podcast.get("title"),
          url: canonicalUrl,
          image: { url: podcast.get("image_url") },
          description: getPodcastMetaDescription(
            podcast,
            podcast.getIn(["summary", "creators"], List())
          ),
        },
        [{ property: "og:image", content: podcast.get("image_url") }]
      ),
    };
  }, [canonicalUrl, categories, podcast]);

  return <Helmet script={script} title={title} meta={meta} link={links} />;
};

PodcastViewStructuredData.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
};

export default PodcastViewStructuredData;
