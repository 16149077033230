import Loadable from "@loadable/component";
const ProConcierge = Loadable(() =>
  import(/* webpackChunkName: "ProConcierge" */ "./ProConcierge")
);

function ProConciergeAsyncLoading() {
  return null;
}

const ProConciergeContainer = (props) => {
  return <ProConcierge {...props} fallback={<ProConciergeAsyncLoading />} />;
};

export default ProConciergeContainer;
