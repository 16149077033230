import Loadable from "@loadable/component";
const SingleReviewStructuredData = Loadable(() =>
  import("./SingleReviewStructuredData")
);

function AboutAsyncLoading() {
  return null;
}

const SingleReviewStructuredDataContainer = (props) => {
  return (
    <SingleReviewStructuredData {...props} fallback={<AboutAsyncLoading />} />
  );
};

export default SingleReviewStructuredDataContainer;
