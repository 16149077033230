import PropTypes from "prop-types";
import { useEffect } from "react";

import wh from "./react-with-hover";

const WithHoverClass = (props) => {
  const {
    hover,
    onHoverChange,
    wrappedComponent: WrappedComponent,
    ...rest
  } = props;

  useEffect(() => {
    if (onHoverChange) {
      onHoverChange(hover);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hover]);

  return <WrappedComponent {...rest} />;
};

WithHoverClass.propTypes = {
  onHoverChange: PropTypes.func,
  hover: PropTypes.bool,
  wrappedComponent: PropTypes.func.isRequired,
};
WithHoverClass.defaultProps = {
  onHoverChange: null,
  hover: false,
};

function withHover(WrappedComponent, whProps = {}) {
  const NewWithHoverClass = (props) => (
    <WithHoverClass {...props} wrappedComponent={WrappedComponent} />
  );

  return wh(whProps)(NewWithHoverClass);
}

withHover.propTypes = {
  onHoverChange: PropTypes.func,
  passHover: PropTypes.bool,
};
withHover.defaultProps = {
  onHoverChange: null,
  passHover: false,
};

export default withHover;
