import {
  makeSelectSpecificEpisode,
  selectEpisodeLoading,
  selectEpisodeFailed,
} from "selectors/podcast";

import useReduxState from "hooks/useReduxState";

export function useEpisodeData(episodeId) {
  const selectSpecificEpisode = makeSelectSpecificEpisode();

  const episode = useReduxState(
    (state) => selectSpecificEpisode(state, episodeId),
    [episodeId]
  );

  return episode;
}

export function useEpisodeDataIsLoading(episodeId) {
  const loading = useReduxState(
    (state) => selectEpisodeLoading(state, episodeId),
    [episodeId]
  );

  return loading;
}

export function useEpisodeDataFailed(podcastID) {
  const failed = useReduxState(
    (state) => selectEpisodeFailed(state, podcastID),
    [podcastID]
  );

  return failed;
}
export default useEpisodeData;
