import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const EpisodeCompactCard = Loadable(() => import("./EpisodeCompactCard"));

function ProAsyncLoading(props) {
  return <AsyncLoadingEntityCard {...props} type="compact" />;
}

const EpisodeCompactCardContainer = (props) => {
  return <EpisodeCompactCard {...props} fallback={<ProAsyncLoading />} />;
};

export default EpisodeCompactCardContainer;
