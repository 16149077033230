import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "150rem",
    maxHeight: "150rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "91.6rem",
      maxHeight: "91.6rem",
    },
  },
};

const ListsContainer = Loadable(() => import("./ListsContainer"));

function ListsAsyncLoading() {
  return <LoadingOverlay height={"91.6rem"} styles={loadingStyles} />;
}

const ListsContainerContainer = (props) => {
  return <ListsContainer {...props} fallback={<ListsAsyncLoading />} />;
};

export default ListsContainerContainer;
