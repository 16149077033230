import Loadable from "@loadable/component";

const HeaderAuthorityScore = Loadable(() =>
  import(
    /* webpackChunkName: "HeaderAuthorityScore" */ "./HeaderAuthorityScore"
  )
);

function HeaderAuthorityScoreAsyncLoading() {
  return null;
}

const HeaderAuthorityScoreContainer = (props) => {
  return (
    <HeaderAuthorityScore
      {...props}
      fallback={<HeaderAuthorityScoreAsyncLoading />}
    />
  );
};

export default HeaderAuthorityScoreContainer;
