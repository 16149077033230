import { useCallback, useState } from "react";

import InsightNavigation from "./InsightNavigation";
import InsightNavigationBar from "./InsightNavigationBar";
import NavItem from "./NavItem";
import useNavigationScroll from "./useNavigationScroll";

import usePodcastData from "hooks/usePodcastData";
import { useScrollPosition } from "hooks/useScrollPosition";

function InsightsBar(props) {
  const { refs, lastScrollableElementRef, podcastId, contactRef } = props;

  const podcast = usePodcastData(podcastId);

  const firstStop = refs[0]?.id;

  const [isNotScrolling, setIsNotScrolling] = useState(true);

  const scrollPosition = useScrollPosition(true, 10);
  const shuffleNav = scrollPosition > 365;

  const [activeLocation, setActiveLocation] = useState(firstStop);

  const lastScrollableElementTop = lastScrollableElementRef?.current?.offsetTop;

  useNavigationScroll({
    activeLocation,
    isNotScrolling,
    lastScrollableElementTop,
    refs,
    scrollPosition,
    setActiveLocation,
  });

  return (
    <InsightNavigation
      contactRef={contactRef}
      entity={podcast}
      entityType="Podcast"
      refs={refs}
      setActiveLocation={setActiveLocation}
      setIsNotScrolling={setIsNotScrolling}
      shuffleNav={shuffleNav}
    >
      {({ handleClick, navSubContainerClass }) => {
        return (
          <div className={navSubContainerClass}>
            <InsightNavigationBar numberElements={refs.length} />
            <div>
              {refs.map(({ ref, id }) => {
                return (
                  <NavItem
                    label={id}
                    active={activeLocation === id}
                    onClick={() => handleClick(id, ref)}
                    key={id}
                  />
                );
              })}
            </div>
          </div>
        );
      }}
    </InsightNavigation>
  );
}

export default InsightsBar;
