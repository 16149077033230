import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";

import AbstractMenu from "components/Menu/AbstractMenuAsync";

import PodchaserProBadge from "../../Branding/PodchaserProBadge";

import generateTransition from "utils/generateTransition";
import { matchesRouteWithParams } from "utils/url";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  pageHeaderTabMenu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    WebkitAppearance: "initial",
  },
  currentTab: {
    ...gStyles.avalonBold,
    fontSize: "1.125rem",
    position: "relative",
  },
  icon: {
    color: "#fff",
    fontSize: "1.3rem",
    minWidth: "1rem",
    minHeight: "1rem",
    marginLeft: "0.5rem",
    transition: generateTransition({ target: "transform", speed: "400ms" }),
    position: "relative",
  },
  closeIcon: {
    transform: "rotate(-180deg)",
  },
  proBadge: {
    marginLeft: "0.5rem",
  },
};

const menuStyles = {
  menuItem: {
    padding: "12px 70px 11px 15px",
    [ScreenSizes.mdAndAbove]: {
      padding: "12px 70px 11px 12px",
    },
  },
  menuItemActive: {
    paddingLeft: 12,
    color: "var(--color-neutral-d4)",
    [ScreenSizes.mdAndAbove]: {
      paddingLeft: 9,
    },
  },
};

const PageHeaderTabMenu = (props) => {
  const {
    hideCurrent,
    title,
    tabs,
    baseRoute,
    onTabChange,
    noMenu,
    ariaLabel,
  } = props;

  const location = useLocation();

  const { styles } = useStyles(baseStyles, props);

  const menuItems = useMemo(
    () =>
      tabs
        .filter(
          (item) =>
            !Object.prototype.hasOwnProperty.call(item, "condition") ||
            item.condition
        )
        .map((tab) => ({
          label: (
            <span key={tab.key}>
              <FontAwesomeIcon icon={tab.icon} fixedWidth /> {tab.title}
              {tab.pro && (
                <span className={css(styles.proBadge)}>
                  <PodchaserProBadge></PodchaserProBadge>
                </span>
              )}
            </span>
          ),
          action:
            Object.prototype.hasOwnProperty.call(tab, "contentCondition") &&
            !tab.contentCondition
              ? tab.onClick
              : () => onTabChange(tab),
          id: tab.key,
          condition: tab.condition,
        })),
    [tabs, styles.proBadge, onTabChange]
  );

  const currentTab = useMemo(() => {
    const foundTab = tabs.find((tab) =>
      matchesRouteWithParams(location.pathname, `${baseRoute}/${tab.key}`)
    );

    return foundTab || tabs[0];
  }, [tabs, location, baseRoute]);

  const isActive = useCallback(
    (tab) => tab.id === currentTab.key,
    [currentTab]
  );

  const renderCurrentTab = () => (
    <div key="currentTab" className={css(styles.currentTab)}>
      {currentTab.title}
    </div>
  );

  const renderLabel = (labelProps) => {
    // makes sure some props aren't passed to the div dom element
    const { ref, isOpen, ...rest } = labelProps;

    // clears out props that shoulds go to the dom
    delete rest.menuItems;

    return (
      <div
        {...rest}
        ref={ref}
        className={css(styles.pageHeaderTabMenu)}
        data-id="page-header-tab-menu"
      >
        {title}
        {!hideCurrent && renderCurrentTab()}
        {!noMenu && (
          <span className={css(styles.icon, isOpen && styles.closeIcon)}>
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
    );
  };

  if (noMenu) {
    return renderLabel({});
  }

  return (
    <AbstractMenu
      isActive={isActive}
      renderTogglerLabel={renderLabel}
      arrowPlacement="bottom"
      menuItems={menuItems}
      ariaLabel={ariaLabel}
      styles={menuStyles}
    />
  );
};

PageHeaderTabMenu.propTypes = {
  tabs: PropTypes.array.isRequired,
  onTabChange: PropTypes.func.isRequired,
  hideCurrent: PropTypes.bool,
  baseRoute: PropTypes.string.isRequired,
  title: PropTypes.node,
  noMenu: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

PageHeaderTabMenu.defaultProps = {
  hideCurrent: false,
  title: null,
  noMenu: false,
  ariaLabel: undefined,
};

export default PageHeaderTabMenu;
