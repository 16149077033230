import Loadable from "@loadable/component";

import AsyncDesktopLoadingEntityCard from "../AsyncDesktopLoadingEntityCard";

const PodcastDesktopCard = Loadable(() => import("./PodcastDesktopCard"));

function ProAsyncLoading(props) {
  return <AsyncDesktopLoadingEntityCard />;
}

const PodcastDesktopCardContainer = (props) => {
  return <PodcastDesktopCard {...props} fallback={<ProAsyncLoading />} />;
};

export default PodcastDesktopCardContainer;
