export function trimWhiteSpaceAndLineBreaks(stringToTrim) {
  if (!stringToTrim) {
    return stringToTrim;
  }

  let trimmedString = stringToTrim.trim();
  const brString = "(<br />|<br/>|<br>)";
  const startBrRegex = new RegExp(`^${brString}`);
  const endBrRegex = new RegExp(`${brString}${"$"}`);

  if (startBrRegex.test(trimmedString) || endBrRegex.test(trimmedString)) {
    trimmedString = trimmedString.replace(startBrRegex, "");
    trimmedString = trimmedString.replace(endBrRegex, "");

    return trimWhiteSpaceAndLineBreaks(trimmedString);
  }

  return trimmedString;
}

const absoluteURLRegex =
  /^([a-z]+:)?(\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,8}(:[0-9]{1,7})?(\/.*)?$/;

export default function transformRawDescription(rawDescription) {
  if (!rawDescription) {
    return rawDescription;
  }
  let description = rawDescription;
  // If there aren't any HTML line breaks in the description,
  // assume they want ASCII line breaks to be used
  if (!description.match(/<br\s\/?>/)) {
    description = description.replace(/(?:\r\n|\r|\n)/g, "<br />");
  } else {
    // just remove all line breaks with spaces and add
    // them back after HTML line breaks for ease of debugging
    description = description
      .replace(/(?:\r\n|\r|\n)/g, " ")
      .replace(/<br\s\/?>/g, "<br />\n");
  }

  // add a line break after end paragraphs
  description = description.replace(/<\/p\s*>/gi, "</p><br/>");

  // remove <p /> tags as they add extra line breaks when <br /> are already added
  description = description.replace(/(<p\s*>|<\/p\s*>)/gi, "");

  // Enforce a maximum of two line breaks
  description = description.replace(/(<br\s*\/?>\s*){2,}/gi, "<br /><br />\n");

  // add nofollow and class to any existing links
  description = description.replace(
    /(<a\s+)([^>]+>)/g,
    '$1rel="ugc" class="parsed_link" target="_blank" $2'
  );

  // truncate any existing links

  description = description.replace(
    /(<a\s+[^>]+>)(.*?)(<\s*\/a\s*>)/g,
    (match, passedP1, p2, p3) => {
      let p1 = passedP1;
      // don't allow relative links
      // eslint-disable-next-line no-unused-vars
      const matchResult = p1.match(/href\s*=\s*["']([^"']+)['"]/);

      if (!matchResult) {
        return `${p1}${p2}${p3}`;
      }

      const [, url] = matchResult;

      if (!url.match(absoluteURLRegex)) {
        // eslint-disable-next-line no-param-reassign,no-multi-assign
        p1 = p3 = "";
      }

      if (url.substr(0, 4) !== "http") {
        p1 = p1.replace(url, `https://${url}`);
      }

      if (p2.length <= 50 || p2.includes("<img")) {
        return `${p1}${p2}${p3}`;
      }
      return `${p1}${p2.substr(0, 50)}…${p3}`;
    }
  );

  // change any images to https to avoid security issues
  // (may end up with broken images, but that's a preferred option)
  description = description.replace(
    /<\s*img([^>]*)\s+src\s*=\s*"\s*(http):/g,
    (match, p1) => `<img ${p1} src="https:`
  );

  if (description) {
    description = trimWhiteSpaceAndLineBreaks(description);
  }

  return description;
}

export function linkifyHTMLContent(rawDescription) {
  try {
    return import("linkifyjs/html").then((linkifyHtmlPackage) => {
      const linkifyHtml = linkifyHtmlPackage.default;

      return linkifyHtml(rawDescription, {
        attributes: {
          rel: "ugc",
          target: "_blank",
        },
        className: "parsed_link",
        format: {
          url: (value) =>
            value.length > 50 ? `${value.slice(0, 50)}…` : value,
        },
        validate: {
          url: (value) => value.match(absoluteURLRegex),
        },
      });
    });
  } catch (e) {
    console.error(e);
    // we can't be sure their HTML doesn't have unescaped characters in it,
    // in which case Linkify can't tokenize the HTML properly and will fail
  }
}
