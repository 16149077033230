import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "820rem",
    maxHeight: "820rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "430rem",
      maxHeight: "430rem",
    },
  },
};

const Similar = Loadable(() => import("./Similar"));

function SimilarAsyncLoading() {
  return <LoadingOverlay height={"136rem"} styles={loadingStyles} />;
}

const SimilarContainer = (props) => {
  return <Similar {...props} fallback={<SimilarAsyncLoading />} />;
};

export default SimilarContainer;
