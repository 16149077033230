import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Insights = Loadable(() => import("./Insights"));

function InsightsAsync(props) {
  return <LoadableLoading {...props} />;
}

const InsightsContainer = (props) => {
  return <Insights {...props} fallback={<InsightsAsync />} />;
};

export default InsightsContainer;
