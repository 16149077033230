import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const EpisodeCardContainer = Loadable(() => import("./EpisodeCardContainer"));

const ASYNC_LOADING_ENTITY_CARD_STYLES = {
  mobile: {
    minHeight: "11.375rem",
    maxHeight: "11.375rem",
  },
  desktop: {
    minHeight: "14rem",
    maxHeight: "14rem",
  },
};

function ContainerAsyncLoading(props) {
  return (
    <AsyncLoadingEntityCard
      type={props.mobile ? "mobile" : "desktop"}
      styles={props.asyncStyles || ASYNC_LOADING_ENTITY_CARD_STYLES}
    />
  );
}

const EpisodeCardContainerContainer = (props) => {
  return (
    <EpisodeCardContainer
      {...props}
      fallback={<ContainerAsyncLoading {...props} />}
    />
  );
};

export default EpisodeCardContainerContainer;
