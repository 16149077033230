import Loadable from "@loadable/component";
import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    width: "100%",
    ...gStyles.skeletonPlaceholder,
    minHeight: "104.125rem",

    [ScreenSizes.smAndAbove]: {
      minHeight: "34.3rem",
    },

    [ScreenSizes.lgAndAbove]: {
      minHeight: "39.7rem",
    },

    [ScreenSizes.xlAndAbove]: {
      minHeight: "21.6rem",
    },
  },
};

const PodcastsStubAsync = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.container)} />;
};

const PodcastsStubAsyncMemo = memo(PodcastsStubAsync);

const PodcastsStub = Loadable(() => import("./PodcastsStub"));

function PodcastsStubAsyncLoading() {
  return <PodcastsStubAsyncMemo />;
}

const PodcastsStubContainer = (props) => {
  return <PodcastsStub {...props} fallback={<PodcastsStubAsyncLoading />} />;
};

export default PodcastsStubContainer;
