import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";

import Title from "components/Entities/Page/Title";

import NoStubItemsMessage from "./NoStubItemsMessage";

import authActions from "actions/auth";
import modalActions from "actions/modals";
import { selectAuthLoggedIn } from "selectors/auth";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  noItemsLink: {
    ...gStyles.noButton,
    ...gStyles.fontSemiBold,
    display: "inline",
    color: colours.primary,
    cursor: "pointer",
  },
};

const NoCreatorsMessage = (props) => {
  const {
    entity,
    entityName,
    entity_type,
    inSidebar,
    noItemsMessage,
    promptType,
    title,
  } = props;

  const { styles } = useStyles(baseStyles, props);

  const isLoggedIn = useReduxState((state) => selectAuthLoggedIn(state), []);

  const { startAuthFlow, showModal } = useActionCreators({
    ...modalActions,
    ...authActions,
  });

  const handleNoItemsClick = useCallback(() => {
    if (isLoggedIn) {
      showModal("prompt", {
        promptType,
        entity,
        entity_type,
      });
      sendGAEvent({
        action: `${entity_type}AboutPage-AddCreatorClicked`,
        [`${entity_type}_id`]: entity.get("id"),
        [`${entity_type}_name`]:
          entity && (entity.get("title") || entity.get("name")),
      });
    } else {
      startAuthFlow(undefined, {
        entity,
        actionType: "Add creators to",
        entityAction: "action",
        analyticsVariables: {
          triggered_by: "creatorsStub",
        },
      });
    }
  }, [isLoggedIn, showModal, promptType, entity, entity_type, startAuthFlow]);

  const noItemsText = `We don't know anything about the creators of this ${entityName} yet`;

  return (
    <Fragment>
      <Title title={title} TitleComponent="h2" inSidebar={inSidebar} />
      <NoStubItemsMessage
        message={
          noItemsMessage || (
            <span>
              {noItemsText}. You can{" "}
              <button
                className={css(styles.noItemsLink)}
                onClick={handleNoItemsClick}
              >
                add them yourself
              </button>{" "}
              so they can be credited for this and other podcasts.
            </span>
          )
        }
      />
    </Fragment>
  );
};

NoCreatorsMessage.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entityName: PropTypes.string,
  entity_type: PropTypes.string,
  inSidebar: PropTypes.bool,
  noItemsMessage: PropTypes.node,
  onRatingClick: PropTypes.func,
  promptType: PropTypes.string,
  title: PropTypes.string,
  titleStyles: PropTypes.object,
};

NoCreatorsMessage.defaultProps = {
  entity: null,
  entityName: "podcast",
  entity_type: "podcast",
  inSidebar: false,
  noItemsMessage: null,
  onRatingClick: null,
  promptType: "creator",
  title: null,
  titleStyles: null,
};

export default memo(NoCreatorsMessage);
