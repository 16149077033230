import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";

import { TabsContext } from "components/Common/Tabs";
import ReviewsStub from "components/Entities/Stubs/ReviewsStub";

import ratingActions from "actions/rating";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";

import useActionCreators from "hooks/useActionCreators";
import useWindowSize from "hooks/useWindowSize";

const AboutReviews = (props) => {
  const { episode, onTabChange } = props;
  const { openEpisodeRatingModal } = useActionCreators(ratingActions);

  const reviewsOnTabChange = useCallback(
    () => onTabChange("reviews"),
    [onTabChange]
  );

  const onRatingClick = useCallback(
    () => openEpisodeRatingModal(episode.get("id"), { entity: episode }),
    [episode, openEpisodeRatingModal]
  );

  const staticFilters = useMemo(
    () => ({
      episode_id: {
        value: episode && episode.get("id"),
      },
    }),
    [episode]
  );

  const { contentRef } = useContext(TabsContext);
  const { isWindowSizeOrLess } = useWindowSize();

  const mobile = isWindowSizeOrLess("medium");

  return (
    <ReviewsStub
      listKey={`episodeView/${episode.get("id")}/aboutReviews`}
      title="Episode Reviews"
      onTabChange={reviewsOnTabChange}
      staticFilters={staticFilters}
      entity={episode}
      entity_type="episode"
      entityName="episode"
      linkLabel="View All Reviews"
      onRatingClick={onRatingClick}
      scrollRef={contentRef}
      viewAllLink={getEpisodeUrl(episode, { path: "reviews" })}
      viewAllTitle={`Podcast Reviews of ${episode.get("title")}`}
      inSidebar={mobile}
    />
  );
};

AboutReviews.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  episode: PropTypes.instanceOf(Map),
};
AboutReviews.defaultProps = {
  episode: null,
};

export default AboutReviews;
