import Loadable from "@loadable/component";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const EpisodeDesktopCard = Loadable(() => import("./EpisodeDesktopCard"));

const baseStyles = {
  asyncLoadingEntityCard: {
    ...gStyles.skeletonPlaceholder,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    borderRadius: "6px",
    border: "1px solid rgba(0,0,0,0.1)",
    marginTop: "1rem",

    ":first-child": {
      marginTop: "1rem",
    },

    minHeight: "14rem",
    maxHeight: "14rem",
  },
};

function EpisodeDesktopCardAsyncLoading(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return <div className={css(styles.asyncLoadingEntityCard)} />;
}

const EpisodeDesktopCardContainer = (props) => {
  return (
    <EpisodeDesktopCard
      {...props}
      fallback={<EpisodeDesktopCardAsyncLoading />}
    />
  );
};

export default EpisodeDesktopCardContainer;
