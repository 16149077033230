import AcastLogo from "./images/acastLogoBlack.png";
import defaultLogo from "./images/acastLogoBlack.png";
import acastVerifiedIcon from "./images/verifiedIcon.svg";
import defaultIcon from "./images/verifiedIcon.svg";

import { capitalize } from "utils/misc";

function getAbbreviation(partner) {
  const hasSpace = partner.indexOf(" ") !== -1;
  return (
    partner.substring(0, hasSpace ? 1 : 2) +
    (hasSpace ? partner.charAt(partner.lastIndexOf(" ") + 1) : "")
  );
}

export default function getVerifiedBannerData(partner) {
  let verfiedLogo = null;
  let verfiedText = null;
  let verfiedTextAbbreviated = null;
  let verifiedIcon = null;
  let verfiedSlogan = "";

  switch (partner) {
    case "acast":
      verfiedLogo = AcastLogo;
      verifiedIcon = acastVerifiedIcon;
      verfiedSlogan = "Verified Partner";
      break;

    default: {
      verfiedLogo = defaultLogo;
      verifiedIcon = defaultIcon;
      verfiedText = capitalize(partner?.replace("_", " "));
      verfiedTextAbbreviated = getAbbreviation(
        partner?.toUpperCase()?.replace("_", " ")
      );
      verfiedSlogan = "Verified Partner";
    }
  }

  return {
    verfiedLogo,
    verfiedText,
    verfiedTextAbbreviated,
    verifiedIcon,
    verfiedSlogan,
  };
}
