import Loadable from "@loadable/component";
import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "8.9rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "3rem",
    },
  },
};

const PodcastAffiliatesAsync = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.container)} />;
};

const PodcastAffiliatesAsyncMemo = memo(PodcastAffiliatesAsync);

const PodcastAffiliates = Loadable(() =>
  import(/* webpackChunkName: "PodcastAffiliates" */ "./PodcastAffiliates")
);

function PodcastAffiliatesAsyncLoading(props) {
  return <PodcastAffiliatesAsyncMemo />;
}

const PodcastAffiliatesContainer = (props) => {
  return (
    <PodcastAffiliates
      {...props}
      fallback={<PodcastAffiliatesAsyncLoading />}
    />
  );
};

export default PodcastAffiliatesContainer;
