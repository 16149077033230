import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";

import { TabsContext } from "components/Common/Tabs";
import ReviewsStub from "components/Entities/Stubs/ReviewsStub";

import ratingActions from "actions/rating";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useActionCreators from "hooks/useActionCreators";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const AboutReviews = (props) => {
  const { podcast, onTabChange } = props;

  const { openPodcastRatingModal } = useActionCreators(ratingActions);

  const reviewsOnTabChange = useCallback(
    () => onTabChange("reviews"),
    [onTabChange]
  );

  const onRatingClick = useCallback(
    () => openPodcastRatingModal(podcast.get("id"), { entity: podcast }),
    [openPodcastRatingModal, podcast]
  );

  const staticFilters = useMemo(
    () => ({
      podcast_id: {
        value: podcast && podcast.get("id"),
      },
    }),
    [podcast]
  );

  const listConfig = useMemo(
    () => ({
      loadingStyles: {
        noOverlay: {
          padding: "4rem 0",
          fontSize: "2rem",
          height: "15.3rem",

          [ScreenSizes.mdAndAbove]: {
            height: "18rem",
          },
        },
      },
    }),
    []
  );

  const { contentRef } = useContext(TabsContext);
  const { isWindowSizeOrLess } = useWindowSize();

  const mobile = isWindowSizeOrLess("medium");

  return (
    <ReviewsStub
      listKey={`podcastView/${podcast.get("id")}/aboutReviews`}
      listConfig={listConfig}
      title="Podcast Reviews"
      onTabChange={reviewsOnTabChange}
      staticFilters={staticFilters}
      entity={podcast}
      entity_type="podcast"
      linkLabel="View All Reviews"
      onRatingClick={onRatingClick}
      scrollRef={contentRef}
      viewAllLink={getPodcastUrl(podcast, "reviews")}
      viewAllTitle={`Podcast Reviews of ${podcast.get("title")}`}
      inSidebar={mobile}
      renderOnMount
    />
  );
};

AboutReviews.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  podcast: PropTypes.instanceOf(Map),
};
AboutReviews.defaultProps = {
  podcast: null,
};

export default AboutReviews;
