import { faSortDown } from "@fortawesome/free-solid-svg-icons/faSortDown";
import { faSortUp } from "@fortawesome/free-solid-svg-icons/faSortUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";

import colours from "../../../styles/colours";

import sendGAEvent from "utils/sendGAEvent";

import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";

const baseStyles = {
  faqText: {
    width: "95%",
    paddingLeft: "1.25rem",
    marginBottom: "1.875rem",
    display: "none",
    height: 0,
    paddingTop: "1rem",
    margin: 0,
    letterSpacing: 0,
    lineHeight: "1.75rem",
    textAlign: "left",
    fontWeight: 300,
  },
  faqOpenText: {
    display: "block",
    height: "fit-content",
  },
  faqParagraph: {
    margin: 0,
    letterSpacing: 0,
    lineHeight: "1.75rem",
    textAlign: "left",
    fontWeight: 300,
    overflowWrap: "anywhere",
  },
  faqInput: {
    display: "none",
  },
  faqLabel: {
    display: "block",
    position: "relative",
    overflow: "hidden",
    cursor: "pointer",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    backgroundColor: colours.white,
    lineHeight: 1,
    ":not(:last-child)": {
      borderBottom: `0.063rem solid ${colours.newLightGreyBox}`,
    },
  },
  faqHeading: {
    ...gStyles.avalonBold,
    margin: 0,
    fontSize: "0.875rem",
    display: "flex",
    gap: "0.5rem",
  },
  faqArrowHeadingWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 1.2rem",
    gap: "0.3rem",
  },

  checkedArrow: {
    transform: "rotate(135deg)",
  },
  faqCheckedLabel: {
    display: "block",
    background: "rgba(255,255,255,255)",
  },
  faqNotCheckedLabel: {
    display: "block",
  },
  svg: {
    marginTop: "-0.4rem",
  },
  icon: {
    fontSize: "0.813rem",
  },
};

const FAQSection = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const [open, setOpen] = useState(false);

  const { question, id, children, podcastId, eventProps, icon } = props;
  const podcast = usePodcastData(podcastId);

  const handleFAQListClick = useCallback(
    (type) => {
      setOpen(!open);

      sendGAEvent({
        action: `${type}ClickFAQListItem`,
        context: "InsightsTabFAQSectionItem",
        podcast_id: podcast?.get("id"),
        podcast_title: podcast?.get("title"),
        ...eventProps,
      });
    },
    [podcast, eventProps, open]
  );

  return (
    <label
      className={css(
        styles.faqLabel,
        open ? styles.faqCheckedLabel : styles.faqNotCheckedLabel
      )}
    >
      <input
        onChange={() => handleFAQListClick(open ? "close" : "open")}
        className={css(styles.faqInput)}
        id={id}
        type="checkbox"
        checked={open}
      />
      <div className={css(styles.faqArrowHeadingWrapper)}>
        <h3 className={css(styles.faqHeading)}>
          {icon && (
            <span>
              <FontAwesomeIcon className={css(styles.icon)} icon={icon} />
            </span>
          )}
          {question}
        </h3>
        <FontAwesomeIcon
          className={css(styles.svg)}
          icon={open ? faSortUp : faSortDown}
        />
      </div>
      <div className={css(styles.faqText, open && styles.faqOpenText)}>
        <p className={css(styles.faqParagraph)}>{children}</p>
      </div>
    </label>
  );
};

FAQSection.propTypes = {
  podcastId: PropTypes.number,
  question: PropTypes.string,
  id: PropTypes.string,
};
FAQSection.defaultProps = {
  podcastId: null,
  question: {},
  id: null,
};

export default FAQSection;
