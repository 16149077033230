import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback } from "react";

import Title from "components/Entities/Page/Title";

import NoStubItemsMessage from "./NoStubItemsMessage";

import authActions from "actions/auth";
import modalActions from "actions/modals";
import { selectAuthLoggedIn } from "selectors/auth";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";

const baseStyles = {
  noItemsLink: {
    ...gStyles.fontSemiBold,
    color: colours.primary,
    cursor: "pointer",
    border: "none",
    padding: 0,
  },
};

const NoListsMessage = (props) => {
  const { entity, entity_type, inSidebar, noItemsMessage, name, titleStyles } =
    props;

  const { styles } = useStyles(baseStyles, props);

  const isLoggedIn = useReduxState((state) => selectAuthLoggedIn(state), []);

  const { startAuthFlow, showModal } = useActionCreators({
    ...modalActions,
    ...authActions,
  });

  const handleNoItemsClick = useCallback(() => {
    if (isLoggedIn) {
      showModal("prompt", {
        promptType: "list",
        entity,
        entity_type,
        isEmpty: true,
      });
      sendGAEvent({
        action: `${entity_type}AboutPage-AddListClicked`,
        [`${entity_type}_id`]: entity.get("id"),
        [`${entity_type}_name`]:
          entity && (entity.get("title") || entity.get("name")),
      });
    } else {
      startAuthFlow(undefined, {
        entity,
        entity_type: "userlist",
        entity_type_name: "list",
        entityAction: "action",
        analyticsVariables: {
          triggered_by: "userlistsStub",
        },
      });
    }
  }, [isLoggedIn, showModal, startAuthFlow, entity, entity_type]);

  return (
    <Fragment>
      <Title
        title="Mentioned In These Lists"
        TitleComponent="h2"
        styles={titleStyles}
        inSidebar={inSidebar}
      />
      <NoStubItemsMessage
        message={
          noItemsMessage || (
            <span>
              There are no lists that include {`"${name}"`}. You can{" "}
              <span
                className={css(styles.noItemsLink)}
                onClick={handleNoItemsClick}
              >
                {` add this ${entity_type}`}
              </span>{" "}
              to a new or existing list.
            </span>
          )
        }
      />
    </Fragment>
  );
};

NoListsMessage.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  inSidebar: PropTypes.bool,
  noItemsMessage: PropTypes.node,
  title: PropTypes.string,
  titleStyles: PropTypes.object,
};

NoListsMessage.defaultProps = {
  entity: null,
  entity_type: "podcast",
  inSidebar: false,
  noItemsMessage: null,
  title: null,
  titleStyles: null,
};

export default memo(NoListsMessage);
