import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

import ScreenSizes from "styles/ScreenSizes";

const loadingStyles = {
  height: {
    height: "104rem",
    maxHeight: "104rem",
    minHeight: 0,

    [ScreenSizes.lgAndAbove]: {
      height: "120.8rem",
      maxHeight: "120.8rem",
    },
  },
};

const Creators = Loadable(() => import("./Creators"));

function CreatorsAsyncLoading(props) {
  return <LoadingOverlay height={"136rem"} styles={loadingStyles} />;
}

const CreatorsContainer = (props) => {
  return <Creators {...props} fallback={<CreatorsAsyncLoading />} />;
};

export default CreatorsContainer;
