import { episodeSchema, podcastSchema } from "api/schema/creator_podcast";
import { normalize } from "normalizr";

import { reviewSchema } from "../schema/review";
import client from "./client";

export function loadReviewsFilterList({
  sort_order,
  offset,
  count,
  filters,
  includes,
  sort_direction,
  options,
  omit_results,
  total_hits,
}) {
  return client
    .post("/list/review", {
      start: offset,
      count,
      sort_order,
      sort_direction,
      filters,
      includes,
      options,
      omit_results,
      total_hits,
    })
    .then((response) => {
      const { entities, additional_entities, ...data } = response.data;

      const normalised = normalize(
        { reviews: entities, ...additional_entities },
        {
          reviews: [reviewSchema],
          episodes: [episodeSchema],
          podcasts: [podcastSchema],
        }
      );

      const { result, ...rest } = normalised;

      return {
        result: result.reviews,
        ...rest,
        meta: { ...data },
      };
    });
}

export function loadSpecificReview({ review_id }) {
  return client
    .get(`/reviews/${review_id}`)
    .then((response) => normalize(response.data, reviewSchema));
}

export function saveReply({ entity_id, entity_type, ...props }) {
  return client
    .post(`/${entity_type}s/${entity_id}/replies`, props)
    .then((response) => normalize(response.data, reviewSchema));
}

export function deleteReply({ review_id, reply_id }) {
  return client
    .delete(`/reviews/${review_id}/replies/${reply_id}`)
    .then((response) => normalize(response.data, reviewSchema));
}
