import Loadable from "@loadable/component";
const AbstractMenu = Loadable(() =>
  import(/* webpackChunkName: "AbstractMenu" */ "./AbstractMenu")
);

function AbstractMenuLoading() {
  return null;
}

const AbstractMenuContainer = (props) => {
  return <AbstractMenu {...props} fallback={<AbstractMenuLoading />} />;
};

export default AbstractMenuContainer;
