import Loadable from "@loadable/component";
const BetaLogo = Loadable(() =>
  import(/* webpackChunkName: "BetaLogo" */ "./BetaLogo")
);

function BetaLogoAsyncLoading() {
  return null;
}

const BetaLogoContainer = (props) => {
  return <BetaLogo {...props} fallback={<BetaLogoAsyncLoading />} />;
};

export default BetaLogoContainer;
