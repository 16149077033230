import { format } from "date-fns/format";
import { List, Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";
import Helmet from "react-helmet-async";

import { getBaseUrl } from "constants/base";
import { SHORT_DATE_FORMAT } from "constants/date";
import getCreatorDisplayName from "utils/entity/getCreatorDisplayName";
import { getRawEpisodeImageUrl } from "utils/entity/getEpisodeImageUrl";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import { generateMetaArray } from "utils/meta";
import { truncateString } from "utils/truncate";
import { getCreatorUrl } from "utils/url/creatorUrls";

export function getEpisodeMetaDescription(episode, podcast) {
  return truncateString(
    `${episode.get("title")} from ${podcast.get(
      "title"
    )} on Podchaser, aired ${format(
      episode.get("air_date"),
      SHORT_DATE_FORMAT
    )}.
${episode.get("description")}`,
    300
  );
}

const EpisodeViewStructuredData = ({ episode, podcast, categories }) => {
  const { links, script, title, meta } = useMemo(() => {
    const url = `${getBaseUrl()}${getEpisodeUrl(episode, { podcast })}`;

    const aggregateRating = {
      "@type": "AggregateRating",
      ratingValue: episode.get("rating"),
    };

    if (episode.get("rating_count") > 0) {
      aggregateRating.ratingCount = episode.get("rating_count");
    }
    if (episode.get("review_count") > 0) {
      aggregateRating.reviewCount = episode.get("review_count");
    }

    const podcastUrl = getBaseUrl() + getPodcastUrl(podcast);

    const roleMap = {
      host: "actor",
      voiceActor: "actor",
      editor: "director",
      producer: "director",
      composer: "musicBy",
    };

    const creatorsJson = episode
      .get("creator_summary", List())
      .reduce((agg, next) => {
        // TODO: need to consolidate how we pass back the creatory summary, either IDs or summarized data
        if (!(next instanceof Map)) {
          return agg;
        }
        let roleCode = "host";
        if (!(next instanceof Map)) {
          return agg;
        }
        if (next.has("roles") && next.get("roles").size > 0) {
          roleCode = next.getIn(["roles", 0, "role"]);
        }
        agg[roleMap[roleCode]] = [
          ...(agg[roleMap[roleCode]] || []),
          {
            "@type": "Person",
            "@id": getBaseUrl() + getCreatorUrl(next),
            name: getCreatorDisplayName(next),
          },
        ];

        return agg;
      }, {});

    const jsonLd = {
      "@context": "http://schema.org",
      "@type": "PodcastEpisode",
      "@id": url,
      accessMode: "auditory",
      genre:
        categories && categories.size > 0 && categories.first().get("text"),
      // isFamilyFriendly: '', // TODO: use explicit value to determine this
      description: getEpisodeMetaDescription(episode, podcast),
      identifier: episode.get("guid"), // maybe make this our canonical URL?
      image: getRawEpisodeImageUrl(podcast, episode),
      name: episode.get("title"),
      url,
      partOfSeries: {
        "@type": "PodcastSeries",
        "@id": podcastUrl,
        name: podcast.get("title"),
        url: podcastUrl,
      },
      ...creatorsJson,
    };

    if (episode.get("episode_num")) {
      jsonLd.episodeNumber = episode.get("episode_num");
    }

    if (episode.get("rating_count") > 0) {
      jsonLd.aggregateRating = aggregateRating;
    }

    let episodeUrl = "";

    if (podcast && episode) {
      episodeUrl = getEpisodeUrl(episode, { podcast });
    }

    const canonicalUrl = `${getBaseUrl()}${episodeUrl}`;

    return {
      title: `${episode.get("title")} by ${podcast.get("title")}`,
      links: [
        { rel: "canonical", href: canonicalUrl },
        {
          rel: "alternate",
          type: "application/json+oembed",
          href: `https://oembed.podchaser.com/embed?url=${encodeURIComponent(
            `episodes/${episode.get("id")}`
          )}`,
        },
      ],
      script: [
        {
          type: "application/ld+json",
          innerHTML: JSON.stringify(jsonLd),
        },
      ],
      meta: generateMetaArray(
        {
          title: `${episode.get("title")} by ${podcast.get("title")}`,
          url: canonicalUrl,
          image: { url: getRawEpisodeImageUrl(podcast, episode) },
          description: getEpisodeMetaDescription(episode, podcast),
        },
        [
          // { property: 'og:title', content: episode.get('title') },
          /* {
          property: 'og:url',
          content: canonicalUrl
        }, */
          // { property: 'og:image', content: episodeImageUrl },
          // { property: 'og:description', content: episode.get('description') },
          // { property: 'description', content: episode.get('description') },
          // { property: 'twitter:title', content: episode.get('title') },
          // { property: 'twitter:description', content: episode.get('description') },
          // { property: 'twitter:image', content: episodeImageUrl },
          // { property: 'twitter:image:src', content: episodeImageUrl }
        ]
      ),
    };
  }, [episode, podcast, categories]);

  return <Helmet script={script} title={title} meta={meta} link={links} />;
};

EpisodeViewStructuredData.propTypes = {
  episode: PropTypes.instanceOf(Map).isRequired,
  podcast: PropTypes.instanceOf(Map).isRequired,
  categories: PropTypes.instanceOf(List),
};

export default memo(EpisodeViewStructuredData);
