import * as sortConstants from "constants/sort";
import commaList from "utils/text/commaList";

export default function getSortTitle({
  sort,
  sortDirection,
  ignoreKeys,
  overrideSortTitles,
}) {
  if (ignoreKeys && ignoreKeys.indexOf(sort) > -1) {
    return "";
  }
  if (overrideSortTitles && sort && overrideSortTitles[sort]) {
    return typeof overrideSortTitles[sort] === "object"
      ? overrideSortTitles[sort][sortDirection || "desc"]
      : overrideSortTitles[sort];
  }
  switch (sort) {
    case sortConstants.SORT_ORDER_RECENT:
    case sortConstants.SORT_ORDER_RECENT_WEIGHTED:
      return "Recent";
    case sortConstants.SORT_ORDER_RANKING:
      return sortDirection && sortDirection === "asc"
        ? "Lowest Rated"
        : "Top Rated"; // 'Best'; <- Moved to overrideSortTitles
    case sortConstants.SORT_ORDER_TRENDING:
      return "Trending";
    case sortConstants.SORT_ORDER_POWER_SCORE:
      return "By Power Score - ";
    case sortConstants.SORT_ORDER_POPULAR:
      return sortDirection && sortDirection === "asc" ? "Unpopular" : "Popular";
    case sortConstants.SORT_ORDER_CURATED:
      return "Best";
    case sortConstants.SORT_ORDER_TOP:
      return "Top";
    case sortConstants.SORT_ORDER_NEW:
      return "New";
    default:
      return "";
  }
}

const PLUS_FILTER_VALUES = [
  "claimed_podcast",
  "category",
  "top_countries",
  "language",
  "gender_bias",
  "episode_frequency",
  "podcast_status",
  "number_of_episodes",
  "average_episode_length",
  "hosting_provider",
  "power_score",
  "episode_length",
];

export const getFilterTitles = (filters, availableFilters) => {
  if (filters && filters.size > 0 && availableFilters) {
    const filterNames = filters
      .keySeq()
      .toList()
      .reduce((namesWithValues, filterKey) => {
        const filter = filters.get(filterKey);
        const availableFilter =
          filter && filter.has("title")
            ? filter
            : availableFilters.get(filterKey);

        if (!availableFilter || !filter) {
          return namesWithValues;
        }

        const filterTitle = availableFilter.get("title");
        // if there are more than 1 filter, show the filter names, if theres only 1 filter and it has under 4 options
        // selected then show the individual values
        if (filters.size === 1 && filter.get("value").size < 4) {
          if (PLUS_FILTER_VALUES.some((key) => key === filterKey)) {
            const filterValuesString = filter
              .get("value")
              .map((value) => {
                const options = availableFilter.get("options");
                const foundOption =
                  options && options.find((op) => op.get("value") === value);

                if (foundOption) {
                  return !isNaN(foundOption.get("value"))
                    ? // if the value is a number then show the title in quotes
                      // so its clear the values aren't being added together
                      `"${foundOption.get("title")}"`
                    : foundOption.get("title");
                }

                return null;
              })
              .filter((valTitle) => !!valTitle)
              .join(" + ");

            return [
              !!filterValuesString
                ? `${filterTitle} (${filterValuesString})`
                : filterTitle,
            ];
          }
        }

        return [
          ...namesWithValues,
          availableFilters.getIn([filterKey, "title"]),
        ];
      }, [])
      .filter((name) => !!name);

    return commaList(filterNames, null, { commaFunc: (i, str) => str }).join(
      ""
    );
  }

  return "";
};
