import Loadable from "@loadable/component";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  loadingContainer: {
    minHeight: "4.1rem",

    [ScreenSizes.mdAndAbove]: {
      minHeight: "1.625rem",
    },
  },
};

const GoldingsAd = Loadable(() =>
  import(/* webpackChunkName: "GoldingsAd" */ "./GoldingsAd")
);

function GoldingsAdAdLoading() {
  const { styles, css } = useStyles(baseStyles);

  return <div className={css(styles.loadingContainer)} />;
}

const GoldingsAdContainer = (props) => {
  return <GoldingsAd {...props} fallback={<GoldingsAdAdLoading />} />;
};

export default GoldingsAdContainer;
