import Title from "components/Entities/Page/TitleAsync";

import { primaryTitleStyles } from "./InsightsStyles";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const baseStyles = {
  insights: {
    width: "100%",

    minHeight: "365rem",

    [ScreenSizes.lgAndAbove]: {
      minHeight: "280rem",
    },
  },
  mainContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "2rem",

    [createBreakpoint({ min: 1170 })]: {
      gridTemplateColumns: "9.688rem 1fr",
    },
  },
  reachContainer: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,

    marginBottom: "0.5rem",

    [createBreakpoint({ min: 1170 })]: {
      height: "11.25rem",
      marginBottom: "3rem",
    },
  },
  audienceContainer: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,

    marginBottom: "0.5rem",

    [createBreakpoint({ min: 1170 })]: {
      height: "45rem",
      marginBottom: "3rem",
    },
  },
  navColumn: {
    display: "none",

    [createBreakpoint({ min: 1170 })]: {
      display: "block",

      ...gStyles.skeletonPlaceholder,
      borderRadius: 8,
      color: colours.grey,
      height: "28rem",
    },
  },
};

function InsightsLoading(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.insights)} data-id="insights-section">
      <Title
        title="Podcast Insights"
        noTopPadding
        TitleComponent="h1"
        styles={primaryTitleStyles}
        primary
        key="title"
      />
      <div className={css(styles.mainContainer)}>
        <div className={css(styles.navColumn)} />
        <div>
          <div className={css(styles.reachContainer)} />
          <div className={css(styles.audienceContainer)} />
        </div>
      </div>
    </div>
  );
}

export default InsightsLoading;
