function padTime(num) {
  return `0${num.toFixed()}`.slice(-2);
}

export function secondsToHoursMinSecs(secs, formatTime = null) {
  let minutes = Math.floor(secs / 60);
  const seconds = secs % 60;
  const hours = Math.floor(minutes / 60);
  minutes %= 60;

  if (formatTime) {
    return formatTime({ hours, minutes, seconds });
  }

  return `${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`;
}

export function secondsToDaysHoursMinSecs(secs, formatTime = null) {
  let minutes = Math.floor(secs / 60);
  let hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const seconds = secs % 60;
  minutes %= 60;
  hours %= 24;

  if (formatTime) {
    return formatTime({ days, hours, minutes, seconds });
  }

  return `${days}:${padTime(hours)}:${padTime(minutes)}:${padTime(seconds)}`;
}

export function secondsToDaysHoursMinSecsChars(secs) {
  return secondsToDaysHoursMinSecs(secs, (values) => {
    let time = "";

    if (values.days > 0) {
      time = `${time}${values.days}d `;
    }
    if (values.hours > 0) {
      time = `${time}${values.hours}h `;
    }
    if (values.minutes > 0) {
      time = `${time}${values.minutes}m `;
    }
    if (values.seconds > 0) {
      time = `${time}${values.seconds}s `;
    }

    return time;
  });
}
