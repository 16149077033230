import { Map } from "immutable";
import PropTypes from "prop-types";

import DirectRecommendationsPanel from "components/Entities/Page/DirectRecommendationsPanelAsync";

import getEpisodeName from "utils/entity/getEpisodeName";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";

const DirectEpisodeRecommendations = (props) => {
  const { episode } = props;

  return (
    <DirectRecommendationsPanel
      {...props}
      entity_type="episode"
      entity={episode}
      getName={getEpisodeName}
      getEntityUrl={getEpisodeUrl}
    />
  );
};

DirectEpisodeRecommendations.propTypes = {
  episode: PropTypes.instanceOf(Map).isRequired,
};

export default DirectEpisodeRecommendations;
