import { useContext } from "react";

import RequestContext from "pages/RequestContext";

import About from "./About";
import AboutLoadingSkeleton from "./AboutLoadingSkeleton";

import { useLoggedIn } from "hooks/useLoggedInUser";

function AboutContainer(props) {
  const { podcast } = props;

  const { server, hasAuthCookieDefined } = useContext(RequestContext);
  const isLoggedIn = useLoggedIn();

  if (!isLoggedIn && hasAuthCookieDefined && server) {
    return <AboutLoadingSkeleton />;
  }

  if (!podcast?.get("id")) {
    return <AboutLoadingSkeleton />;
  }

  return <About {...props} />;
}

export default AboutContainer;
