import PropTypes from "prop-types";
import { useMemo } from "react";

import SocialLinksPanel from "components/Entities/Page/SocialLinksPanelAsync";

import useEpisodeData from "hooks/useEpisodeData";

const SocialLinks = ({ episodeId, noPanel }) => {
  const episode = useEpisodeData(episodeId);

  const links = useMemo(() => {
    const innerLinks = {};

    if (episode.get("episode_url")) {
      innerLinks.web = {
        url: episode.get("episode_url"),
        title: "Official Episode Page",
      };
    }
    if (episode.get("audio_url")) {
      innerLinks.download = {
        url: episode.get("audio_url"),
        title: "Download Audio File",
      };
    }
    if (episode.get("feed_url")) {
      innerLinks.feed = { url: episode.get("feed_url") };
    }
    if (episode.get("itunes_id")) {
      innerLinks.itunes = { id: episode.get("itunes_id") };
    }

    return innerLinks;
  }, [episode]);

  if (Object.keys(links).length > 0) {
    return (
      <SocialLinksPanel links={links} noPanel={noPanel} title="Want More?" />
    );
  }

  return null;
};

SocialLinks.propTypes = {
  episodeId: PropTypes.number.isRequired,
  noPanel: PropTypes.bool,
};

SocialLinks.defaultProps = {
  noPanel: false,
};

export default SocialLinks;
