import { Redirect, useLocation, useRouteMatch } from "react-router-dom";

/*
 * Executes a function when the component mounts if the testFunc returns true
 * */
const canonicalRedirect = (getUrlFunc) => (ComposedComponent) => (props) => {
  // eslint-disable-next-line react/prop-types
  const location = useLocation();
  const match = useRouteMatch();
  const canonicalUrl = getUrlFunc({ ...props, location, match });

  // eslint-disable-next-line react/prop-types
  if (match?.url === canonicalUrl) {
    return <ComposedComponent {...props} />;
  }
  return <Redirect to={canonicalUrl} />;
};

export default canonicalRedirect;
