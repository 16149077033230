import Loadable from "@loadable/component";

import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  loadingContainer: {
    minHeight: "4.1rem",

    [ScreenSizes.mdAndAbove]: {
      minHeight: "1.625rem",
    },
  },
};

const ColdCaseAd = Loadable(() =>
  import(/* webpackChunkName: "ColdCaseAd" */ "./ColdCaseAd")
);

function ColdCaseAdAdLoading() {
  const { styles, css } = useStyles(baseStyles);

  return <div className={css(styles.loadingContainer)} />;
}

const ColdCaseAdContainer = (props) => {
  return <ColdCaseAd {...props} fallback={<ColdCaseAdAdLoading />} />;
};

export default ColdCaseAdContainer;
