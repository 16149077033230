import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { format } from "date-fns/format";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useMemo, useState } from "react";
import Helmet from "react-helmet-async";
import { useLocation } from "react-router-dom";

import PlayButton from "components/Buttons/PlayButtonAsync";
import StandardButton from "components/Buttons/StandardButton";
import EntityLink from "components/Entities/EntityLink";
import PageHeader from "components/Entities/Page/PageHeader";
import RatingText from "components/Entities/Page/RatingTextAsync";
import ClaimedBadge from "components/Podcast/ClaimedBadgeAsync";
import EpisodeTypeBadge from "components/Podcast/EpisodeTypeBadgeContainer";
import PodcastButtons from "components/Podcast/PodcastButtonsAsync";
import RatingWithHover from "components/Rating/RatingWithHoverAsync";

import entityPageStyles from "../../styles/entityPageStyles";
import generateTransition from "../../utils/generateTransition";
import accessibleClickProps from "../../utils/misc/accessibleClickProps";
import EpisodeNavBar from "./EpisodeNavBar";

import ratingActions from "actions/rating";
import { MISSING_IMAGE_URL } from "constants/base";
import { SHORT_DATE_FORMAT } from "constants/date";
import cachedImage from "utils/entity/cachedImage";
import getEntityImageTitle, {
  getEntityImageAlt,
} from "utils/entity/getEntityImageTitle";
import { episodeVerbLabels } from "utils/rating";
import { matchesRouteWithParams } from "utils/url";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...entityPageStyles,
  mediumNameContainer: {
    ...entityPageStyles.mediumNameContainer,
    marginTop: "1.5rem",
  },
  smallHeaderName: {
    ...entityPageStyles.smallHeaderName,
    marginBottom: "0.4rem",
    ...gStyles.textEllipsis,
    cursor: "pointer",
  },
  ratingContainer: {
    ...entityPageStyles.ratingContainer,
    maxWidth: 300,
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: "2.5rem",
    marginTop: "1.8rem",

    [ScreenSizes.lgAndAbove]: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: "0.5rem",
      marginRight: 0,
      maxWidth: 250,
    },
    [ScreenSizes.xlAndAbove]: {
      marginLeft: "2rem",
      maxWidth: 300,
    },
  },
  mediumRatingTitle: {
    color: colours.white,
    position: "relative",
  },
  primaryActionButtonContainer: {
    marginBottom: "0.5rem",
  },
  primaryActionButtonIcon: {
    marginRight: "0.5em",
  },
  mediumButtons: {
    display: "flex",
    flexDirection: "column",
    minWidth: "calc(100vw - 4.25rem)",
    marginTop: "-22px",
  },
  divider: {
    marginTop: "2rem",
    marginBottom: ".2rem",
    borderTop: "1px solid #F3F3F3",
  },
  backgroundImage: {
    position: "absolute",
    left: 0,
    right: 0,
    backgroundPosition: "center",
    opacity: ".07",
    filter: "blur(6px) saturate(0.3)",
    top: "-200px",
    bottom: "-200px",
    backgroundSize: "cover",
    transform: "rotate(-10deg)",
  },
  image: {
    width: "100%",
    height: "auto",
    borderRadius: 8,

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 8,
    },
  },
  smallImage: {
    borderRadius: 6,

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 8,
    },
  },
  mainHeaderImage: {
    position: "relative",

    width: "8rem",
    height: "8rem",

    margin: "0 auto",

    border: `3px solid ${colours.white}`,
    boxShadow: "0 1px 4px 0 rgba(0,0,0,0.2)",
    borderRadius: 8,

    zIndex: 10,
    cursor: "pointer",

    transition: generateTransition({
      targets: ["width", "height"],
      speed: "150ms",
    }),

    [ScreenSizes.mdAndAbove]: {
      border: "unset",
    },

    [ScreenSizes.lgAndAbove]: {
      width: "7rem",
      height: "7rem",

      margin: 0,
      borderWidth: 4,
    },
  },
  smallMainHeaderImage: {
    position: "relative",
    top: 0,

    borderRadius: 6,
    border: "unset",

    height: "3.5rem",
    width: "3.5rem",

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 8,
    },
  },
};

const headerStyles = {
  ...entityPageStyles,
  mainHeaderSpan: {
    borderRadius: 1,
  },
  largeContainer: {
    alignItems: "flex-start",
    top: 0,
  },
  infoContainer: {
    marginLeft: "2rem",

    [ScreenSizes.xlAndAbove]: {
      marginLeft: "2.5rem",
    },
  },
  smallMainHeaderImage: {
    borderRadius: 0,
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.smAndAbove]: {
      display: "block",
    },

    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
  tabletContainer: {
    display: "none",

    [ScreenSizes.smAndAbove]: {
      display: "none",
    },
    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
};

const aboutPageHeaderStyles = {
  ...headerStyles,
  tabletContainer: {
    display: "block",

    [ScreenSizes.smAndAbove]: {
      display: "block",
    },

    [ScreenSizes.lgAndAbove]: {
      display: "none",
    },
  },
  mobileContainer: {
    display: "none",

    [ScreenSizes.smAndAbove]: {
      display: "none",
    },
  },
};

const imageToggledHeaderStyles = {
  mainHeaderImage: {
    width: "12rem",
    height: "12rem",

    [ScreenSizes.smAndMd]: {
      width: "14rem",
      height: "14rem",
    },
  },
};

const mediumContainerStyles = {
  container: {
    height: "auto",
  },
};

const largeContainerStyles = {
  container: {
    paddingTop: 0,
  },
  contentContainer: {
    paddingTop: "2.938rem",
    paddingBottom: "3.25rem",
  },
};

const mediumContentStyles = {
  contentContainer: {
    paddingTop: 0,

    [ScreenSizes.smAndAbove]: {
      paddingTop: 0,
    },
    [ScreenSizes.mdAndAbove]: {
      paddingTop: 0,
    },
  },
};

const ratingStyles = {
  starEmpty: {
    color: "rgba(0,0,0,0.3)",
  },
};

const rateEpisodeButtonStyles = {
  button: {
    height: "3em",
  },
};

const defaultOverrideStyles = {
  contentContainer: {
    paddingTop: "2rem",

    [ScreenSizes.mdOnly]: {
      paddingTop: "2rem",
    },
    [ScreenSizes.lgOnly]: {
      paddingTop: "2rem",
      paddingBottom: "2rem",
    },
    [ScreenSizes.xlAndAbove]: {
      paddingTop: "2rem",
      paddingBottom: "2rem",
    },
  },
};

const smallOverrideStyles = {
  contentContainer: {
    padding: "0 1rem",

    [ScreenSizes.mdOnly]: {
      padding: "0 1rem",
    },
  },
};

const episodeTypeBadgeStyles = {
  outer: {
    marginRight: "0.75em",
  },
};

function getImageUrl(imageUrl, mainImageSize) {
  return cachedImage(imageUrl, mainImageSize);
}

function getBackgroundImageStyle(imageUrl) {
  return {
    backgroundImage: {
      backgroundImage: `url(${imageUrl})`,
    },
  };
}

const MAIN_SIZE_IMAGE = 256;
const BACKGROUND_SIZE_IMAGE = 64;

const SIZE_SMALL = "small";

const EpisodeHeader = (props) => {
  const { entity: episode, onTabChange, podcast } = props;

  const location = useLocation();

  const src = episode.get("image_url") || podcast.get("image_url");
  const isMissingImage = src === MISSING_IMAGE_URL;

  const backgroundHeaderImageUrl = isMissingImage
    ? MISSING_IMAGE_URL
    : getImageUrl(src, BACKGROUND_SIZE_IMAGE);

  const episodeHeaderImageUrl = isMissingImage
    ? MISSING_IMAGE_URL
    : getImageUrl(src, MAIN_SIZE_IMAGE);

  const episodeHeaderImageUrlRetina = isMissingImage
    ? MISSING_IMAGE_URL
    : getImageUrl(src, MAIN_SIZE_IMAGE * 2);

  const { styles } = useStyles(
    [baseStyles, getBackgroundImageStyle(backgroundHeaderImageUrl)],
    props
  );

  const { isWindowSizeOrLess, isWindowSizeOrMore } = useWindowSize();

  const isMedium = isWindowSizeOrLess("medium");
  const isTiny = isWindowSizeOrLess("tiny");
  const isMediumOrMore = isWindowSizeOrMore("medium");

  const { openEpisodeRatingModal } = useActionCreators(ratingActions);

  const isClaimed = episode.getIn(["podcast", "is_claimed"]);

  const userRatingData = episode.getIn(["user_data", "rating"]);
  const userRating = userRatingData && userRatingData.get("rating");
  const userRatingSaving =
    userRatingData && userRatingData.get("rating_saving");

  const isOnAboutTab = useMemo(
    () =>
      matchesRouteWithParams(
        location.pathname,
        "/podcasts/:id/episodes/:episode_id/about"
      ) ||
      matchesRouteWithParams(
        location.pathname,
        "/podcasts/:id/episodes/:episode_id",
        true
      ),
    [location]
  );
  const HeadingComponent = isOnAboutTab ? "h1" : "h3";
  const ratingFontSize = useMemo(() => {
    if (isTiny) {
      return "1.3rem";
    }
    if (isMedium) {
      return "2.1rem";
    }

    return "1.4rem";
  }, [isTiny, isMedium]);

  const [imageToggled, setImageToggled] = useState(false);
  const [isLowResolutionImageLoaded, setIsLowResolutionImageLoaded] =
    useState(false);

  const handleRatingClick = useCallback(
    () => openEpisodeRatingModal(episode.get("id"), { entity: episode }),
    [openEpisodeRatingModal, episode]
  );
  const handleAboutTabChange = useCallback(
    () => onTabChange("about"),
    [onTabChange]
  );
  const handleMainImageClick = useCallback(() => {
    setImageToggled(!imageToggled);
  }, [imageToggled]);

  const { yourRatingButtonStyles, ratingBackgroundColor } = useMemo(() => {
    const customButtonColor = userRating ? "#fff" : null;
    const customButtonBackgroundColor =
      userRating && colours.stars[userRating]
        ? colours.stars[userRating]
        : null;

    return {
      ratingBackgroundColor: customButtonBackgroundColor ? null : "white",
      yourRatingButtonStyles: {
        button: {
          ...(customButtonBackgroundColor && {
            color: customButtonColor,
            backgroundColor: customButtonBackgroundColor,
          }),
          height: "3em",
        },
      },
    };
  }, [userRating]);

  const renderRating = useCallback(
    (medium = false) => (
      <div
        className={css(styles.ratingContainer)}
        {...accessibleClickProps(handleRatingClick)}
      >
        <div className={css(styles.ratingStarsContainer)}>
          <RatingWithHover
            id={episode.get("id")}
            fontSize={ratingFontSize}
            rating={episode.get("rating")}
            styles={ratingStyles}
            entity={episode}
            ariaLabelFunc={({ rating }) =>
              `Community Rating: ${Math.round(rating * 100) / 100} out of 5`
            }
            showTooltip
          />
        </div>
        <div
          className={css(
            styles.ratingTitle,
            medium && styles.mediumRatingTitle
          )}
        >
          <RatingText
            entityName="episode"
            saving={userRatingSaving}
            ratedByUser={userRatingData}
            userRating={userRating}
            ratingCount={episode.get("rating_count")}
            friendRatingCount={episode.get("friend_rating_count")}
            noRatingMessage="Be the first to rate this episode"
          />
        </div>
      </div>
    ),
    [
      episode,
      handleRatingClick,
      ratingFontSize,
      userRating,
      userRatingData,
      userRatingSaving,
      styles.mediumRatingTitle,
      styles.ratingContainer,
      styles.ratingStarsContainer,
      styles.ratingTitle,
    ]
  );

  const renderPodcastName = useCallback(
    (medium = false) => (
      <HeadingComponent
        className={css(styles.name, medium && styles.mediumName)}
        title={episode.get("title")}
      >
        {episode.get("title")}
      </HeadingComponent>
    ),
    [episode, styles.mediumName, styles.name]
  );

  const renderSmallHeaderContent = useCallback(
    () => (
      <Fragment>
        <HeadingComponent
          className={css(styles.smallHeaderName)}
          onClick={handleAboutTabChange}
        >
          {episode.get("title")}
          {isClaimed && (
            <ClaimedBadge
              showLabel={isMediumOrMore}
              padded={isMediumOrMore}
              showTooltip={isMediumOrMore}
            />
          )}
        </HeadingComponent>
      </Fragment>
    ),
    [
      episode,
      handleAboutTabChange,
      isClaimed,
      isMediumOrMore,
      styles.smallHeaderName,
    ]
  );

  const renderMediumHeaderContent = useCallback(
    () => (
      <Fragment>
        <div className={css(styles.mediumNameContainer)}>
          {renderPodcastName(true)}
        </div>
        <div className={css(styles.subtitle)}>
          <div className={css(styles.description)}>
            <EpisodeTypeBadge
              episode={episode}
              styles={episodeTypeBadgeStyles}
            />
            {format(episode.get("air_date"), SHORT_DATE_FORMAT)}
          </div>
        </div>
        {renderRating(true)}
      </Fragment>
    ),
    [
      episode,
      renderPodcastName,
      renderRating,
      styles.subtitle,
      styles.description,
      styles.mediumNameContainer,
    ]
  );

  const youRatedString = useMemo(
    () =>
      userRating &&
      `You ${episodeVerbLabels[userRating].toLowerCase()} this episode`,
    [userRating]
  );

  const renderMediumContent = useCallback(
    () => (
      <Fragment>
        <div className={css(styles.mediumButtons)}>
          <div className={css(styles.primaryActionButtonContainer)}>
            <PlayButton
              entity_type="episode"
              entity_id={episode.get("id")}
              noAnimation
              render={(playProps) => {
                delete playProps.isPlaying;
                delete playProps.iconSpin;
                delete playProps.isPlayed;

                return (
                  <StandardButton
                    {...playProps}
                    variation="pink"
                    fontSize="1rem"
                    label={
                      <span>
                        <FontAwesomeIcon
                          className={css(styles.primaryActionButtonIcon)}
                          spin={playProps.iconSpin}
                          icon={playProps.icon}
                        />{" "}
                        {playProps.label}
                      </span>
                    }
                    flat
                  />
                );
              }}
            />
          </div>

          {userRating ? (
            <StandardButton
              variation={ratingBackgroundColor}
              onClick={handleRatingClick}
              fontSize=".8125rem"
              label={youRatedString}
              flat
              customStyles={yourRatingButtonStyles}
            />
          ) : (
            <StandardButton
              variation={ratingBackgroundColor}
              label="Rate Episode"
              onClick={handleRatingClick}
              fontSize=".8125rem"
              flat
              customStyles={rateEpisodeButtonStyles}
              outerComponent="div"
            />
          )}
          <PodcastButtons
            entity_type="episode"
            entity={episode}
            podcast={podcast}
          />
        </div>
      </Fragment>
    ),
    [
      episode,
      podcast,
      handleRatingClick,
      ratingBackgroundColor,
      userRating,
      youRatedString,
      yourRatingButtonStyles,
      styles.mediumButtons,
      styles.primaryActionButtonIcon,
      styles.primaryActionButtonContainer,
    ]
  );

  const renderLargeContent = useCallback(
    () => (
      <div className={css(styles.headerContainer)}>
        <div className={css(styles.mainTextContainer)}>
          {renderPodcastName()}
          <div className={css(styles.subtitle)}>
            <div className={css(styles.description)}>
              <EpisodeTypeBadge
                episode={episode}
                styles={episodeTypeBadgeStyles}
              />
              Released {format(episode.get("air_date"), SHORT_DATE_FORMAT)}
            </div>
          </div>
        </div>
        {renderRating()}
      </div>
    ),
    [
      episode,
      renderPodcastName,
      renderRating,
      styles.description,
      styles.headerContainer,
      styles.mainTextContainer,
      styles.subtitle,
    ]
  );

  const renderBackgroundImage = useCallback(
    () => <div className={css(styles.backgroundImage)} />,
    [styles.backgroundImage]
  );

  const { size, containerStyles, overrideStyles } = useMemo(() => {
    let pageSize = "large";
    let contStyles = null;
    let overStyles = null;

    if (isMedium) {
      if (isOnAboutTab) {
        pageSize = "medium";
        contStyles = mediumContainerStyles;
        overStyles = defaultOverrideStyles;
      } else {
        pageSize = "small";
        overStyles = smallOverrideStyles;
      }
    } else {
      contStyles = largeContainerStyles;
      overStyles = defaultOverrideStyles;
    }

    return {
      size: pageSize,
      containerStyles: contStyles,
      overrideStyles: overStyles,
    };
  }, [isMedium, isOnAboutTab]);

  const { alt, title } = useMemo(() => {
    return {
      alt: getEntityImageAlt(episode, "episode"),
      title: getEntityImageTitle(episode, "episode"),
    };
  }, [episode]);

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="image"
          href={backgroundHeaderImageUrl}
          fetchpriority="high"
        />
      </Helmet>
      {size !== "small" && (
        <EpisodeNavBar
          episodeId={episode.get("id")}
          podcastId={podcast.get("id")}
        />
      )}
      <PageHeader
        containerStyles={containerStyles}
        entity={episode}
        entity_type="episode"
        mediumContentStyles={mediumContentStyles}
        onSmallLayoutBackClick={handleAboutTabChange}
        overrideStyles={overrideStyles}
        renderBackgroundImage={renderBackgroundImage}
        renderContent={renderLargeContent}
        renderMediumContent={renderMediumContent}
        renderMediumHeaderContent={renderMediumHeaderContent}
        renderSmallHeaderContent={renderSmallHeaderContent}
        renderProfileImage={() => {
          return (
            <div
              className={css(
                styles.mainHeaderImage,
                size === SIZE_SMALL && styles.smallMainHeaderImage
              )}
              onClick={handleMainImageClick}
            >
              <EntityLink
                entity_type="episode"
                entity={episode}
                block
                expand
                isImage
              >
                {isLowResolutionImageLoaded ? (
                  <img
                    src={backgroundHeaderImageUrl}
                    onLoad={() => {
                      setIsLowResolutionImageLoaded(true);
                    }}
                    className={css(
                      styles.image,
                      size === SIZE_SMALL && styles.smallImage
                    )}
                    alt={alt}
                    title={title}
                    width={60}
                    height={60}
                    fetchpriority="high"
                  />
                ) : (
                  <picture>
                    <source
                      media="(min-width: 640px)"
                      srcSet={`${episodeHeaderImageUrl} 1x, ${episodeHeaderImageUrlRetina} 2x`}
                      className={css(
                        styles.image,
                        size === SIZE_SMALL && styles.smallImage
                      )}
                      alt={alt}
                      title={title}
                      width={60}
                      height={60}
                    />
                    <img
                      src={episodeHeaderImageUrl}
                      className={css(
                        styles.image,
                        size === SIZE_SMALL && styles.smallImage
                      )}
                      alt={alt}
                      title={title}
                      width={60}
                      height={60}
                      fetchpriority="high"
                    />
                  </picture>
                )}
              </EntityLink>
            </div>
          );
        }}
        siteHeaderPadding={false}
        size={size}
        styles={[
          isOnAboutTab ? aboutPageHeaderStyles : headerStyles,
          imageToggled && imageToggledHeaderStyles,
        ]}
        updateProps={episode}
      />
    </>
  );
};

EpisodeHeader.propTypes = {
  entity: PropTypes.instanceOf(Map).isRequired,
  podcast: PropTypes.instanceOf(Map).isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default EpisodeHeader;
