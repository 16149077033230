import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const RatingStars = Loadable(() => import("./RatingStars"));

function RatingStarsAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const RatingStarsContainer = (props) => {
  return <RatingStars {...props} fallback={<RatingStarsAsyncLoading />} />;
};

export default RatingStarsContainer;
