import Loadable from "@loadable/component";

import AsyncLoadingEntityCard from "../AsyncLoadingEntityCard";

const PodcastFollowCard = Loadable(() => import("./PodcastFollowCard"));

function ProAsyncLoading(props) {
  return <AsyncLoadingEntityCard {...props} />;
}

const PodcastFollowCardContainer = (props) => {
  return <PodcastFollowCard {...props} fallback={<ProAsyncLoading />} />;
};

export default PodcastFollowCardContainer;
