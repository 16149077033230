import { Map } from "immutable";
import PropTypes from "prop-types";
import { useEffect } from "react";

import PageSidebarPanel from "components/Entities/Page/PageSidebarPanel";
import EntityFollowerList from "components/Follow/EntityFollowerListAsync";

import paginationActions from "actions/pagination";
import * as sortConstants from "constants/sort";
import loadUsersList from "sagas/pagination/lists/loadUsersList";

import useActionCreators from "hooks/useActionCreators";
import useList from "hooks/useList";
import { useStyles } from "hooks/useStyles";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  followContainer: {
    maxWidth: "22rem",
    minHeight: "2.75rem",
  },
  container: {
    width: "100%",
    height: "14.2rem",

    [ScreenSizes.lgAndAbove]: {
      height: "7rem",
    },
  },
  loadingContainer: {
    height: "14.2rem",

    [ScreenSizes.mdAndAbove]: {
      height: "7rem",
    },
  },
};

const mobileListStyles = {
  title: {
    fontSize: "1.125rem",
  },
  titleSmall: {
    marginLeft: "1rem",
  },
  ...baseStyles,
};

const loadingStyles = {
  noOverlay: {
    padding: "4rem 0",
    fontSize: "2rem",
    height: "11.8rem",

    [ScreenSizes.lgAndAbove]: {
      height: "2.7rem",
    },
  },
};

const Followers = (props) => {
  const { podcast, mobile } = props;
  const followersListKey = `podcastView/${podcast.get("id")}/followersPanel`;

  const { styles, css } = useStyles(baseStyles, props);

  const { initList } = useActionCreators({
    initList: paginationActions.initList,
  });

  const { listExists, total, loading } = useList(followersListKey);

  useEffect(() => {
    if (!listExists) {
      initList({
        key: followersListKey,
        sort: sortConstants.SORT_ORDER_RELEVANCE,
        sort_direction: "desc",
        list_type: "users_filtered",
        loadListAction: loadUsersList,
        entity_type: "user",
        pageSize: 8,
        staticFilters: {
          following: { value: { podcast: podcast.get("id") } },
        },
        renderOnMount: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [followersListKey, listExists]);

  if (loading) {
    return <div className={css(styles.loadingContainer)}></div>;
  }

  if (total === undefined || total === null || total === 0) {
    return null;
  }

  return (
    <PageSidebarPanel {...props} className={css(styles.container)}>
      <EntityFollowerList
        entity={podcast}
        entity_type="podcast"
        followDirection="followers"
        listKey={followersListKey}
        styles={mobile ? mobileListStyles : baseStyles}
        loadingStyles={loadingStyles}
        {...props}
      />
    </PageSidebarPanel>
  );
};

Followers.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
  mobile: PropTypes.bool,
};

Followers.defaultProps = {
  mobile: false,
};

export default Followers;
