import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import { TabsContext } from "components/Common/Tabs";
import PodcastsStub from "components/Entities/Stubs/PodcastsStubAsync";
import RequestContext from "pages/RequestContext";

import { SORT_ORDER_POPULAR } from "constants/sort";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useList from "hooks/useList";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  podcast: {
    ...gStyles.skeletonPlaceholder,
    margin: "0.375rem",
    listStyle: "none",
    width: "100%",
    flex: "0 0 96%",
    aspectRatio: "1 / 1",
    padding: "0 0.75rem",
    marginBottom: "2rem",

    [ScreenSizes.smAndAbove]: {
      width: "50%",
      flex: "0 0 46%",
      marginBottom: "2rem",
    },

    [ScreenSizes.mdAndAbove]: {
      width: "25%",
      flex: "0 0 22%",
      marginBottom: "3.6rem",
    },

    [ScreenSizes.lgAndAbove]: {
      width: "50%",
      flex: "0 0 46%",
      marginBottom: "4rem",
    },

    [ScreenSizes.xlAndAbove]: {
      width: "25%",
      flex: "0 0 23%",
      marginBottom: "5.1rem",
    },
  },
  container: {
    ...gStyles.skeletonPlaceholder,
    minHeight: "104.125rem",

    [ScreenSizes.smAndAbove]: {
      minHeight: "34.3rem",
    },

    [ScreenSizes.lgAndAbove]: {
      minHeight: "39.7rem",
    },

    [ScreenSizes.xlAndAbove]: {
      minHeight: "21.6rem",
    },
  },
};

const podcastStubStyles = {
  podcastList: {
    display: "flex",
    flexDirection: "row",
    margin: "0 -0.75rem -1rem",
    padding: 0,
    listStyle: "none",
    flexWrap: "wrap",

    [ScreenSizes.lgAndAbove]: {
      minHeight: "33.7rem",
    },

    [ScreenSizes.xlAndAbove]: {
      minHeight: "15.5rem",
    },
  },
};

const AboutSimilar = (props) => {
  const { podcast, onTabChange } = props;
  const { css, styles } = useStyles(baseStyles, props);

  const requestContext = useContext(RequestContext);

  const staticFilters = useMemo(
    () => ({
      recommendations: {
        value: {
          podcasts: [podcast.get("id")],
        },
      },
    }),
    [podcast]
  );

  const similarOnTabChange = useCallback(
    () => onTabChange("similar"),
    [onTabChange]
  );

  const listKey = `podcastView/${podcast.get("id")}/aboutSimilar`;

  const { listExists, list, total } = useList(listKey);

  const { contentRef } = useContext(TabsContext);
  const { isWindowSizeOrLess } = useWindowSize();

  const mobile = isWindowSizeOrLess("medium");

  const clickEventVars = useMemo(
    () => ({ from_podcast: podcast.get("id") }),
    [podcast]
  );

  function renderLoadingComponent() {
    return (
      <>
        <div className={css(styles.podcast)} />
        <div className={css(styles.podcast)} />
        <div className={css(styles.podcast)} />
        <div className={css(styles.podcast)} />
      </>
    );
  }

  if (listExists && list.get("loaded") && total === 0) {
    return null;
  }

  return (
    <LazyLoadComponent
      className={css(styles.container)}
      renderOnMount={requestContext.isBotCrawler}
    >
      <PodcastsStub
        listKey={listKey}
        title="Similar Podcasts"
        staticFilters={staticFilters}
        entity_type="podcast"
        entity={podcast}
        scrollRef={contentRef}
        showTitles
        showBadges
        clickEventAction="similarPodcast"
        clickEventVars={clickEventVars}
        viewAllLink={getPodcastUrl(podcast, "similar")}
        viewAllTitle={`Similar Podcasts to ${podcast.get("title")}`}
        maxItems={4}
        inSidebar={mobile}
        onTabChange={similarOnTabChange}
        showCount={false}
        sort={SORT_ORDER_POPULAR}
        listConfig={{
          renderLoadingComponent,
        }}
        styles={podcastStubStyles}
      />
    </LazyLoadComponent>
  );
};

AboutSimilar.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  podcast: PropTypes.instanceOf(Map),
};
AboutSimilar.defaultProps = {
  podcast: null,
};

export default AboutSimilar;
