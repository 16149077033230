import Loadable from "@loadable/component";
const SocialLinksPanel = Loadable(() => import("./SocialLinksPanel"));

function SocialLinksPanelAsyncLoading() {
  return null;
}

const SocialLinksPanelContainer = (props) => {
  return (
    <SocialLinksPanel {...props} fallback={<SocialLinksPanelAsyncLoading />} />
  );
};

export default SocialLinksPanelContainer;
