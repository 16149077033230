import { faComment } from "@fortawesome/free-regular-svg-icons/faComment";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons/faUserCircle";
import { faBolt } from "@fortawesome/pro-solid-svg-icons/faBolt";
import { List, Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useEffect, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { checkExternalIds as checkExternalIdsRoutine } from "routines/podcast";

import EntityPageActionHandler from "components/ActionHandler/EntityPageActionHandlerAsync";
import Page from "components/Entities/Page/Page";
import SingleReviewModal from "components/Review/SingleReviewModalAsync";
import SingleReviewStructuredData from "components/Review/SingleReviewStructuredDataAsync";

import About from "./About/About";
import Creators from "./Creators/CreatorsAsync";
import EpisodeHeader from "./EpisodeHeader";
import EpisodeViewStructuredData from "./EpisodeViewStructuredData";
import InsightsContainer from "./Insights/InsightsContainer";
import Lists from "./Lists/ListsAsync";
import Reviews from "./Reviews/ReviewsAsync";
import Sidebar from "./Sidebar/EpisodeViewSidebarAsync";
import Transcript from "./transcripts/TranscriptViewContainerAsync";

import modalActions from "actions/modals";
import getEpisodeName from "utils/entity/getEpisodeName";
import getEpisodeUrl from "utils/entity/getEpisodeUrl";
import getPodcastName from "utils/entity/getPodcastName";
import sendGAEvent from "utils/sendGAEvent";
import { getParams } from "utils/url";

import useActionCreators from "hooks/useActionCreators";
import useReviewWithEntity from "hooks/useReviewWithEntity";

import faList from "styles/icons/faList";
import ScreenSizes, { createBreakpoint } from "styles/ScreenSizes";

const BASE_ROUTE = "/podcasts/:id/episodes/:episode_id";

const overrideStyles = {
  container: {
    [ScreenSizes.lgAndAbove]: {
      marginTop: "0.938rem",
    },
    [createBreakpoint({ min: 1400 })]: {
      marginTop: 0,
    },
  },
};

const getTabs = ({ episode, podcast, showModal, basePathname }) => {
  const hasReviews = episode && episode.get("review_count") > 0;
  const hasTranscript =
    episode && episode.getIn(["transcript_counts", "beautified"]) === 1;
  const hasCreators = episode && episode.get("creator_count") > 0;
  const hasLists = episode && episode.get("list_count") > 0;

  const showPromptModal = (modalProps) => () => {
    showModal("prompt", {
      ...modalProps,
      entity: episode,
      entity_type: "episode",
      page: `Episodes View - ${modalProps?.promptType} tab`,
    });
    sendGAEvent({
      action: `episodeView-emptyTabClick-${modalProps.promptType}`,
      episode_id: episode,
      episode_name: getEpisodeName(episode, "episode"),
    });
  };

  const episodeUrl = `${getEpisodeUrl(episode, { podcast })}`;

  return [
    {
      key: "about",
      title: "About",
      icon: faHome,
      contentComponent: About,
      anchor: `${episodeUrl}`,
      hiddenTitle: `${episode.get("title")}`,
    },
    {
      key: "insights",
      title: "Insights",
      icon: faBolt,
      contentComponent: InsightsContainer,
      anchor: `${episodeUrl}/insights`,
      hiddenTitle: `Insights into ${episode.get("title")}`,
      condition: true,
      redirect: basePathname,
      pro: true,
      tabProps: {
        entityType: "episode",
      },
    },
    {
      key: "reviews",
      title: "Reviews",
      icon: faComment,
      contentComponent: Reviews,
      badge:
        episode.get("review_count") > 0 ? episode.get("review_count") : null,
      anchor: hasReviews && `${episodeUrl}/reviews`,
      preventTabChange: !hasReviews,
      onClick: hasReviews ? null : showPromptModal({ promptType: "review" }),
      contentCondition: hasReviews,
      redirect: basePathname,
      hiddenTitle: `Reviews of ${episode.get("title")}`,
    },
    {
      key: "creators",
      title: "Credits",
      icon: faUserCircle,
      contentComponent: Creators,
      badge:
        episode?.get("creator_count") && episode?.get("creator_count") > 0
          ? episode?.get("creator_count")
          : null,
      anchor: hasCreators && `${episodeUrl}/creators`,
      preventTabChange: !hasCreators,
      onClick: hasCreators
        ? null
        : showPromptModal({ promptType: "episodeCreator" }),
      contentCondition: hasCreators,
      redirect: basePathname,
      hiddenTitle: `Creators of ${episode.get("title")}`,
    },
    {
      key: "lists",
      title: "Lists",
      icon: faList,
      contentComponent: Lists,
      badge: episode.get("list_count") > 0 ? episode.get("list_count") : null,
      anchor: hasLists && `${episodeUrl}/lists`,
      preventTabChange: !hasLists,
      onClick: hasLists ? null : showPromptModal({ promptType: "list" }),
      contentCondition: hasLists,
      redirect: basePathname,
      hiddenTitle: `Lists featuring ${episode.get("title")}`,
    },
    {
      key: "transcript",
      title: "Transcript",
      contentComponent: Transcript,
      anchor: `${episodeUrl}/transcript`,
      preventTabChange: !hasTranscript,
      hiddenTitle: `Transcript for ${episode.get("title")}`,
      redirect: basePathname,
      onClick: hasTranscript
        ? null
        : showPromptModal({ promptType: "transcript" }),
      contentCondition: hasTranscript,
      tabProps: {
        entityType: "episode",
      },
    },
  ];
};

const REVIEWS_ROUTE =
  "/podcasts/:podcast_id/episodes/:episode_id/reviews/:review_id";

const EpisodeView = ({ episode, podcast, categories }) => {
  const location = useLocation();
  const { id, episode_id } = useParams();

  const basePathname = `/podcasts/${id}/episodes/${episode_id}`;

  const { showModal } = useActionCreators(modalActions);

  const tabs = useMemo(
    () =>
      getTabs({
        episode,
        podcast,
        showModal,
        basePathname,
      }),
    [episode, podcast, showModal, basePathname]
  );

  const dataHeader = useMemo(
    () => (
      <EpisodeViewStructuredData
        episode={episode}
        podcast={podcast}
        categories={categories}
      />
    ),
    [episode, podcast, categories]
  );

  const routePassProps = useMemo(
    () => ({
      episode,
      podcast,
    }),
    [episode, podcast]
  );

  const { review_id } = getParams(location.pathname, REVIEWS_ROUTE);
  const review = useReviewWithEntity(review_id);

  const { checkExternalIds } = useActionCreators({
    checkExternalIds: checkExternalIdsRoutine,
  });

  const analyticsVariables = useMemo(
    () => ({
      podcast_id: episode.get("podcast_id"),
      episode_id: episode.get("id"),
      podcast_name: getPodcastName(podcast),
      episode_name: getEpisodeName(episode),
      page: "episode",
      pageType: "EpisodeView",
      page_id: episode.get("id"),
      page_name: getEpisodeName(episode),
    }),
    [podcast, episode]
  );

  useEffect(() => {
    // check for missing external IDs we know a podcast can have, and hit the server in the background to check for them
    const externalIds =
      episode.getIn(["meta_data", "external_ids"], Map()) || Map();
    const EXTERNAL_PLAYER_KEYS = ["spotify"];
    if (!EXTERNAL_PLAYER_KEYS.every((player) => externalIds.has(player))) {
      checkExternalIds({
        entity_type: "entity",
        podcast_id: episode.get("podcast_id"),
        episode_id: episode.get("id"),
      });
    }
  }, [checkExternalIds, episode, podcast]);

  return (
    <Fragment>
      <Page
        baseRoute={BASE_ROUTE}
        tabs={tabs}
        basePathname={basePathname}
        dataHeader={dataHeader}
        header={EpisodeHeader}
        sideBar={Sidebar}
        tabProps={routePassProps}
        preserveScroll
        analyticsVariables={analyticsVariables}
        entity_type="episode"
        entity={episode}
        showMobileTabs
        styles={overrideStyles}
        podcast={podcast}
      />
      <EntityPageActionHandler
        entity={episode}
        entity_type="episode"
        getName={getEpisodeName}
      />
      {review_id && review && (
        <Fragment>
          <SingleReviewModal
            review_id={parseInt(review_id, 10)}
            navigate_on_exit={getEpisodeUrl(episode, { path: "/reviews" })}
          />
          <SingleReviewStructuredData review_id={review_id} />
        </Fragment>
      )}
    </Fragment>
  );
};

EpisodeView.propTypes = {
  episode: PropTypes.instanceOf(Map),
  podcast: PropTypes.instanceOf(Map),
  categories: PropTypes.instanceOf(List),
};

EpisodeView.defaultProps = {
  episode: null,
  podcast: null,
  categories: null,
};

export default EpisodeView;
