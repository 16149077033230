import Loadable from "@loadable/component";
const ProNotes = Loadable(() =>
  import(/* webpackChunkName: "ProNotes" */ "./ProNotes")
);

function ProNotesAsyncLoading(props) {
  return null;
}

const ProNotesContainer = (props) => {
  return <ProNotes {...props} fallback={<ProNotesAsyncLoading />} />;
};

export default ProNotesContainer;
