import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  insights: {
    width: "100%",
  },

  reachContainer: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,

    marginBottom: "0.5rem",

    [ScreenSizes.lgAndAbove]: {
      height: "11.25rem",
      marginBottom: "3rem",
    },
  },
  audienceContainer: {
    ...gStyles.skeletonPlaceholder,
    borderRadius: 8,
    color: colours.grey,

    marginBottom: "0.5rem",

    [ScreenSizes.lgAndAbove]: {
      height: "45rem",
      marginBottom: "3rem",
    },
  },
};

function AboutLoadingSkeleton(props) {
  const { styles, css } = useStyles(baseStyles, props);

  return (
    <div className={css(styles.insights)} data-id="insights-section">
      <div className={css(styles.reachContainer)} />
      <div className={css(styles.audienceContainer)} />
    </div>
  );
}

export default AboutLoadingSkeleton;
