import { Map } from "immutable";
import PropTypes from "prop-types";

import Insights from "./async";
import PublicInsights from "./PublicInsights/async";

import useHasProForPodcast from "hooks/useHasProForPodcast";

function InsightsContainer(props) {
  const { podcast } = props;

  const hasPro = useHasProForPodcast(podcast?.get("id"));

  if (hasPro) {
    return <Insights {...props} />;
  }

  return <PublicInsights {...props} />;
}

InsightsContainer.propTypes = {
  podcast: PropTypes.instanceOf(Map),
};

InsightsContainer.defaultProps = {
  podcast: null,
};

export default InsightsContainer;
