import Loadable from "@loadable/component";
import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "7.2rem",
    marginBottom: "0.75rem",

    [ScreenSizes.smAndAbove]: {
      height: "6.8rem",
    },

    [ScreenSizes.lgAndAbove]: {
      height: "7.7rem",
    },

    [ScreenSizes.xlAndAbove]: {
      height: "5.8rem",
    },
  },
};

const PodcastEpisodeCardContainerAsync = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return <div className={css(styles.container)} />;
};

const PodcastEpisodeCardContainerAsyncMemo = memo(
  PodcastEpisodeCardContainerAsync
);

const PodcastEpisodeCardContainer = Loadable(() =>
  import("./PodcastEpisodeCardContainer")
);

function PodcastEpisodeCardContainerAsyncLoading() {
  return <PodcastEpisodeCardContainerAsyncMemo />;
}

const PodcastEpisodeCardContainerContainer = (props) => {
  return (
    <PodcastEpisodeCardContainer
      {...props}
      fallback={<PodcastEpisodeCardContainerAsyncLoading />}
    />
  );
};

export default PodcastEpisodeCardContainerContainer;
