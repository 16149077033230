import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const Title = Loadable(() => import("./Title"));

function TitleAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const TitleContainer = (props) => {
  return <Title {...props} fallback={<TitleAsyncLoading />} />;
};

export default TitleContainer;
