import colours from "./colours";
import gStyles from "./GenericStyles";
import ScreenSizes from "./ScreenSizes";

const entityPageStyles = {
  headerContainer: {
    display: "flex",
    flexDirection: "row",
  },
  largeContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  largeContentContainerLeftColumn: {
    maxWidth: "37rem",
    marginRight: "2rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    [ScreenSizes.lgAndAbove]: {
      justifyContent: "flex-start",
    },
  },
  mainTextContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,

    [ScreenSizes.lgAndAbove]: {
      justifyContent: "center",
    },
  },
  subtitle: {
    overflow: "hidden",
    margin: "0.75rem auto 1rem",
    ":last-child": {
      marginBottom: 0,
    },
    [ScreenSizes.mdAndAbove]: {
      maxWidth: "90%",
    },
    [ScreenSizes.lgAndAbove]: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      margin: ".8rem 0 1rem",
      maxWidth: "100%",
    },
    [ScreenSizes.xlAndAbove]: {
      margin: "1rem 0 1.5rem",
    },
  },
  description: {
    ...gStyles.fontRegular,
    fontSize: ".8125rem",
    lineHeight: 1.6,
    position: "relative",
    margin: 0,
    color: colours.entitySubtleText,

    [ScreenSizes.smAndAbove]: {
      fontSize: "1rem",
    },
    [ScreenSizes.lgAndAbove]: {
      ...gStyles.fontLight,
      whiteSpace: "normal",
      fontSize: "1rem",
      lineHeight: "24px",
      color: colours.white,
    },
    [ScreenSizes.xlAndAbove]: {
      fontSize: "1rem",
    },
  },
  mediumHeaderContent: {
    zIndex: 1,
    position: "relative",
    marginTop: "1rem",
    marginBottom: "-1.1rem",
    color: colours.white,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  smallHeaderName: {
    fontSize: "0.8125rem",
    margin: 0,
    padding: 0,
    ...gStyles.fontMedium,
    maxWidth: "100%",
    cursor: "pointer",
    marginBottom: "0.25rem",
  },
  mediumNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    [ScreenSizes.lgAndAbove]: {
      marginTop: "2.5rem",
    },
  },
  name: {
    ...gStyles.avalonBold,
    fontSize: "2rem",
    cursor: "pointer",
    pointerEvents: "none",
    margin: 0,
    padding: 0,

    [ScreenSizes.lgAndAbove]: {
      pointerEvents: "auto",
      cursor: "default",
    },
  },
  mediumName: {
    color: colours.white,
    position: "relative",
    width: "100%",
    whiteSpace: "normal",
    fontSize: "1.5rem",
  },
  ratingContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    cursor: "pointer",
  },
  ratingStarsContainer: {
    pointerEvents: "none",
  },
  ratingTitle: {
    ...gStyles.fontMedium,
    fontSize: ".75rem",
    marginTop: ".875rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: "1rem",
    },
    [ScreenSizes.lgAndAbove]: {
      ...gStyles.fontMedium,
      color: colours.white,
      fontSize: ".813rem",
      marginTop: "1.3rem",
    },
  },
  infoContainer: {
    whiteSpace: "normal",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "1.5rem",
    },
  },
};

export default entityPageStyles;
