import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";

import { getIcons } from "components/Entities/Cards/PodcastCards/sharedPodcastElements";
import EntityImage from "components/Entities/EntityImage";
import EntityLink from "components/Entities/EntityLink";
import InfoIcons from "components/Entities/Items/InfoIconsAsync";
import PageSidebarPanel from "components/Entities/Page/PageSidebarPanel";
import Title from "components/Entities/Page/Title";
import EntityFollowButton from "components/Follow/EntityFollowButtonAsync";

import getPodcastUrl from "utils/entity/getPodcastUrl";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import cardStyles from "styles/CardStyles";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  details: {
    width: "100%",
    display: "block",
    flexDirection: "column",
  },
  podcastImage: {
    float: "right",
    width: "100%",
    height: "auto",
    maxWidth: 100,
    marginBottom: "1rem",
    display: "block",
    marginLeft: "1rem",
    [ScreenSizes.mdAndAbove]: {
      maxWidth: 60,
      flexBasis: 60,
    },
  },
  podcastTitle: {
    ...gStyles.avalonBold,
    margin: "0 0 1rem",
    [ScreenSizes.mdAndAbove]: {
      marginBottom: "0.5rem",
    },
  },
  infoIcons: {
    display: "block",
    margin: "0.5rem 0 1rem",
    ":last-child": {
      marginBottom: 0,
    },
  },
  podcastDescription: {
    ...cardStyles.mobileCardDescription,
    clear: "both",

    border: 0,
    maxWidth: "17.5rem",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "4",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",

    [ScreenSizes.mdAndAbove]: {
      ...cardStyles.desktopCardDescription,
      clear: "both",
    },
  },
  buttons: {
    marginTop: "1rem",
  },
};

const iconStyles = {
  tooltip: {
    marginRight: "1rem",
  },
};

const titleStyles = {
  titleContainer: {
    ...gStyles.avalonBold,
    display: "block",
    fontSize: "0.75rem",
    paddingBottom: "0.75rem",

    [ScreenSizes.smAndAbove]: {
      fontSize: "0.75rem",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: "0.75rem",
      paddingBottom: "0.75rem",
    },
  },
};

function PodcastInfo(props) {
  const { podcast, noPanel } = props;
  const { styles } = useStyles(baseStyles, props);

  const icons = useMemo(() => getIcons(podcast), [podcast]);
  const { isWindowSizeOrLess } = useWindowSize();
  const mobile = isWindowSizeOrLess("medium");

  const Panel = noPanel ? Fragment : PageSidebarPanel;

  return (
    <Panel {...(noPanel ? {} : { renderOnMount: true })}>
      <div className={css(styles.details)}>
        <div className={css(styles.podcastImage)}>
          <EntityImage
            entity={podcast}
            entity_type="podcast"
            size={mobile ? 100 : 60}
          />
        </div>
        <Title
          title="From The Podcast"
          TitleComponent="h3"
          inSidebar
          styles={titleStyles}
        />
        <h4 className={css(styles.podcastTitle)}>
          <EntityLink
            entity={podcast}
            entity_type="podcast"
            clamp={2}
            inline
            avalon
          >
            {podcast.get("title")}
          </EntityLink>
        </h4>
        <span className={css(styles.infoIcons)}>
          <InfoIcons icons={icons} styles={iconStyles} />
        </span>
        <Link
          to={getPodcastUrl(podcast)}
          className={css(styles.podcastDescription)}
        >
          {podcast.get("description")}
        </Link>
      </div>
      <div className={css(styles.buttons)}>
        <EntityFollowButton
          dataId="podcast-info-follow-button"
          entity_type="podcast"
          entity={podcast}
          variation="white"
          followedVariation="actioned"
          key="PodcastFollowButton"
          entityName="Podcast"
        />
      </div>
    </Panel>
  );
}

PodcastInfo.propTypes = {
  podcast: PropTypes.instanceOf(Map),
  noPanel: PropTypes.bool,
};

PodcastInfo.defaultProps = {
  podcast: null,
  noPanel: false,
};

export default PodcastInfo;
