import Immutable from "immutable";
import PropTypes from "prop-types";

import TagsPanel from "components/Entities/Page/TagsPanel";

import { selectSpecificCategory } from "selectors/category";

import useEpisodeData from "hooks/useEpisodeData";
import useReduxState from "hooks/useReduxState";

const Tags = (props) => {
  const { episodeId, noPanel } = props;

  const episode = useEpisodeData(episodeId);

  const categories = useReduxState(
    (state) => {
      let categories = Immutable.List([]);

      if (episode && episode.get("hashtags")) {
        const isDenormalized =
          episode &&
          episode.get("hashtags") &&
          episode.get("hashtags").first() &&
          typeof episode.get("hashtags").first() === "object";

        categories = isDenormalized
          ? episode.get("hashtags")
          : episode
              .get("hashtags")
              .map((cat_id) => selectSpecificCategory(state, cat_id));
      }

      return categories;
    },
    [episode]
  );

  return (
    <TagsPanel
      title="Episode Tags"
      entity={episode}
      entity_type="episode"
      categories={categories}
      noPanel={noPanel}
    />
  );
};

Tags.propTypes = {
  episodeId: PropTypes.number.isRequired,
  noPanel: PropTypes.bool,
};

Tags.defaultProps = {
  noPanel: false,
};

export default Tags;
