import { getParams } from "utils/url";

export const getId = (props = null) => {
  if (!props) {
    return null;
  }

  if (props.episode) {
    return props.episode.get("id");
  }

  if (props.episode_id !== undefined) {
    return props.episode_id;
  }

  if (props.location) {
    const params = getParams(
      props.location.pathname,
      "/podcasts/:podcast_id/episodes/:episode_id"
    );

    if (params.episode_id) {
      const matched = params.episode_id.match(/\d+$/);

      if (matched && matched[0]) {
        return matched[0];
      }
    }
  }

  if (props.params && props.params.episode_id) {
    const idMatch = props.params.episode_id.match(/\d+$/);

    return idMatch && idMatch[0];
  }

  return null;
};
