import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const Merch = Loadable(() => import("./Merch"));

function MerchAsyncLoading(props) {
  return <LoadableLoading {...props} />;
}

const MerchContainer = (props) => {
  return <Merch {...props} fallback={<MerchAsyncLoading />} />;
};

export default MerchContainer;
