import { useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import getPodcastWrapperId from "components/EntityWrappers/getPodcastWrapperId";
import SingleReviewStructuredData from "components/Review/SingleReviewStructuredDataAsync";
import Error404 from "pages/Error404/Async";

import PodcastView from "./PodcastView";

import { loadSpecificPodcast as loadSpecificPodcastAction } from "actions/podcast";
import sendGAEvent from "utils/sendGAEvent";
import { getParams } from "utils/url";

import useActionCreators from "hooks/useActionCreators";
import useExecOnMount from "hooks/useExecOnMount";
import usePodcastData, {
  usePodcastDataIsLoading,
  usePodcastDataFailed,
} from "hooks/usePodcastData";
import useReviewWithEntity from "hooks/useReviewWithEntity";

const REVIEWS_ROUTE = "/podcasts/:podcast_id/reviews/:review_id";
const compareFunc = (compProps, oldCompProps) =>
  compProps.podcastID !== oldCompProps.podcastID;

const PodcastAboutViewContainer = () => {
  const params = useParams();
  const location = useLocation();

  const podcastID = getPodcastWrapperId({ params });

  const podcast = usePodcastData(podcastID);
  const podcastTitle = podcast?.get("title");

  const loading = usePodcastDataIsLoading(podcastID);
  const isFailed = usePodcastDataFailed(podcastID);

  const { loadSpecificPodcast } = useActionCreators({
    loadSpecificPodcast: loadSpecificPodcastAction,
  });

  const loadPodcastFlag = podcastID && !loading;

  const loadPodcast = useCallback(() => {
    if (loadPodcastFlag) {
      loadSpecificPodcast(podcastID);
    }
  }, [loadPodcastFlag, loadSpecificPodcast, podcastID]);

  useExecOnMount(loadPodcast, loadPodcastFlag, { podcastID }, compareFunc);

  useEffect(() => {
    if (podcastID && podcastTitle) {
      sendGAEvent({
        action: "podcastPageImpression",
        podcast_id: podcastID,
        page: "podcast",
        pageType: "PodcastView",
        page_id: podcastID,
        page_name: podcastTitle,
        podcast_category: podcast?.get("categories")?.toJS(),
        hosting_provider: podcast?.get("hosting_provider"),
        path: location?.pathname,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastID, podcastTitle, location?.pathname]);

  const { review_id } = getParams(location.pathname, REVIEWS_ROUTE);
  const review = useReviewWithEntity(review_id);

  if (!podcast && isFailed) {
    return <Error404 />;
  }

  return (
    <>
      <PodcastView podcast={podcast} />
      {review_id && review && review.get("entity") && (
        <SingleReviewStructuredData review_id={review_id} />
      )}
    </>
  );
};

export default PodcastAboutViewContainer;
