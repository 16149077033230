import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";

import InfoMessage from "components/Common/Messages/InfoMessage";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  messageContainer: {
    [ScreenSizes.lgAndAbove]: {
      marginTop: "5rem",
    },
  },
};

const infoMessageStyles = {
  message: {
    ...gStyles.fontMediumItalic,
    background: "none",
    border: "none",
    color: "#6E7684",
    letterSpacing: 0,
    textAlign: "center",
  },
};

const AboutDisclaimer = (props) => {
  const { styles } = useStyles(baseStyles, props);
  return (
    <div className={css(styles.messageContainer)}>
      <InfoMessage styles={infoMessageStyles}>
        This podcast, its content, and its artwork are not owned by, affiliated
        with, or endorsed by Podchaser.
      </InfoMessage>
    </div>
  );
};

AboutDisclaimer.propTypes = {
  episode: PropTypes.instanceOf(Map),
};
AboutDisclaimer.defaultProps = {
  episode: null,
};

export default AboutDisclaimer;
