import { Map } from "immutable";
import PropTypes from "prop-types";

import Similar from "./async";
import SimilarTabLoading from "./SimilarTabLoading";

function SimilarContainer(props) {
  const { podcast } = props;

  if (!podcast?.get("id")) {
    return <SimilarTabLoading />;
  }

  return <Similar {...props} />;
}

SimilarContainer.propTypes = {
  podcast: PropTypes.instanceOf(Map),
};

SimilarContainer.defaultProps = {
  podcast: null,
};
export default SimilarContainer;
