import { css } from "aphrodite";
import { List } from "immutable";
import PropTypes from "prop-types";
import { memo } from "react";

import LinkListItem from "components/Lists/LinkListItem";

import { indexKey } from "utils/misc";

import { useStyles } from "hooks/useStyles";

const LinkList = (props) => {
  const { links, styles: linkStyles } = props;
  const { styles } = useStyles({}, props);

  if (!links || links.size === 0 || links.length === 0) {
    return null;
  }

  return (
    <ul className={css(styles.list)}>
      {links.map((link, i) => (
        <LinkListItem key={indexKey(i)} {...link} styles={linkStyles} />
      ))}
    </ul>
  );
};

LinkList.propTypes = {
  links: PropTypes.oneOfType([PropTypes.array, PropTypes.instanceOf(List)]),
  styles: PropTypes.object,
};
LinkList.defaultProps = {
  links: null,
  styles: null,
};

export default memo(LinkList);
