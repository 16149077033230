import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import ActivityContext from "components/Analytics/ActivityContext";
import ContentContainer from "components/Common/Layout/ContentContainer";
import Tabs from "components/Common/Tabs";

import sendGAEvent from "utils/sendGAEvent";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    backgroundColor: "#FDFDFD",
    display: "flex",
    flex: "none",
    flexDirection: "column",
    // chrome 41 breaks when this is 100%, causing SEO issues
    minHeight: 0,
    minWidth: 0,
    "-webkit-tap-highlight-color": "rgb(0,0,0,0)",
  },
  columnContainer: {
    [ScreenSizes.mdAndAbove]: {
      display: "flex",
      flexDirection: "row",
    },
  },
  tabsContainer: {
    [ScreenSizes.mdAndAbove]: {
      display: "flex",
      flex: 2,
      marginTop: "3rem",
      maxWidth: "100%",
      overflow: "hidden",
      paddingRight: "4%",
      paddingLeft: "2rem",
      marginLeft: "-2rem",
    },

    [ScreenSizes.xlAndAbove]: {
      paddingRight: "5%",
      marginTop: "3.25rem",
    },
  },
  sidebarContainer: {
    display: "none",
    flex: 1,
    maxWidth: 300,

    [ScreenSizes.mdAndAbove]: {
      display: "flex",
    },

    [ScreenSizes.lgAndAbove]: {
      maxWidth: 250,
      flexBasis: 250,
      flexShrink: 0,
    },

    [ScreenSizes.xlAndAbove]: {
      maxWidth: 300,
      flexBasis: 300,
      flexShrink: 0,
    },
  },
  desktopContainer: {
    display: "none",

    [ScreenSizes.mdAndAbove]: {
      display: "block",
    },
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.mdAndAbove]: {
      display: "none",
    },
  },
};

const contentContainerStyles = {
  contentContainer: {
    padding: "0rem",

    [ScreenSizes.lgAndAbove]: {
      padding: "2.2rem",
    },

    [ScreenSizes.xlAndAbove]: {
      padding: "3rem",
    },
  },
};

export const tabsStyles = {
  tabs: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottom: "3px solid rgba(0, 0, 0, 0.05)",
    marginBottom: "2rem",
  },
  tabContainer: {
    position: "relative",
    top: 3,
    padding: "0 1.5rem 0 0",

    [ScreenSizes.xlAndAbove]: {
      padding: "0 1.75rem 0 0",
    },
  },
  tab: {
    fontSize: "1rem",
    paddingBottom: "1.5rem",
    borderBottomWidth: 3,
    ...gStyles.avalonBold,
    color: "rgb(64, 73, 87)",
  },
  currentTab: {
    ...gStyles.avalonBold,
    color: colours.primary,
  },
};

const mobileTabStyles = {
  container: {
    marginBottom: 0,
  },
  tabs: {
    padding: "1rem 1.5rem 0",
    overflowX: "auto",
    marginBottom: "1rem",
    justifyContent: "center",

    // Hide ScrollBar
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "::-webkit-scrollbar": {
      display: "none",
    },

    [ScreenSizes.mdAndAbove]: {
      padding: 0,
      marginBottom: "2rem",
    },
  },
  tabsWide: {
    justifyContent: "flex-start",
  },
  tabs_about: {
    marginBottom: 0,
  },
  tabContainer: {
    ":last-child": {
      paddingRight: 0,
    },
  },
  tabContainerWide: {
    display: "flex",

    ":last-child": {
      paddingRight: "1.5rem",
    },
  },
  tab: {
    paddingBottom: "1rem",
    marginBottom: 3,

    [ScreenSizes.mdAndAbove]: {
      paddingBottom: "1.5rem",
    },
  },
};

const Page = (props) => {
  const {
    dataHeader,
    header: Header,
    sideBar: Sidebar,
    baseRoute,
    basePathname,
    tabs,
    tabStyles,
    tabProps: passedTabProps,
    renderPage,
    preserveScroll,
    analyticsVariables,
    entity_type,
    entity,
    showMobileTabs,
    separateTabs,
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const history = useHistory();
  const location = useLocation();

  const mobile = isWindowSizeOrLess("medium");

  // TODO should scroll to the top of the tabs on change
  const handleTabChange = useCallback(
    (tab, e) => {
      if (!tab.preventTabChange) {
        let tabKey = null;

        if (typeof tab === "string") {
          tabKey = tab;
        } else {
          tabKey = tab.key === "about" ? "" : tab.key;
        }

        let entityProps = {};

        if (entity && entity_type) {
          entityProps = {
            [`${entity_type}_name`]:
              entity && (entity.get("title") || entity.get("name")),
          };
        }

        sendGAEvent({
          action: "pageTabClicked",
          tab: tabKey,
          ...entityProps,
          ...analyticsVariables,
        });

        const newPage =
          typeof tab.anchor === "string"
            ? tab.anchor
            : `${basePathname}/${tabKey}`;
        const pathname = tab.root ? basePathname : newPage;

        if (typeof window !== "undefined" && e && (e.ctrlKey || e.metaKey)) {
          window.open(pathname, "_blank");
        } else {
          history.push(
            { pathname },
            { preserveScroll: preserveScroll || tab.preserveScroll }
          );
        }
      }
    },
    [
      entity,
      entity_type,
      analyticsVariables,
      history,
      basePathname,
      preserveScroll,
    ]
  );

  const tabProps = useMemo(
    () => ({
      onTabChange: handleTabChange,
      mobile,
      ...passedTabProps,
    }),
    [handleTabChange, mobile, passedTabProps]
  );

  const renderTabs = (forMobile = false, justTabs = false) => (
    <Tabs
      onTabChange={handleTabChange}
      tabs={tabs}
      styles={[
        tabsStyles,
        tabStyles,
        !justTabs && forMobile && mobileTabStyles,
      ].filter((i) => !!i)}
      hideTabs={!showMobileTabs && forMobile}
      tabProps={tabProps}
      baseRoute={baseRoute}
      byRoute
      key={location.path}
      showScrollControls={showMobileTabs}
      justTabs={justTabs}
    />
  );

  const renderContent = () => {
    return (
      <ContentContainer styles={contentContainerStyles} noVerticalPadding>
        <div className={css(styles.columnContainer)}>
          <div className={css(styles.tabsContainer)}>{renderTabs(mobile)}</div>
          {Sidebar && (
            <div className={css(styles.sidebarContainer)}>
              <Sidebar {...props} {...tabProps} onTabChange={handleTabChange} />
            </div>
          )}
        </div>
      </ContentContainer>
    );
  };

  if (renderPage) {
    const isMainPage = location.pathname === basePathname;

    return (
      <div
        className={css(
          styles.container,
          isMainPage && styles.mainPageContainer
        )}
      >
        {dataHeader}
        {renderPage({
          ...props,
          ...tabProps,
          isMainPage,
          renderedTabs: renderTabs(mobile),
          justTabs: separateTabs ? renderTabs(mobile, true) : null,
        })}
      </div>
    );
  }

  return (
    <ActivityContext.Provider value={analyticsVariables}>
      <div className={css(styles.container)}>
        {dataHeader}
        {Header && (
          <Header
            {...props}
            onTabChange={handleTabChange}
            mobile={mobile}
            renderTabs={renderTabs}
          />
        )}
        {renderContent()}
      </div>
    </ActivityContext.Provider>
  );
};

Page.propTypes = {
  baseRoute: PropTypes.string.isRequired,
  sidebar: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
  header: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.node,
  ]),
  tabs: PropTypes.array,
  tabProps: PropTypes.object,
  dataHeader: PropTypes.node,
  tabStyles: PropTypes.object,
  renderPage: PropTypes.func,
  preserveScroll: PropTypes.bool,
  analyticsVariables: PropTypes.object,
  showMobileTabs: PropTypes.bool,
  entity_type: PropTypes.string,
  entity: PropTypes.instanceOf(Map),
  separateTabs: PropTypes.bool,
};

Page.defaultProps = {
  sidebar: null,
  tabs: [],
  tabProps: {},
  dataHeader: null,
  tabStyles: {},
  renderPage: null,
  preserveScroll: false,
  analyticsVariables: {},
  showMobileTabs: false,
  entity_type: null,
  entity: null,
  separateTabs: false,
};

export default Page;
