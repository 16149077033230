import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const EpisodeViewSidebar = Loadable(() =>
  import(/* webpackChunkName: "EpisodeViewSidebar" */ "./EpisodeViewSidebar")
);

function EpisodeViewSidebarLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const EpisodeViewSidebarContainer = (props) => {
  return (
    <EpisodeViewSidebar {...props} fallback={<EpisodeViewSidebarLoading />} />
  );
};

export default EpisodeViewSidebarContainer;
