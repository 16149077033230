import PropTypes from "prop-types";
import { memo } from "react";

import InfoMessage from "components/Common/Messages/InfoMessage";

const NoStubItemsMessage = (props) => {
  const { message } = props;

  return <InfoMessage>{message}</InfoMessage>;
};

NoStubItemsMessage.propTypes = {
  message: PropTypes.node.isRequired,
};

export default memo(NoStubItemsMessage);
