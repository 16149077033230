import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import EpisodeCard from "components/Entities/Cards/EpisodeCards/EpisodeCardContainer";
import PodcastCard from "components/Entities/Cards/PodcastCards/PodcastCardContainer";

import CategoryTag from "../CategoryTag";
import Review from "./Review";

import modalActions from "actions/modals";
import { MOBILE_BOTTOM_ROW_HEIGHT } from "constants/podcast";
import { selectSpecificUser } from "selectors/user";
import { truncateString } from "utils/truncate";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";
import useReviewWithEntity from "hooks/useReviewWithEntity";
import { useStyles } from "hooks/useStyles";

import cardStyles, { cardSizes } from "styles/CardStyles";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  reviewMobileCard: {
    ...cardStyles.mobileCard,
    width: "100%",
    minHeight: "15.7rem",

    [ScreenSizes.lgAndAbove]: {
      minHeight: 0,
    },
  },
  reviewMobileCardNoPanel: {
    ...cardStyles.cardNoPanel,
  },
  topRow: {
    ...cardStyles.mobileCardTopRow,
    padding: 0,
    display: "block",
  },
  reviewBubbleNoSidePadding: {
    ...gStyles.noSidePadding,
  },
  reviewBubbleNoVerticalPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
  },
  reviewBubbleInsideCarousel: {
    paddingLeft: 0,
    paddingRight: "0.75rem",
  },
  reviewInfoIconRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: ".4rem 0",
    fontSize: ".875rem",
  },
  bottomRow: {
    ...cardStyles.bottomRow,
  },
  bottomRowInsidePanel: {
    ...cardStyles.bottomRowInsidePanel,
  },
  bottomRowCategories: {
    ...cardStyles.bottomRowCategories,
  },
  tagTitle: {
    ...cardStyles.bottomRowGroupTitle,
  },
  actions: {
    ...cardStyles.cardActions,
  },
  reviewEntityOverview: {
    padding: cardSizes.mobileStandardPadding,
    paddingBottom: 0,
    marginBottom: cardSizes.mobilePaddingBetweenReviewAndEntity,
    [ScreenSizes.lgAndAbove]: {
      paddingLeft: 0,
    },
  },
};

const rateReviewBubbleStyles = {
  userImage: {
    width: "2rem",
    maxWidth: "2rem",
    height: "2rem",
  },
  reviewBubbleTitle: {
    fontSize: ".875rem",
  },
};

const PAGE_SIZE = 3;

const getEntityOverview = (entity_type) => {
  if (entity_type === "episode") {
    return EpisodeCard;
  }

  return PodcastCard;
};

const ReviewMobileCard = (props) => {
  const {
    review: passedReview,
    hideBottomRow,
    hideEntityOverview,
    noPanel,
    noSidePadding,
    noVerticalPadding,
    showInfoIcons,
    userRating,
    hideUserImage,
    insidePanel,
    insideCarousel,
    renderOnMount,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const pulledReview = useReviewWithEntity(
    passedReview && passedReview.get("id")
  );
  const review = pulledReview || Map();
  const entity = review.has("entity") ? review.get("entity") : Map();
  const loadedUser = useReduxState(
    (state) => selectSpecificUser(state, review.get("user")),
    [review]
  );
  const user = loadedUser || Map();

  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });

  const content = insideCarousel
    ? truncateString(review.get("content"), 200)
    : review.get("content");

  const handleOpenReviewModal = useCallback(
    () => showModal("review", { review_id: review.get("id") }),
    [review, showModal]
  );

  const renderReviewBubble = () => (
    <div
      className={css(
        styles.reviewBubble,
        noSidePadding && styles.reviewBubbleNoSidePadding,
        noVerticalPadding && styles.reviewBubbleNoVerticalPadding,
        insideCarousel && styles.reviewBubbleInsideCarousel
      )}
    >
      <Review
        {...props}
        review={review}
        user={user}
        hideUserImage={hideUserImage}
        userRating={userRating}
        showInfoIcons={showInfoIcons}
        content={content}
        styles={rateReviewBubbleStyles}
        renderOnMount={renderOnMount}
        showTitle
        showImage
        showReplies
      />
    </div>
  );

  const renderCategory = (category, index) => {
    if (index === PAGE_SIZE - 1 && PAGE_SIZE < review.get("tags").size) {
      return (
        <CategoryTag
          key="categoryMore"
          onClick={handleOpenReviewModal}
          isMore
        />
      );
    }

    return <CategoryTag key={category} slug={category} />;
  };

  const renderTags = () => {
    if (review.get("tags") && review.get("tags").size > 0) {
      return (
        <div className={css(styles.bottomRowCategories)}>
          <div className={css(styles.tagTitle)}>Tags Added</div>
          {review.get("tags") &&
            review.get("tags").slice(0, PAGE_SIZE).map(renderCategory)}
        </div>
      );
    }

    return null;
  };

  const renderBottomRow = () => {
    if (review.get("tags") && review.get("tags").size > 0) {
      return (
        <LazyLoadComponent
          renderOnMount={renderOnMount}
          height={MOBILE_BOTTOM_ROW_HEIGHT}
        >
          <div
            className={css(
              styles.bottomRow,
              insidePanel && styles.bottomRowInsidePanel
            )}
          >
            {renderTags()}
          </div>
        </LazyLoadComponent>
      );
    }

    return null;
  };

  const renderEntityOverview = () => {
    const EntityOverview = getEntityOverview(passedReview.get("entity_type"));

    return (
      <div className={css(styles.reviewEntityOverview)}>
        <EntityOverview
          entity_type={passedReview.get("entity_type")}
          entity={entity}
          renderOnMount={renderOnMount}
          layout="overview"
          hideCreators
        />
      </div>
    );
  };

  return (
    <div
      data-id="review-mobile-card"
      className={css(
        styles.reviewMobileCard,
        noPanel && styles.reviewMobileCardNoPanel,
        styles.entityCard
      )}
    >
      <div className={css(styles.topRow)}>
        {renderReviewBubble()}
        {!hideEntityOverview && renderEntityOverview()}
      </div>
      {!hideBottomRow && renderBottomRow()}
    </div>
  );
};

ReviewMobileCard.propTypes = {
  review: PropTypes.instanceOf(Map),
  hideBottomRow: PropTypes.bool,
  hideEntityOverview: PropTypes.bool,
  noPanel: PropTypes.bool,
  showInfoIcons: PropTypes.bool,
  userRating: PropTypes.number,
  hideUserImage: PropTypes.bool,
  insidePanel: PropTypes.bool,
  insideCarousel: PropTypes.bool,
  noSidePadding: PropTypes.bool,
  noVerticalPadding: PropTypes.bool,
  renderOnMount: PropTypes.bool,
};

ReviewMobileCard.defaultProps = {
  review: null,
  hideBottomRow: false,
  hideEntityOverview: false,
  noPanel: false,
  insidePanel: false,
  insideCarousel: false,
  showInfoIcons: false,
  userRating: undefined,
  hideUserImage: false,
  noSidePadding: false,
  noVerticalPadding: false,
  renderOnMount: false,
};

export default ReviewMobileCard;
