import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import AddEntityButton from "components/Entities/Page/AddEntityButton";

import authActions from "actions/auth";
import modalActions from "actions/modals";
import { selectAuthLoggedIn } from "selectors/auth";

import useActionCreators from "hooks/useActionCreators";
import useReduxState from "hooks/useReduxState";

const AddCreatorsButton = (props) => {
  const { entity, entity_type, promptType } = props;

  const { showModal, startAuthFlow } = useActionCreators({
    ...modalActions,
    ...authActions,
  });

  const isLoggedIn = useReduxState((state) => selectAuthLoggedIn(state), []);

  const handleAdd = useCallback(() => {
    if (isLoggedIn) {
      showModal("prompt", {
        promptType,
        entity,
        entity_type,
        title: "Add Creators",
        introText:
          "Are we missing a creator from this podcast? Search below to find them and add credits, or add them as a new creator.",
      });
    } else {
      startAuthFlow(undefined, {
        entity,
        entityAction: "action",
        actionType: "Add creators to",
        analyticsVariables: {
          triggered_by: "AddCreditsButtonClick",
        },
      });
    }
  }, [entity, entity_type, isLoggedIn, promptType, showModal, startAuthFlow]);

  return <AddEntityButton onClick={handleAdd} label="Add Creators" />;
};

AddCreatorsButton.propTypes = {
  entity: PropTypes.object.isRequired,
  entity_type: PropTypes.string.isRequired,
  promptType: PropTypes.string,
};
AddCreatorsButton.defaultProps = {
  promptType: "creator",
};

export default memo(AddCreatorsButton);
