import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const Sponsors = Loadable(() => import("./Sponsors"));

function SponsorsAsyncLoading(props) {
  return <LoadingOverlay height={"136rem"} />;
}

const SponsorsContainer = (props) => {
  return <Sponsors {...props} fallback={<SponsorsAsyncLoading />} />;
};

export default SponsorsContainer;
