import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";

import { TabsContext } from "components/Common/Tabs";
import EpisodesStub from "components/Entities/Stubs/EpisodesStub";

import PodcastEpisodeCardContainer from "../components/PodcastEpisodeCardContainerAsync";

import * as sortConstants from "constants/sort";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useList from "hooks/useList";
import useWindowSize from "hooks/useWindowSize";

import cardStyles from "styles/CardStyles";
import ScreenSizes from "styles/ScreenSizes";

const { keysToConstants } = sortConstants;

const episodeStubStyles = {
  entityCard: {
    marginBottom: "0.5rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "0.75rem",
    },
  },
};

const episodeCompacttitleClampStyles = {
  title: {
    ...cardStyles.mobileCardPrimaryTitle,
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
};

const AboutBestEpisodes = ({ podcast, onTabChange, initialSort }) => {
  const listKey = `podcastView/${podcast.get("id")}/aboutBestEpisodes`;

  const { list: listDetails } = useList(listKey);
  const sort = listDetails && listDetails.get("sort");

  const { isWindowSizeOrLess } = useWindowSize();
  const isSmallOrLess = isWindowSizeOrLess("small");

  const entityCardProps = useMemo(
    () => ({
      compact: true,
      showShare: true,
      hideInfoIcons: isSmallOrLess,
      clickableStars: true,
      renderOnMount: true,
    }),
    [isSmallOrLess]
  );

  const listConfig = useMemo(
    () => ({
      sort: initialSort || sortConstants.SORT_ORDER_RANKING,
      loadingStyles: {
        noOverlay: {
          padding: "4rem 0",
          fontSize: "2rem",
          height: "23rem",
          flex: "initial",

          [ScreenSizes.smAndAbove]: {
            height: "21rem",
          },

          [ScreenSizes.lgAndAbove]: {
            height: "27rem",
          },

          [ScreenSizes.xlAndAbove]: {
            height: "19.6rem",
          },
        },
      },
    }),
    [initialSort]
  );

  const linkListKey = useMemo(
    () =>
      Object.entries(keysToConstants).reduce(
        (keyToReturn, [key, orderConst]) => {
          if (orderConst === sort) {
            return key;
          }

          return keyToReturn;
        },
        ""
      ),
    [sort]
  );

  const episodesOnTabChange = useCallback(
    () => onTabChange(`episodes/${linkListKey}`),
    [onTabChange, linkListKey]
  );

  const staticFilters = useMemo(
    () => ({
      podcast_id: {
        value: podcast && podcast.get("id"),
      },
    }),
    [podcast]
  );

  const { contentRef } = useContext(TabsContext);

  const mobile = isWindowSizeOrLess("medium");

  const renderItem = useCallback(
    (props) => (
      <PodcastEpisodeCardContainer
        {...props}
        renderOnMount
        compactEpisodeProps={{
          entityLinkProps: {
            inline: false,
            clamp: false,
          },
          cardTitleStyles: episodeCompacttitleClampStyles,
        }}
      />
    ),
    []
  );

  return (
    <EpisodesStub
      listKey={listKey}
      listConfig={listConfig}
      onTabChange={episodesOnTabChange}
      staticFilters={staticFilters}
      linkLabel="View Best Episodes"
      hidePodcastName
      scrollRef={contentRef}
      entityCardProps={entityCardProps}
      styles={episodeStubStyles}
      podcast={podcast}
      viewAllLink={getPodcastUrl(podcast, "episodes/ranking")}
      viewAllTitle={`Best Episodes of ${podcast.get("title")}`}
      inSidebar={mobile}
      renderItem={renderItem}
    />
  );
};

AboutBestEpisodes.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  podcast: PropTypes.instanceOf(Map),
  initialSort: PropTypes.string,
};
AboutBestEpisodes.defaultProps = {
  podcast: null,
  initialSort: null,
};

export default AboutBestEpisodes;
