import { getParams } from "utils/url";

const getPodcastWrapperId = (props = null, options = {}) => {
  if (!props) {
    return null;
  }

  if (props.podcast) {
    return props.podcast.get("id");
  }

  if (options.overrideId) {
    return options.overrideId(props);
  }

  if (props.podcast_id !== undefined) {
    return props.podcast_id;
  }

  if (props.episode && props.episode.has("podcast_id")) {
    return props.episode.get("podcast_id");
  }

  if (props.location) {
    const params = getParams(props.location.pathname, "/podcasts/:podcast_id");

    if (params.podcast_id) {
      const matched = params.podcast_id.match(/\d+$/);

      if (matched && matched[0]) {
        return matched[0];
      }
    }
  }

  if (props.params && props.params.id) {
    const idMatch =
      props.params.id.match(/[0-9]+\//) || props.params.id.match(/\d+$/);

    return idMatch && idMatch[0].replace("/", "");
  }

  return "new";
};

export default getPodcastWrapperId;
