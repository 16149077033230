import { Map } from "immutable";
import PropTypes from "prop-types";

import DirectRecommendationsPanel from "components/Entities/Page/DirectRecommendationsPanelAsync";

import getPodcastName from "utils/entity/getPodcastName";
import getPodcastUrl from "utils/entity/getPodcastUrl";

const DirectPodcastRecommendations = (props) => {
  const { podcast } = props;

  return (
    <DirectRecommendationsPanel
      {...props}
      entity_type="podcast"
      entity={podcast}
      getName={getPodcastName}
      getEntityUrl={getPodcastUrl}
    />
  );
};

DirectPodcastRecommendations.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
};

export default DirectPodcastRecommendations;
