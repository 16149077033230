import PropTypes from "prop-types";

import GoldingsAd from "./GoldingsAdAsync";

import { PODCAST_CATEGORIES } from "constants/podcast";

import usePodcastData from "hooks/usePodcastData";

const isBeforeFeb20 = () => new Date() < Date.parse("2024-02-20 00:00:00");
const isBeforeFeb27 = () => new Date() < Date.parse("2024-02-27 00:00:00");
const isBeforeMar5 = () => new Date() < Date.parse("2024-03-05 00:00:00");
const isBeforeMar12 = () => new Date() < Date.parse("2024-03-12 00:00:00");

const canShowMentalHeathCategory = (categories) => {
  return (
    !isBeforeFeb20() &&
    isBeforeFeb27() &&
    categories?.contains(PODCAST_CATEGORIES.MENTALHEALTH)
  );
};

const canShowRelationshipCategory = (categories) => {
  return (
    !isBeforeFeb27() &&
    isBeforeMar5() &&
    categories?.contains(PODCAST_CATEGORIES.RELATIONSHIPS)
  );
};

const canShowDramaCategory = (categories) => {
  return (
    !isBeforeMar5() &&
    isBeforeMar12() &&
    categories?.contains(PODCAST_CATEGORIES.DRAMA)
  );
};

export const canShowGoldingsAd = (podcast) => {
  const categories = podcast?.get("categories");

  return (
    canShowMentalHeathCategory(categories) ||
    canShowRelationshipCategory(categories) ||
    canShowDramaCategory(categories)
  );
};

const GoldingsAdContainer = (props) => {
  const { noPanel, podcastId } = props;

  const podcast = usePodcastData(podcastId);

  const categories = podcast?.get("categories");

  if (
    canShowMentalHeathCategory(categories) ||
    canShowRelationshipCategory(categories) ||
    canShowDramaCategory(categories)
  ) {
    return <GoldingsAd noPanel={noPanel} podcastId={podcastId} />;
  }

  return null;
};

GoldingsAdContainer.propTypes = {
  noPanel: PropTypes.bool,
  podcastId: PropTypes.number,
};

GoldingsAdContainer.defaultProps = {
  noPanel: false,
};

export default GoldingsAdContainer;
