import { format } from "date-fns/format";
import PropTypes from "prop-types";
import { useCallback, useMemo } from "react";

import { HashLink } from "components/Common/HashLink";
import ShowMoreContainer from "components/Common/ShowMoreContainer";
import Title from "components/Entities/Page/Title";

import { SHORT_DATE_ONLY_FORMAT } from "../../../constants/date";
import getCategoryText from "../../../utils/entity/getCategoryText";
import getCategoryUrl from "../../../utils/entity/getCategoryUrl";
import FAQSection from "./FAQSection";
import FAQStyles, { secondaryTitleStyles } from "./FAQStyles";

import formatFieldDate from "utils/entity/formatFieldDate";
import getPodcastHasGuests from "utils/entity/getPodcastHasGuests";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import sendGAEvent from "utils/sendGAEvent";

import usePodcastData from "hooks/usePodcastData";
import { useStyles } from "hooks/useStyles";
import useTransformRawDescription, {
  TransformRawDescriptionAnalytics,
} from "hooks/useTransformRawDescription";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  ...FAQStyles,
};

const newStyles = {
  ...secondaryTitleStyles,
  titleContainer: {
    ...secondaryTitleStyles.titleContainer,
    marginLeft: "1rem",

    [ScreenSizes.lgAndAbove]: {
      ...secondaryTitleStyles.titleContainer,
      marginLeft: "0",
    },
  },
};

const PodcastFAQForPro = (props) => {
  const { styles, css } = useStyles(baseStyles, props);
  const { podcastId, hasSpotifyData } = props;

  const podcast = usePodcastData(podcastId);

  const podcastTitle = podcast.get("title");

  const handleFAQListLinkClick = useCallback(
    (linkType) => {
      sendGAEvent({
        action: `FAQ${linkType}Click`,
        context: "InsightsTabFAQSectionLinkClick",
        podcast_id: podcastId,
        podcast_title: podcastTitle,
        userIsPro: true,
      });
    },
    [podcastId, podcastTitle]
  );

  const podcastDescription = useTransformRawDescription(
    podcast.get("description") && podcast.get("description_sanitized")
  );

  const showMoreAnalytics = useMemo(
    () => ({
      action: "descriptionLinkOnClick",
      page: "PODCAST_FAQ_PRO",
      entity: "podcast",
      entity_id: podcast.get("id"),
      entity_name: podcast.get("name"),
    }),
    [podcast]
  );

  return (
    <div data-testid="pro-faq" className={css(styles.faqWrapper)}>
      <Title
        title={`Frequently Asked Questions about ${podcastTitle}`}
        TitleComponent="h3"
        styles={newStyles}
        noTopPadding
      />
      <div className={css(styles.faq)}>
        <FAQSection
          podcastId={podcastId}
          id="faq-1"
          question={`How do I contact ${podcastTitle}?`}
        >
          <HashLink
            to={getPodcastUrl(podcast, "/insights#podcast-contacts")}
            onClick={() => handleFAQListLinkClick("ContactLink")}
            className={css(styles.link)}
          >
            Click here to view contact information{" "}
          </HashLink>
          for {podcastTitle}.
        </FAQSection>
        <FAQSection
          podcastId={podcastId}
          id="faq-2"
          question={`How many listens/downloads does ${podcastTitle} get?`}
        >
          <HashLink
            to={getPodcastUrl(
              podcast,
              "/insights#podcast-insights-audience-section"
            )}
            onClick={() => handleFAQListLinkClick("AudienceInsightsLink")}
            className={css(styles.link)}
          >
            Click here to view reach information{" "}
          </HashLink>
          for {podcastTitle}.
        </FAQSection>

        <FAQSection
          podcastId={podcastId}
          id="faq-3"
          question={`Does ${podcastTitle} accept guest interviews?`}
        >
          {
            { true: "Yes", false: "Unlikely", never: "Never" }[
              getPodcastHasGuests(podcast)
            ]
          }
        </FAQSection>

        <FAQSection
          podcastId={podcastId}
          id="faq-4"
          question={`How much does it cost to advertise on ${podcastTitle}?`}
        >
          <HashLink
            to={getPodcastUrl(podcast, "/insights#podcast-insights-Ad_Rate")}
            onClick={() => handleFAQListLinkClick("AdsRateLink")}
            className={css(styles.link)}
          >
            Click here to view our estimated rate card{" "}
          </HashLink>
          for {podcastTitle}.
        </FAQSection>

        <FAQSection
          podcastId={podcastId}
          id="faq-5"
          question={`Where does ${podcastTitle} rank on Apple Podcasts charts?`}
        >
          <HashLink
            to={getPodcastUrl(podcast, "/insights#Apple-chart-position")}
            onClick={() => handleFAQListLinkClick("AppleChartsLink")}
            className={css(styles.link)}
          >
            Click here to view chart rankings{" "}
          </HashLink>
          for {podcastTitle}.
        </FAQSection>

        {hasSpotifyData && (
          <FAQSection
            podcastId={podcastId}
            id="faq-6"
            question={`Where does ${podcastTitle} rank on Spotify's podcast charts?`}
          >
            <HashLink
              to={getPodcastUrl(podcast, "/insights#Spotify-chart-position")}
              onClick={() => handleFAQListLinkClick("SpotifyChartsLink")}
              className={css(styles.link)}
            >
              Click here to view chart rankings{" "}
            </HashLink>
            for {podcastTitle}.
          </FAQSection>
        )}

        <FAQSection
          podcastId={podcastId}
          id="faq-7"
          question={`Which brands have sponsored ${podcastTitle}?`}
        >
          <HashLink
            to={getPodcastUrl(podcast, "/insights#podcast-sponsor-insights")}
            onClick={() => handleFAQListLinkClick("BrandSponsorsLink")}
            className={css(styles.link)}
          >
            Click here to view sponsor history{" "}
          </HashLink>
          for {podcastTitle}.
        </FAQSection>

        <FAQSection
          podcastId={podcastId}
          id="faq-8"
          question={`What is ${podcastTitle} about?`}
        >
          Here is the podcast description for {podcastTitle}: <br />
          <ShowMoreContainer
            open={true}
            hideOverlay
            hideToggle
            disableToggleOnContentClick
          >
            <TransformRawDescriptionAnalytics analytics={showMoreAnalytics}>
              {podcastDescription}
            </TransformRawDescriptionAnalytics>
          </ShowMoreContainer>
        </FAQSection>

        <FAQSection
          podcastId={podcastId}
          id="faq-9"
          question={`How many episodes of ${podcastTitle} exist?`}
        >
          As of {format(new Date(), SHORT_DATE_ONLY_FORMAT)}, {podcastTitle} has
          published {podcast.get("number_of_episodes")} episodes.
        </FAQSection>

        <FAQSection
          podcastId={podcastId}
          id="faq-10"
          question={`When was the latest episode of ${podcastTitle}?`}
        >
          The latest episode of {podcastTitle} was published on{" "}
          {formatFieldDate(podcast, "date_of_latest_episode")}
        </FAQSection>
        <FAQSection
          podcastId={podcastId}
          id="faq-11"
          question={`When did ${podcastTitle} launch?`}
        >
          {podcastTitle} launched its first episode on{" "}
          {formatFieldDate(podcast, "date_of_first_episode")}.
        </FAQSection>

        <FAQSection
          podcastId={podcastId}
          id="faq-12"
          question={`Is ${podcastTitle} explicit?`}
        >
          The content of {podcastTitle} is{" "}
          {podcast.get("is_explicit") ? "explicit" : " not explicit"}.
        </FAQSection>
        <FAQSection
          podcastId={podcastId}
          id="faq-13"
          question={`Who is the author of ${podcastTitle}?`}
        >
          {podcastTitle} is created by {podcast.get("itunes_owner_name")}.
        </FAQSection>

        <FAQSection
          podcastId={podcastId}
          id="faq-14"
          question={`Which podcast categories does ${podcastTitle} belong to?`}
        >
          {podcastTitle} belongs to the following podcast categories:{" "}
          <ul className={css(styles.ul)}>
            {podcast
              .get("categories")
              .toJS()
              .map((category) => (
                <li className={css(styles.li)} key={getCategoryText(category)}>
                  <a
                    href={getCategoryUrl(category)}
                    className={css(styles.link)}
                    key={getCategoryText(category)}
                  >
                    {getCategoryText(category)}
                  </a>
                </li>
              ))}
          </ul>
        </FAQSection>

        <FAQSection
          podcastId={podcastId}
          id="faq-15"
          question={`Which podcast hosting provider does ${podcastTitle} use?`}
        >
          {podcastTitle} is hosted on{" "}
          {podcast.get("hosting_provider") || "Unknown"}.
        </FAQSection>
      </div>
    </div>
  );
};

PodcastFAQForPro.propTypes = {
  podcastId: PropTypes.number,
  hasSpotifyData: PropTypes.bool,
};

export default PodcastFAQForPro;
