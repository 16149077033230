import useInterval from "@use-it/interval";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";

import StandardButton from "components/Buttons/StandardButton";
import LineLoader from "components/Common/Loader/LineLoader";
import SideButtonStyles from "pages/PodcastView/Sidebar/SideButtonStyles";

import { refreshPodcastFeed } from "api/live/podcast";

import { useStyles } from "hooks/useStyles";

const baseStyles = {
  ...SideButtonStyles,
};

const timeout = 30;

const RefreshPodcastFeed = (props) => {
  const { podcast } = props;
  const { styles } = useStyles(baseStyles, props);

  const [timer, setTimer] = useState(0);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [pageReloading, setPageReloading] = useState(false);

  useInterval(
    () => {
      setTimer(timer + 250);
    },
    refreshing ? 250 : null
  );

  useEffect(() => {
    if (timer > (timeout - 1) * 1000) {
      setRefreshing(false);
      setError("TIMED_OUT");
    }
  }, [timer]);

  const handleRefreshClick = useCallback(() => {
    setRefreshing(true);
    refreshPodcastFeed({ podcast_id: podcast.get("id") })
      .then(() => {
        setPageReloading(true);
        window.location.reload();
      })
      .catch((e) => {
        if (!refreshing) {
          return;
        }
        if (e.statusCode === "504") {
          setError("TIMED_OUT");
        } else {
          setError(e.message);
        }
      })
      .finally(() => {
        setRefreshing(false);
        setTimer(0);
      });
  }, [podcast, refreshing]);

  const renderDescription = () => {
    if (refreshing) {
      return (
        <Fragment>
          This feed has been queued for refresh. Depending on response time and
          number of episodes, it may take up to 24 hours to see changes on the
          site.
          <br />
          <br />
          This page will now reload.
        </Fragment>
      );
    }

    if (error) {
      switch (error) {
        case "TIMED_OUT":
          return (
            <Fragment>
              The feed took too long to respond, but we will refresh it in the
              background. Check back in 5 minutes.
            </Fragment>
          );
        default:
          return (
            <Fragment>
              Looks like we had trouble retrieving the feed.
              <br />
              Refresh and try again or{" "}
              <a href="mailto:contact@podchaser.com">contact us</a>.
            </Fragment>
          );
      }
    }

    return (
      <Fragment>
        Are we missing an episode or update?
        <br />
        Use this to check the RSS feed immediately.
      </Fragment>
    );
  };

  const label = useMemo(() => {
    if (refreshing) {
      return "Refreshing...";
    }
    if (pageReloading) {
      return "Reloading page...";
    }
    return "Refresh Feed";
  }, [pageReloading, refreshing]);

  const gaProps = useMemo(() => ({ podcast_id: podcast.get("id") }), [podcast]);

  return (
    <Fragment>
      <div className={css(styles.button)}>
        <StandardButton
          label={label}
          onClick={handleRefreshClick}
          variation="white"
          flat
          disabled={refreshing || pageReloading || !!error}
          gaAction="refreshFeedClicked"
          gaProps={gaProps}
        />
        {refreshing && (
          <LineLoader percent={(timer / (timeout * 1000)) * 100} />
        )}
      </div>
      <div
        className={css(styles.description, error && styles.descriptionError)}
      >
        {renderDescription()}
      </div>
    </Fragment>
  );
};

RefreshPodcastFeed.propTypes = {
  podcast: PropTypes.instanceOf(Map).isRequired,
};
RefreshPodcastFeed.defaultProps = {};

export default RefreshPodcastFeed;
