import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const AffiliateBadgesSidebarPanel = Loadable(() =>
  import("./AffiliateBadgesSidebarPanel")
);

function AffiliateBadgesSidebarPanelAsyncLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const AffiliateBadgesSidebarPanelContainer = (props) => {
  return (
    <AffiliateBadgesSidebarPanel
      {...props}
      fallback={<AffiliateBadgesSidebarPanelAsyncLoading />}
    />
  );
};

export default AffiliateBadgesSidebarPanelContainer;
