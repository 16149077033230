import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";

import { TabsContext } from "components/Common/Tabs";
import EpisodesStub from "components/Entities/Stubs/EpisodesStub";

import PodcastEpisodeCardContainer from "../components/PodcastEpisodeCardContainerAsync";

import * as sortConstants from "constants/sort";
import getPodcastUrl from "utils/entity/getPodcastUrl";

import useList from "hooks/useList";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import cardStyles from "styles/CardStyles";
import ScreenSizes from "styles/ScreenSizes";

const { keysToConstants } = sortConstants;

const episodeStubStyles = {
  entityCard: {
    marginBottom: "0.5rem",

    [ScreenSizes.lgAndAbove]: {
      marginBottom: "0.75rem",
    },
  },
};

const episodeCompactTitleClampStyles = {
  title: {
    ...cardStyles.mobileCardPrimaryTitle,
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
  },
};

const baseStyles = {};

const AboutRecentEpisodes = ({ podcast, onTabChange, initialSort }) => {
  const listKey = `podcastView/${podcast.get("id")}/aboutRecentEpisodes`;

  const { styles, css } = useStyles(baseStyles);

  const { list: listDetails } = useList(listKey);
  const sort = listDetails && listDetails.get("sort");

  const { isWindowSizeOrLess } = useWindowSize();
  const isSmallOrLess = isWindowSizeOrLess("small");
  const mobile = isWindowSizeOrLess("medium");

  const entityCardProps = useMemo(
    () => ({
      compact: true,
      showShare: true,
      hideRating: isSmallOrLess,
      clickableStars: true,
      renderOnMount: true,
    }),
    [isSmallOrLess]
  );

  const numberOfEpisodes = podcast?.get("number_of_episodes");
  const listConfig = useMemo(() => {
    const defaultConfig = {
      sort: initialSort || sortConstants.SORT_ORDER_RECENT,
      loadingStyles: {
        noOverlay: {
          padding: "4rem 0",
          fontSize: "2rem",
          flex: "initial",
        },
      },
    };

    if (numberOfEpisodes === 1) {
      return {
        ...defaultConfig,
        loadingStyles: {
          noOverlay: {
            ...defaultConfig.loadingStyles.noOverlay,
            height: "6.3rem",

            [ScreenSizes.mdAndAbove]: {
              height: "5.3rem",
            },

            [ScreenSizes.lgAndAbove]: {
              height: "7.2rem",
            },

            [ScreenSizes.xlAndAbove]: {
              height: "6rem",
            },
          },
        },
      };
    }

    if (numberOfEpisodes === 2) {
      return {
        ...defaultConfig,
        loadingStyles: {
          noOverlay: {
            ...defaultConfig.loadingStyles.noOverlay,
            height: "13rem",

            [ScreenSizes.mdAndAbove]: {
              height: "12.6rem",
            },

            [ScreenSizes.lgAndAbove]: {
              height: "13.8rem",
            },

            [ScreenSizes.xlAndAbove]: {
              height: "12.5rem",
            },
          },
        },
      };
    }

    return {
      ...defaultConfig,
      loadingStyles: {
        noOverlay: {
          ...defaultConfig.loadingStyles.noOverlay,
          height: "23rem",

          [ScreenSizes.mdAndAbove]: {
            height: "21rem",
          },

          [ScreenSizes.lgAndAbove]: {
            height: "27rem",
          },

          [ScreenSizes.xlAndAbove]: {
            height: "19.6rem",
          },
        },
      },
    };
  }, [initialSort, numberOfEpisodes]);

  const linkListKey = useMemo(
    () =>
      Object.entries(keysToConstants).reduce(
        (keyToReturn, [key, orderConst]) => {
          if (orderConst === sort) {
            return key;
          }

          return keyToReturn;
        },
        ""
      ),
    [sort]
  );

  const episodesOnTabChange = useCallback(
    () => onTabChange(`episodes/${linkListKey}`),
    [onTabChange, linkListKey]
  );

  const staticFilters = useMemo(
    () => ({
      podcast_id: {
        value: podcast && podcast.get("id"),
      },
    }),
    [podcast]
  );

  const { contentRef } = useContext(TabsContext);

  const renderItem = useCallback(
    (props) => (
      <PodcastEpisodeCardContainer
        {...props}
        renderOnMount
        compactEpisodeProps={{
          entityLinkProps: {
            inline: false,
            clamp: false,
          },
          cardTitleStyles: episodeCompactTitleClampStyles,
        }}
      />
    ),
    []
  );

  return (
    <div className={css(styles.container)}>
      <EpisodesStub
        listKey={listKey}
        listConfig={listConfig}
        onTabChange={episodesOnTabChange}
        staticFilters={staticFilters}
        linkLabel="View All Episodes"
        hidePodcastName
        scrollRef={contentRef}
        entityCardProps={entityCardProps}
        styles={episodeStubStyles}
        podcast={podcast}
        viewAllLink={getPodcastUrl(podcast, "episodes/recent")}
        viewAllTitle={`Recent Episodes of ${podcast.get("title")}`}
        inSidebar={mobile}
        renderItem={renderItem}
      />
    </div>
  );
};

AboutRecentEpisodes.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  podcast: PropTypes.instanceOf(Map),
  initialSort: PropTypes.string,
};
AboutRecentEpisodes.defaultProps = {
  podcast: null,
  initialSort: null,
};

export default AboutRecentEpisodes;
