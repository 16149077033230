import { Map } from "immutable";
import PropTypes from "prop-types";
import { useCallback, useContext, useMemo } from "react";

import { TabsContext } from "components/Common/Tabs";
import UserlistsStub from "components/Entities/Stubs/UserlistsStubAsync";

import getEpisodeUrl from "utils/entity/getEpisodeUrl";

import useWindowSize from "hooks/useWindowSize";

const AboutLists = ({ episode, onTabChange }) => {
  const listsOnTabChange = useCallback(
    () => onTabChange("lists"),
    [onTabChange]
  );
  const staticFilters = useMemo(
    () => ({
      episode_id: {
        value: episode && episode.get("id"),
      },
    }),
    [episode]
  );

  const { contentRef } = useContext(TabsContext);
  const { isWindowSizeOrLess } = useWindowSize();

  const mobile = isWindowSizeOrLess("medium");

  return (
    <UserlistsStub
      listKey={`episodeView/${episode.get("id")}/aboutUserlists`}
      title="Mentioned In These Lists"
      onTabChange={listsOnTabChange}
      staticFilters={staticFilters}
      name={episode.get("title")}
      entity_type="episode"
      entity={episode}
      scrollRef={contentRef}
      viewAllLink={getEpisodeUrl(episode, { path: "lists" })}
      viewAllTitle={`Podcast lists featuring ${episode.get("title")}`}
      inSidebar={mobile}
    />
  );
};

AboutLists.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  episode: PropTypes.instanceOf(Map),
};
AboutLists.defaultProps = {
  episode: null,
};

export default AboutLists;
