import { Map } from "immutable";
import PropTypes from "prop-types";
import { useMemo, useContext } from "react";

import LazyLoadComponent from "components/Common/LazyLoad/LazyLoadComponent";
import NoListsMessage from "components/Entities/Stubs/NoListsMessage";
import RequestContext from "pages/RequestContext";

import AboutLists from "./AboutLists";

import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    height: "16rem",

    [ScreenSizes.xlAndAbove]: {
      height: "18rem",
    },
  },
};

const AboutListsContainer = (props) => {
  const { episode, onTabChange } = props;

  const requestContext = useContext(RequestContext);

  const { isWindowSizeOrLess } = useWindowSize();

  const { styles, css } = useStyles(baseStyles);

  const mobile = isWindowSizeOrLess("medium");

  const showNoListMessage = useMemo(
    () => episode?.get("list_count") === 0,
    [episode]
  );

  if (!episode) {
    return null;
  }

  if (showNoListMessage) {
    return (
      <NoListsMessage
        entity={episode}
        entity_type="episode"
        inSidebar={mobile}
        name={episode.get("title")}
      />
    );
  }

  return (
    <LazyLoadComponent
      className={css(styles.container)}
      renderOnMount={requestContext.isBotCrawler}
    >
      <AboutLists key="lists" onTabChange={onTabChange} episode={episode} />
    </LazyLoadComponent>
  );
};

AboutListsContainer.propTypes = {
  episode: PropTypes.instanceOf(Map),
  onTabChange: PropTypes.func.isRequired,
};

AboutListsContainer.defaultProps = {
  episode: null,
};

export default AboutListsContainer;
