import PropTypes from "prop-types";
import { Suspense, useContext } from "react";

import RequestContext from "pages/RequestContext";

function ClientSuspense(props) {
  const { fallback, children } = props;

  const { server } = useContext(RequestContext);

  if (server) {
    return null;
  }

  return <Suspense fallback={fallback}>{children}</Suspense>;
}

ClientSuspense.propTypes = {
  fallback: PropTypes.func,
  children: PropTypes.node,
};

ClientSuspense.defaultProps = {
  fallback: null,
  children: null,
};

export default ClientSuspense;
