import { faCircleNotch } from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { lazy, memo } from "react";

import ClientSuspense from "components/Common/ClientSuspense";

const PodcastModeratorTools = lazy(() => import("./PodcastModeratorTools"));

function LazyLoader(props) {
  const { podcast_id, noPanel } = props;

  return (
    <ClientSuspense
      key="moderatortoolssuspense"
      fallback={
        <div key="podcastmoderatortoolsfallback">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      }
    >
      <PodcastModeratorTools
        key="podcastmoderatortools"
        podcast_id={podcast_id}
        noPanel={noPanel}
      />
    </ClientSuspense>
  );
}

LazyLoader.propTypes = {
  podcast_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  noPanel: PropTypes.bool,
};

LazyLoader.propTypes = {
  noPanel: false,
};

export default memo(LazyLoader);
