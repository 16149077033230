import Immutable from "immutable";
import PropTypes from "prop-types";

import TagsPanel from "components/Entities/Page/TagsPanel";

const Tags = ({ podcast, noPanel }) => (
  <TagsPanel
    title="Podcast Tags"
    entity={podcast}
    categories={podcast.get("tags")}
    noPanel={noPanel}
  />
);

Tags.propTypes = {
  podcast: PropTypes.instanceOf(Immutable.Map).isRequired,
  noPanel: PropTypes.bool,
};

Tags.defaultProps = {
  noPanel: false,
};

export default Tags;
