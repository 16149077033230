import Loadable from "@loadable/component";

const AdvertiseContainer = Loadable(() =>
  import(/* webpackChunkName: "AdvertiseContainer" */ "./AdvertiseContainer")
);

function AdvertiseContainerAsyncLoading() {
  return null;
}

const HeaderAuthorityScoreContainer = (props) => {
  return (
    <AdvertiseContainer
      {...props}
      fallback={<AdvertiseContainerAsyncLoading />}
    />
  );
};

export default HeaderAuthorityScoreContainer;
