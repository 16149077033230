export default function addCommasToNumber(str) {
  // I ripped this from stackoverflow with a few syntax updates
  const parts = `${str}`.split(".");
  let main = parts[0];
  let len = main.length;
  let output = "";
  let first = main.charAt(0);
  let i;

  if (first === "-") {
    main = main.slice(1);
    len = main.length;
  } else {
    first = "";
  }

  i = len - 1;

  while (i >= 0) {
    output = main.charAt(i) + output;

    if ((len - i) % 3 === 0 && i > 0) {
      output = `,${output}`;
    }

    i -= 1;
  }
  // put sign back
  output = first + output;
  // put decimal part back
  if (parts.length > 1) {
    output += `.${parts[1]}`;
  }

  return output;
}
