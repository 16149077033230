import Loadable from "@loadable/component";
import { css } from "aphrodite";
import { memo } from "react";

import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  container: {
    ...gStyles.skeletonPlaceholder,
    width: "100%",
    height: "20.7rem",

    [ScreenSizes.lgAndAbove]: {
      height: "30rem",
    },

    [ScreenSizes.xlAndAbove]: {
      height: "14.5rem",
      marginBottom: "3.5rem",
    },
  },
  title: {
    ...gStyles.skeletonPlaceholder,
    display: "none",

    [ScreenSizes.lgAndAbove]: {
      display: "block",
      width: "100%",
      height: "5.7rem",
      marginTop: "3rem",
      marginBottom: "1.5rem",
    },
  },
};

const UserlistsStubAsync = (props) => {
  const { styles } = useStyles(baseStyles, props);

  return (
    <>
      <div className={css(styles.title)} />
      <div className={css(styles.container)} />
    </>
  );
};

const UserlistsStubAsyncMemo = memo(UserlistsStubAsync);

const UserlistsStub = Loadable(() => import("./UserlistsStub"));

function UserlistsStubAsyncLoading() {
  return <UserlistsStubAsyncMemo />;
}

const UserlistsStubContainer = (props) => {
  return <UserlistsStub {...props} fallback={<UserlistsStubAsyncLoading />} />;
};

export default UserlistsStubContainer;
