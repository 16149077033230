import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useRef } from "react";

import entityList from "components/Entities/EntityList";
import Title from "components/Entities/Page/Title";
import { useStubLinkClick } from "components/Entities/Stubs/useStubLinkClick";

import AddReviewButton from "../../Review/AddReviewButton";
import ReviewCardContainer from "../Cards/ReviewCards/ReviewCardContainer";
import NoReviewsMessage from "./NoReviewsMessage";
import StubLink from "./StubLink";

import { SORT_ORDER_POPULAR } from "constants/sort";
import loadReviewsList from "sagas/pagination/lists/loadReviewsList";
import { selectList } from "selectors/pagination";
import { selectSpecificReview } from "selectors/reviews";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  reviewList: {
    paddingLeft: "0.75rem",
    height: "16.875rem",

    [ScreenSizes.mdAndAbove]: {
      paddingLeft: 0,
      height: "initial",
    },

    [ScreenSizes.lgAndAbove]: {
      paddingLeft: 0,
    },
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.mdAndAbove]: {
      display: "none",
    },
  },
};

const reviewStyles = {
  entityCard: {
    marginBottom: "1.5rem",
  },
  reviewContainer: {
    borderRadius: 0,
    boxShadow: "none",

    [ScreenSizes.lgAndAbove]: {
      borderRadius: 6,
      boxShadow: "0 5px 16px 0 rgba(0, 0, 0, 0.07)",
    },
  },
};

const ReviewsStub = (props) => {
  const {
    onTabChange,
    listKey,
    staticFilters,
    title,
    entity,
    entity_type,
    linkLabel,
    entityName,
    listConfig,
    noItemsMessage,
    onRatingClick,
    renderReview: passedRenderReview,
    pageSize,
    hideAdd,
    sort,
    includeEntity,
    showMore,
    titleStyles,
    showStubLink,
    minCarouselItemPercentage: passedItemPercentage,
    scrollRef,
    viewAllLink,
    viewAllTitle,
    inSidebar,
    renderOnMount,
  } = props;
  const { styles } = useStyles(baseStyles, props);

  const { isWindowSizeOrLess, isWindowSizeOrMore } = useWindowSize();
  const isMediumOrLess = isWindowSizeOrLess("medium");
  const handleLinkClick = useStubLinkClick(onTabChange, scrollRef);

  const list = useReduxState(
    (state) => selectList(state, { key: listKey }),
    [listKey]
  );

  const ReviewsList = useRef(
    entityList({
      key: listKey,
      sort,
      list_type: "reviews_filtered",
      loadListAction: loadReviewsList,
      entity_type: "review",
      pageSize,
      staticFilters,
      getEntity: (state, id) => selectSpecificReview(state, id),
      apiProps: { includes: includeEntity ? ["entity"] : [] },
      noLoadingOverlay: true,
      loadingStyles: {
        noOverlay: {
          padding: "4rem 0",
          fontSize: "2rem",
        },
      },
      ...listConfig,
    })
  ).current;

  const showCarousel = isMediumOrLess && list && list.get("total") > 1;
  let itemPercentage = 85;

  if (isWindowSizeOrMore("xl")) {
    itemPercentage = 45;
  }

  const renderReview = useCallback(
    (review) => {
      const reviewProps = {
        review,
        entity: entity || (review && review.get("entity")),
        styles: reviewStyles,
        entityName,
        insideCarousel: showCarousel,
        renderOnMount,
      };

      if (passedRenderReview) {
        return passedRenderReview(reviewProps);
      }

      return (
        <ReviewCardContainer
          entity={review}
          mobile={isMediumOrLess}
          noPanel
          hideBottomRow
          showActionsInReviewBubble
          noSidePadding
          noVerticalPadding
          hideEntityOverview
          showInfoIcons
          hideRepliesToggle
          {...reviewProps}
        />
      );
    },
    [
      entity,
      entityName,
      isMediumOrLess,
      showCarousel,
      renderOnMount,
      passedRenderReview,
    ]
  );

  if (list && list.get("total") === 0 && list.get("loaded")) {
    return (
      <NoReviewsMessage
        title={title}
        entity={entity}
        entity_type={entity_type}
        entityName={entityName}
        noItemsMessage={noItemsMessage}
        onRatingClick={onRatingClick}
        titleStyles={titleStyles}
        inSidebar={inSidebar}
      />
    );
  }

  const renderAddButton = () => (
    <AddReviewButton entity={entity} entity_type={entity_type} />
  );

  return (
    <Fragment>
      {title && (
        <Title
          title={title}
          onTabChange={handleLinkClick}
          count={showCarousel ? list && list.get("total") : null}
          TitleComponent="h2"
          button={!hideAdd && !isMediumOrLess && renderAddButton()}
          styles={titleStyles}
          link={viewAllLink}
          viewAllTitle={viewAllTitle}
          inSidebar={inSidebar}
        />
      )}
      <div className={css(styles.reviewList)}>
        <ReviewsList
          renderItem={renderReview}
          minCarouselItemPercentage={passedItemPercentage || itemPercentage}
          carousel={showCarousel}
          cellSpacing={0}
          showMore={showMore}
          onMoreClick={handleLinkClick}
          desiredWidth={800}
        />
      </div>
      {showStubLink && (
        <div className={css(styles.mobileContainer)}>
          <StubLink onClick={handleLinkClick} label={linkLabel} />
        </div>
      )}
      {!hideAdd && (
        <div className={css(styles.mobileContainer)}>{renderAddButton()}</div>
      )}
    </Fragment>
  );
};

ReviewsStub.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  title: PropTypes.string,
  listKey: PropTypes.string.isRequired,
  staticFilters: PropTypes.object,
  linkLabel: PropTypes.string,
  showStubLink: PropTypes.bool,
  entity: PropTypes.instanceOf(Map),
  entityName: PropTypes.string,
  listConfig: PropTypes.object,
  noItemsMessage: PropTypes.node,
  onRatingClick: PropTypes.func,
  entity_type: PropTypes.string,
  renderReview: PropTypes.func,
  pageSize: PropTypes.number,
  hideAdd: PropTypes.bool,
  showMore: PropTypes.bool,
  sort: PropTypes.string,
  includeEntity: PropTypes.bool,
  titleStyles: PropTypes.object,
  minCarouselItemPercentage: PropTypes.number,
  scrollRef: PropTypes.object,
  viewAllTitle: PropTypes.string,
  viewAllLink: PropTypes.string,
  inSidebar: PropTypes.bool,
  renderOnMount: PropTypes.bool,
};

ReviewsStub.defaultProps = {
  entity_type: "podcast",
  title: null,
  staticFilters: null,
  entity: null,
  linkLabel: "View All",
  showStubLink: true,
  entityName: "podcast",
  listConfig: {},
  noItemsMessage: null,
  onRatingClick: null,
  renderReview: null,
  pageSize: 3,
  hideAdd: false,
  showMore: false,
  sort: SORT_ORDER_POPULAR,
  includeEntity: false,
  titleStyles: null,
  minCarouselItemPercentage: null,
  scrollRef: null,
  viewAllTitle: null,
  viewAllLink: null,
  inSidebar: false,
};

export default memo(ReviewsStub);
