import Loadable from "@loadable/component";

import LoadableLoading from "components/Common/LoadableLoading";

const TranscriptViewContainer = Loadable(() =>
  import("./TranscriptViewContainer")
);

function TranscriptViewContainerAsyncLoading(props) {
  return <LoadableLoading {...props} page />;
}

const TranscriptViewContainerContainer = (props) => {
  return (
    <TranscriptViewContainer
      {...props}
      fallback={<TranscriptViewContainerAsyncLoading />}
    />
  );
};

export default TranscriptViewContainerContainer;
