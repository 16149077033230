import PropTypes from "prop-types";
import { memo } from "react";

import StandardButton from "components/Buttons/StandardButton";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const linkStyles = {
  button: {
    ...gStyles.fontSemiBold,
    fontSize: ".813rem",
    color: colours.primary,

    [ScreenSizes.smAndAbove]: {
      fontSize: ".938rem",
    },
  },
};

const StubLink = (props) => (
  <StandardButton
    className={props.className}
    customStyles={linkStyles}
    variation="transparent"
    {...props}
    anchor
  />
);

StubLink.propTypes = {
  className: PropTypes.string,
};
StubLink.defaultProps = {
  className: "",
};

export default memo(StubLink);
